<template>
  <div
    ref="dialogWrapperRef"
    class="dialog-layout-wrapper bg-white"
    tabindex="0"
  >
    <slot name="content"></slot>
    <div class="dialog-divider py-8">
      <v-divider class="dialog-divider__line" />
    </div>
    <div class="d-flex justify-space-between flex-row-reverse">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PropType, onMounted, onUnmounted, ref } from 'vue'
const dialogWrapperRef = ref<HTMLDivElement>()
const props = defineProps({
  onSubmit: {
    type: Function as PropType<() => void>,
    default: () => {},
  },
  buttonLoading: {
    type: Boolean,
    default: false,
  },
  isDeletable: {
    type: Boolean,
    default: false,
  },
})

/**
 * handleKeyPressed
 * @param {event} event
 */
function handleKeyPressed(event: KeyboardEvent): void {
  if (event.key == 'Enter') {
    props.onSubmit()
  }
}

onMounted(() => {
  dialogWrapperRef.value?.focus()
  document.addEventListener('keyup', handleKeyPressed)
})

onUnmounted(() => {
  document.removeEventListener('keyup', handleKeyPressed)
})
</script>
<style lang="scss" scoped>
.dialog-layout-wrapper {
  padding: 4rem 4rem 2rem;
}

.dialog-divider {
  height: 100%;

  &__line {
    position: absolute;
    width: 100%;
    left: 0;
  }
}
</style>
