<template>
  <div class="profile-settings d-flex flex-column justify-space-between">
    <div>
      <GeneralPageTitle>
        <template #title>
          {{ t('profileSettings.headline') }}
        </template>
      </GeneralPageTitle>
      <div class="d-flex align-stretch">
        <LinkTeaser class="w-50 mr-6">
          <template #title>
            {{ t('profileSettings.personal.headline') }}
          </template>
          <template #text>
            <DefaultList class="mt-4">
              <ListElementSelection>
                <template #prepend>
                  <DefaultAvatar
                    size="48"
                    :color="PlattformColors.INFO"
                  >
                    <v-icon>{{ Icons.USER }}</v-icon>
                  </DefaultAvatar>
                </template>
                <template #title>
                  <p class="mb-0 xs">{{ t('profileSettings.personal.name') }}</p>
                </template>
                <template #subtitle>
                  <p class="mb-0">{{ fullName }}</p>
                </template>
              </ListElementSelection>
              <ListElementSelection>
                <template #prepend>
                  <DefaultAvatar
                    size="48"
                    :color="PlattformColors.INFO"
                  >
                    <v-icon>{{ Icons.EMAIL }}</v-icon>
                  </DefaultAvatar>
                </template>
                <template #title>
                  <p class="mb-0 xs">{{ t('profileSettings.personal.email') }}</p>
                </template>
                <template #subtitle>
                  <p class="mb-0">{{ activeUser?.email }}</p>
                </template>
              </ListElementSelection>
              <ListElementSelection>
                <template #prepend>
                  <DefaultAvatar
                    size="48"
                    :color="PlattformColors.INFO"
                  >
                    <v-icon>{{ Icons.LOCK }}</v-icon>
                  </DefaultAvatar>
                </template>
                <template #title>
                  <p class="mb-0 xs">{{ t('profileSettings.personal.password') }}</p>
                </template>
                <template #subtitle>
                  <p class="mb-0">**********</p>
                </template>
                <template #append>
                  <IconButton
                    :icon="Icons.EDIT"
                    @click="changePassword()"
                  />
                </template>
              </ListElementSelection>
            </DefaultList>
          </template>
        </LinkTeaser>
        <LinkTeaser class="w-50">
          <template #title>
            {{ t('profileSettings.organizations.headline') }}
          </template>
          <template #text>
            <DefaultList
              v-if="organizationsWithoutTenant.length"
              class="mt-4"
            >
              <ListElementSelection
                v-for="organization in organizationsWithoutTenant"
                :key="organization.id"
              >
                <template #prepend>
                  <OrganizationLogo :organization-logo="organization.logo" />
                </template>
                <template #title>{{ organization.name }}</template>
                <template #subtitle>
                  <p class="mb-0 xs">{{ getOrganizationSubtitle(organization) }}</p>
                </template>
                <template #append>
                  <IconButton
                    :icon="Icons.LOG_OUT"
                    @click="showleaveOrganizationOverlay(organization)"
                  />
                </template>
              </ListElementSelection>
            </DefaultList>
            <div
              v-else
              class="d-flex justify-center align-center flex-column border-light-grey rounded-lg px-8 py-16 mt-4 mb-9"
            >
              <p class="text-grey font-weight-bold mb-0">{{ t('profileSettings.organizations.empty') }}</p>
            </div>
          </template>
        </LinkTeaser>
      </div>
    </div>
    <div class="d-flex justify-space-between align-center my-8">
      <div>
        <h3 class="mb-1">{{ t('profileSettings.delete.headline') }}</h3>
        <p class="mb-0">{{ t('profileSettings.delete.copy') }}</p>
      </div>
      <DefaultButton
        :color="PlattformColors.LIGHT"
        class="text-error"
        @click="deleteUserDialogRef?.open()"
      >
        {{ t('profileSettings.delete.button') }}
      </DefaultButton>
    </div>
  </div>
  <Dialog
    ref="leaveOrganizationDialogRef"
    @on-close="leaveOrganizationDialogRef?.close()"
  >
    <DialogOrganizationLeaveQuestion
      :organization="selectedOrganization"
      :loading="leaveOrganizationLoading"
      @submit="leaveOrganization()"
      @cancel="leaveOrganizationDialogRef?.close()"
    />
  </Dialog>
  <Dialog
    ref="deleteUserDialogRef"
    @on-close="deleteUserDialogRef?.close()"
  >
    <DialogUserDelete
      :has-organizations="!!organizationsWithoutTenant.length"
      :loading="deleteUserLoading"
      @submit="deleteUser()"
      @cancel="deleteUserDialogRef?.close()"
    />
  </Dialog>
</template>
<script lang="ts" setup>
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import ListElementSelection from '@/components/baseComponents/lists/ListElementSelection.vue'
import LinkTeaser from '@/components/baseComponents/teasers/LinkTeaser.vue'
import DialogOrganizationLeaveQuestion from '@/components/dialogs/DialogOrganizationLeaveQuestion.vue'
import DialogUserDelete from '@/components/dialogs/DialogUserDelete.vue'
import Dialog from '@/components/layout/Dialog.vue'
import OrganizationLogo from '@/components/organizations/OrganizationLogo.vue'
import { useFormatHelper } from '@/composables/useFormatHelper'
import { useLocalStorage } from '@/composables/useLocalStorage'
import { OrganizationPublicResponse } from '@/models/Organization'
import { UserOrganization } from '@/models/User'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { AuthorizationsContexts, AuthorizationsRoles } from '@/models/enums/AuthorizationsContextTypes'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import { changePassword, logoutFromKeycloak } from '@/plugins/keycloak'
import { useActiveUserStore } from '@/store/activeUser'
import { useAlertStore } from '@/store/alerts'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const myOrganizationStore = useMyOrganizationStore()
const alertStore = useAlertStore()
const activeUserStore = useActiveUserStore()

const { myOrganizations } = storeToRefs(myOrganizationStore)
const { activeUser, fullName } = storeToRefs(activeUserStore)

const { stringSort } = useFormatHelper()
const { setItem } = useLocalStorage()

const selectedOrganization = ref<UserOrganization>()
const leaveOrganizationDialogRef = ref<InstanceType<typeof Dialog>>()
const deleteUserDialogRef = ref<InstanceType<typeof Dialog>>()
const leaveOrganizationLoading = ref(false)
const deleteUserLoading = ref(false)

const organizationsWithoutTenant = computed(() => {
  return [
    ...myOrganizations.value
      .filter((userOrganization) => {
        return userOrganization.authorizations.some((authorization) => {
          return authorization.contexts.find((context) => context.context === AuthorizationsContexts.PROVIDER)
        })
      })
      .sort((orgA, orgB) => stringSort(orgA.name, orgB.name)),
    ...myOrganizations.value
      .filter((userOrganization) => {
        return userOrganization.authorizations.some((authorization) => {
          return authorization.contexts.find((context) => context.context === AuthorizationsContexts.CONSUMER)
        })
      })
      .sort((orgA, orgB) => stringSort(orgA.name, orgB.name)),
  ]
})

function getOrganizationSubtitle(userOrganization: UserOrganization): string {
  const authorization = userOrganization.authorizations.find((authorization) => authorization.application === 'BDP')
  if (authorization?.contexts[0].context === AuthorizationsContexts.PROVIDER) {
    return authorization?.contexts[0].role === AuthorizationsRoles.ADMIN
      ? `${t('profileSettings.organizations.provider')} | ${t('profileSettings.organizations.admin')}`
      : t('profileSettings.organizations.provider')
  } else {
    return authorization?.contexts[0].role === AuthorizationsRoles.ADMIN
      ? `${t('profileSettings.organizations.consumer')} | ${t('profileSettings.organizations.admin')}`
      : t('profileSettings.organizations.consumer')
  }
}

/**
 * showleaveOrganizationOverlay
 * @param {UserOrganization} organization
 */
function showleaveOrganizationOverlay(organization: UserOrganization): void {
  selectedOrganization.value = organization
  leaveOrganizationDialogRef.value?.open()
}

/**
 * leaveOrganization
 */
async function leaveOrganization(): Promise<void> {
  try {
    leaveOrganizationLoading.value = true
    await myOrganizationStore.LEAVE(selectedOrganization.value)
    alertStore.add({
      text: t('profileSettings.leaveMsg'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    leaveOrganizationLoading.value = false
    leaveOrganizationDialogRef.value?.close()
  }
}

/*
 * deleteUser
 */
async function deleteUser(): Promise<void> {
  try {
    deleteUserLoading.value = true
    await activeUserStore.DELETE()
    setItem('deletedUser', 'true')
    logoutFromKeycloak()
  } catch (e) {
    const organizations = (e as { organizations: OrganizationPublicResponse[] }).organizations
    alertStore.add({
      text: t('profileSettings.deleteMsg.adminOrganizations', { organizations: organizations.map((org) => org.name).join(', ') }),
      type: AlertTypes.ERROR,
    })
    await Promise.resolve()
  } finally {
    deleteUserLoading.value = false
    deleteUserDialogRef.value?.close()
  }
}
</script>

<style lang="scss" scoped>
.profile-settings {
  min-height: calc(100vh - 8rem);
}
</style>

<i18n lang="yaml">
de:
  profileSettings:
    headline: Profileinstellungen
    personal:
      headline: Persönliche Daten
      name: Name
      email: E-Mail
      password: Password
    organizations:
      headline: Organisationen
      empty: Keine Organisationen vorhanden
      provider: API Anbieter
      consumer: API Nutzer
      admin: Admin
    delete:
      headline: Profil löschen
      copy: Du kannst dein Profil nicht löschen, wenn du der letzte Admin einer Organisation bist.
      button: Profil löschen
    leaveMsg: Du hast die Organisation verlassen!
    deleteMsg:
      adminOrganizations: 'Profil kann nicht gelöscht werden! Folgende Organisationen benötigen einen Admin: {organizations}'
</i18n>
