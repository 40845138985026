<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('providerDashboard.headline') }}
    </template>
  </GeneralPageTitle>
  <v-row>
    <v-col>
      <OrganizationTeaser :active-organization="activeOrganization" />
    </v-col>
  </v-row>
  <v-row class="d-flex align-stretch">
    <v-col>
      <ProductTeaser
        :products="products"
        @show-create-product-overlay="showCreateProductDialog()"
      />
    </v-col>
    <v-col>
      <ConsumerRequestsTeaser
        :bundles="mappedBundles"
        :bundle-processes="bundleProcesses"
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <TeamTeaser
        :team-count="teamsLength"
        :member-count="usersLength"
      />
    </v-col>
    <v-col>
      <DocsTeaser />
    </v-col>
  </v-row>
  <Dialog
    ref="dialogRef"
    @on-close="closeCreateProductDialog()"
  >
    <DialogProductFormBasic
      :loading="loading"
      @cancel="closeCreateProductDialog()"
      @submit="(product: ProductForm) => createProduct(product)"
    />
  </Dialog>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useProductStore } from '@/store/products'
import { useTeamStore } from '@/store/teams'
import { useUserStore } from '@/store/users'
import { useBundleStore } from '@/store/bundles'
import { useOrganizationStore } from '@/store/organizations'
import { ProductForm, ProductResponse } from '@/models/Product'
import DocsTeaser from '@/components/dashboards/general/DocsTeaser.vue'
import ConsumerRequestsTeaser from '@/components/dashboards/provider/ConsumerRequestsTeaser.vue'
import ProductTeaser from '@/components/dashboards/provider/ProductTeaser.vue'
import OrganizationTeaser from '@/components/dashboards/general/OrganizationTeaser.vue'
import TeamTeaser from '@/components/dashboards/general/TeamTeaser.vue'
import Dialog from '@/components/layout/Dialog.vue'
import DialogProductFormBasic from '@/components/dialogs/DialogProductFormBasic.vue'
import { Routes } from '@/models/enums/Routes'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'

const { t } = useI18n()
const router = useRouter()

const bundleStore = useBundleStore()
const myOrganizationStore = useMyOrganizationStore()
const organizationStore = useOrganizationStore()
const userStore = useUserStore()
const productStore = useProductStore()
const teamStore = useTeamStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)
const { bundles, bundleProcesses } = storeToRefs(bundleStore)
const { organizationRelations } = storeToRefs(organizationStore)
const { teamsLength } = storeToRefs(teamStore)
const { usersLength } = storeToRefs(userStore)
const { products } = storeToRefs(productStore)

const loading = ref(false)
const dialogRef = ref<InstanceType<typeof Dialog>>()

/**
 * goToProduct
 * @param {productId} productId
 */
function goToProduct(productId: ProductResponse['id']): void {
  router.push({ name: Routes.PROVIDER_PRODUCTS_DETAILS, params: { productId } })
}

/**
 * closeCreateProductDialog
 */
function closeCreateProductDialog(): void {
  dialogRef.value?.close()
}

/**
 * showCreateProductDialog
 */
function showCreateProductDialog(): void {
  dialogRef.value?.open()
}

/**
 * createProduct
 * @param {product} product
 */
async function createProduct(product: ProductForm): Promise<void> {
  try {
    loading.value = true
    const resp = await productStore.CREATE(product)
    goToProduct(resp.id)
  } catch {
    Promise.resolve()
  } finally {
    loading.value = false
    closeCreateProductDialog()
  }
}

const mappedBundles = computed(() => {
  return bundles.value.map((bundle) => {
    return {
      ...bundle,
      organizationName: organizationRelations.value.find((organization) => organization.consumer.id === bundle.organizationId)?.consumer.name || '',
    }
  })
})

if (activeOrganization?.value)
  await Promise.allSettled([
    bundleStore.GET(),
    bundleStore.GET_BUNDLE_PROCESSES(activeOrganization.value.id),
    organizationStore.GET_ORGANIZATIONS_IN_RELATION(activeOrganization.value.id),
    productStore.GET_PRODUCTS({ providerId: activeOrganization.value.id }),
    teamStore.GET(),
    userStore.GET(),
  ])
</script>

<i18n lang="yaml">
de:
  providerDashboard:
    headline: Anbieter Dashboard
</i18n>
