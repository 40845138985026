<template>
  <v-tooltip
    location="bottom"
    transition="fade-transition"
    :max-width="240"
    content-class="plain-tooltip elevation-5 py-1 px-2 bg-blue text-white"
  >
    <template #activator="{ props }">
      <slot
        name="activator"
        :props="props"
      ></slot>
    </template>
    <template #default>
      <p class="xs mb-0"><slot name="default"></slot></p>
    </template>
  </v-tooltip>
</template>
<style lang="scss">
.plain-tooltip {
  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    border-bottom: 0.25rem solid rgb(var(--v-theme-blue));
    transform: translateX(-50%);
    z-index: 1;
  }
}
</style>
