<template>
  <v-card
    variant="flat"
    class="bg-white pa-2"
  >
    <template #prepend>
      <OrganizationLogo
        class="mr-2"
        :organization-logo="activeOrganization?.logo"
      />
    </template>
    <template #title>
      <h3 class="mb-0">{{ organization?.name }}</h3>
    </template>
    <!-- <template #subtitle>
        {{
          t('organizationCard.lastUpdated', {
            date: format(new Date(activeOrganization?.updatedAt || 0), 'dd.MM.yyyy'),
          })
        }}
      </template> -->
    <template #append>
      <slot name="append"></slot>
    </template>
    <template #text>
      <div class="ml-16">
        <DefaultTag
          v-if="organization?.email"
          class="mr-4 mb-2"
          :prepend-icon="Icons.EMAIL"
          :href="`mailto:${organization?.email}`"
        >
          {{ organization?.email }}
        </DefaultTag>
        <DefaultTag
          v-if="isOrganizationAddressComplete(organization)"
          class="mr-4 mb-2"
          :variant="TagVariants.READ_ONLY"
          :prepend-icon="Icons.LOCATION"
        >
          {{
            t('organizationCard.completeAddress', {
              street: organization?.address?.street,
              number: organization?.address?.number,
              zipCode: organization?.address?.zipCode,
              city: organization?.address?.city,
            })
          }}
        </DefaultTag>
        <DefaultTag
          v-else-if="activeOrganization?.id === organization?.id"
          class="mr-4 mb-2"
          :variant="TagVariants.EMPTY"
        >
          {{ t('organizationCard.notCompletedAddress') }}
        </DefaultTag>
        <DefaultTag
          v-if="organization?.registrationNumber"
          class="mr-4 mb-2"
          :variant="TagVariants.READ_ONLY"
        >
          {{ organization?.registrationNumber }}
        </DefaultTag>
        <DefaultTag
          v-else-if="activeOrganization?.id === organization?.id"
          class="mr-4 mb-2"
          :variant="TagVariants.EMPTY"
        >
          {{ t('organizationCard.notCompletedRegistrationNumber') }}
        </DefaultTag>
      </div>
    </template>
  </v-card>
</template>
<script lang="ts" setup>
// import { format } from 'date-fns'
import { Icons } from '@/models/enums/IconTypes'
import { useI18n } from 'vue-i18n'
import { OrganizationExtendedResponse } from '@/models/Organization'
import { PropType } from 'vue'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import OrganizationLogo from '@/components/organizations/OrganizationLogo.vue'
import DefaultTag from '@/components/baseComponents/tags/DefaultTag.vue'
import { TagVariants } from '@/models/enums/components/Tags'

const myOrganizationStore = useMyOrganizationStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)

defineProps({
  organization: { type: Object as PropType<OrganizationExtendedResponse>, default: undefined },
})

const { t } = useI18n()

/**
 * isOrganizationAddressComplete
 * @param {organization} organization
 * @return {boolean} true or false
 */
function isOrganizationAddressComplete(organization?: OrganizationExtendedResponse): boolean {
  return (
    !!organization?.name &&
    !!organization?.email &&
    !!organization?.address?.street &&
    !!organization?.address?.number &&
    !!organization?.address?.zipCode &&
    !!organization?.address?.city
  )
}
</script>
<i18n lang="yaml">
de:
  organizationCard:
    lastUpdated: 'Zuletzt bearbeitet am: {date}'
    completeAddress: '{street} {number}, {zipCode} {city}'
    notCompletedAddress: Organisationsadresse
    notCompletedRegistrationNumber: Handelsregisternummer
</i18n>
