export enum StoreName {
  ACCESS_DEFINITIONS = 'accessDefinitions',
  ACCESSES = 'accesses',
  ACTIVE_USER = 'activeUser',
  ALERTS = 'alerts',
  BUNDLES = 'bundles',
  CATEGORIES = 'categories',
  CONTACTS = 'contacts',
  FILES = 'files',
  INVITATION = 'invitation',
  LOADING = 'loading',
  MY_ORGANIZATIONS = 'myOrganizations',
  NEWSLETTER = 'newsletter',
  ORGANIZATIONS = 'organizations',
  PLANS = 'plans',
  PRODUCTS = 'products',
  PRODUCT_OFFERS = 'productOffers',
  SIDE_WIZARD = 'sideWizard',
  SNACKBAR = 'snackbar',
  SUBSCRIPTIONS = 'subscriptions',
  TECHNICAL_USERS = 'technicalUsers',
  TENANTS = 'tenants',
  USERS = 'users',
}
