<template>
  <div>
    <v-form
      ref="form"
      @submit.prevent="emit('submit', terminationNote)"
    >
      <DialogDefaultLayout>
        <template #content>
          <h1>{{ t('terminateBundle.headline', { name: bundle.name }) }}</h1>
          <p>{{ t('terminateBundle.copy', { name: bundle.name }) }}</p>

          <InfoBox
            v-if="provider"
            class="mb-8"
            :headline="t('terminateBundle.infoBox.lockCredentials.headline')"
            :copy="t('terminateBundle.infoBox.lockCredentials.copy')"
            :info-type="StatusTypes.WARNING"
          />

          <DefaultTextField
            autofocus
            :label="t('terminateBundle.date')"
            :model-value="format(terminationDate, 'dd.MM.yyyy')"
            disabled
            required
          />
          <DefaultTextarea
            v-model="terminationNote"
            :label="t('terminateBundle.note')"
          />
        </template>
        <template #buttons>
          <DefaultButton
            type="submit"
            :color="PlattformColors.PRIMARY"
            :loading="loading"
          >
            {{ t('buttons.terminate') }}</DefaultButton
          >

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="emit('cancel')"
          >
            {{ t('buttons.cancel') }}
          </DefaultButton>
        </template>
      </DialogDefaultLayout>
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { PropType, ref } from 'vue'
import { BundleResponse } from '@/models/Bundle'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { format, addDays } from 'date-fns'
import InfoBox from '../InfoBox.vue'
import { StatusTypes } from '@/models/enums/StatusTypes'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultTextField from '@/components/baseComponents/inputs/DefaultTextField.vue'
import DefaultTextarea from '@/components/baseComponents/inputs/DefaultTextarea.vue'

const { t } = useI18n()
const dateNow = new Date()
const terminationDate = addDays(dateNow, 1)

const terminationNote = ref<string>()
defineProps({
  bundle: {
    type: Object as PropType<BundleResponse>,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
  provider: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])
</script>

<i18n lang="yaml">
de:
  terminateBundle:
    headline: App inkl. aller Pläne kündigen?
    copy: Bist du sicher, dass du deine App inklusive aller aktiven Pläne unwiderruflich kündigen möchtest?
    date: Kündigung zum*
    note: Notiz
    infoBox:
      lockCredentials:
        headline: Authentifizierungsmittel sperren!
        copy: Stelle sicher, dass du aktive Authentifizierungsmittel zum Kündigungseintritt sperrst, um deinem Kunden den Zugriff zu entziehen.
</i18n>
