<template>
  <v-list-group class="mb-5">
    <template #activator="{ props, isOpen }">
      <ListElement
        :to="to"
        :clickable="clickable"
        :data-test="dataTest"
        @click="() => emit('click')"
      >
        <template
          v-if="$slots.prepend"
          #prepend
        >
          <slot name="prepend"></slot>
        </template>
        <template
          v-if="$slots.title"
          #title
        >
          <slot name="title"></slot>
        </template>
        <template
          v-if="$slots.subtitle"
          #subtitle
        >
          <slot name="subtitle"></slot>
        </template>
        <template #append>
          <slot name="append"></slot>
          <DefaultButton
            v-if="$slots.expand && expandable"
            icon
            :variant="ButtonVariants.TEXT"
            :size="ButtonSizes.DEFAULT"
            :color="PlattformColors.TERTIERY"
            v-bind="props"
            :data-test="ListElementExpandableTest.CHEVRON"
            @click.prevent
          >
            <v-icon :class="{ 'rotate-180': isOpen }">{{ Icons.CHEVRON_DOWN }}</v-icon>
          </DefaultButton>
        </template>
      </ListElement>
    </template>
    <template
      v-if="$slots.expand"
      #default
    >
      <div class="px-6 pb-6">
        <slot name="expand"></slot>
      </div>
    </template>
  </v-list-group>
</template>
<script setup lang="ts">
import ListElement from '@/components/baseComponents/lists/ListElement.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { Icons } from '@/models/enums/IconTypes'
import { ButtonVariants, ButtonSizes } from '@/models/enums/components/Buttons'
import { PlattformColors } from '@/models/enums/ColorSets'
import { PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import { ListElementExpandableTest } from '@/models/enums/test/ListElementExpandable'

defineProps({
  to: { type: Object as PropType<RouteLocationRaw>, default: undefined },
  dataTest: { type: String, default: undefined },
  clickable: { type: Boolean, default: false },
  expandable: { type: Boolean, default: true },
})

const emit = defineEmits(['click'])
</script>
<style lang="scss" scoped>
:deep(.v-list-item--active .v-list-item__overlay) {
  display: none;
}
</style>
