<template>
  <v-form
    v-model="accessDefinitionForm"
    @submit.prevent="submitAccessDefinition()"
  >
    <DialogDefaultLayout>
      <template #content>
        <h1>{{ !!accessDefinitionObject ? t('accessDefinition.form.updateHeadline') : t('accessDefinition.form.createHeadline') }}</h1>

        <DefaultSelect
          v-model="accessDefinition.accessExchangeType"
          :items="accessExchangeTypeNames"
          :rules="requireSelectionRules"
          required
          :disabled="!!accessDefinitionObject"
          variant="outlined"
          :density="InputDensity.COMFORTABLE"
          item-title="name"
          item-value="key"
          :label="t('accessDefinition.form.exchangeType.label')"
        />
        <InfoBox
          class="mb-6"
          :copy="t('accessDefinition.form.info')"
        />

        <DefaultCheckbox
          v-if="!accessDefinitionObject"
          v-model="accessDefinition.defaultDefinition"
          :label="t('accessDefinition.form.setDefault')"
        />

        <div v-if="accessDefinition.accessType">
          <p class="highlight sm">{{ t('accessDefinition.form.subHead') }}</p>
          <DefaultSelect
            v-model="accessDefinition.accessType.hashAlgorithm"
            :items="hashAlgorithmItems"
            :rules="requireSelectionRules"
            required
            :disabled="!!accessDefinitionObject"
            :density="InputDensity.COMFORTABLE"
            :label="t('accessDefinition.form.hashAlgorithm.label')"
          />

          <DefaultSelect
            v-model="accessDefinition.accessType.maxTokenLength"
            :items="getAccessMaxTokenLengthList()"
            :rules="requireSelectionRules"
            required
            :density="InputDensity.COMFORTABLE"
            :label="t('accessDefinition.form.maxTokenLength.label')"
          />

          <DefaultSelect
            v-model="accessDefinition.accessType.validityPeriod"
            :items="getAccessValidityPeriodListinYears()"
            item-title="key"
            item-value="value"
            :rules="requireSelectionRules"
            required
            :density="InputDensity.COMFORTABLE"
            :label="t('accessDefinition.form.validityPeriod.label')"
          />
        </div>
      </template>
      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          :disabled="!accessDefinitionForm"
        >
          {{ t('buttons.save') }}</DefaultButton
        >

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
          >{{ t('buttons.cancel') }}</DefaultButton
        >
      </template>
    </DialogDefaultLayout>
  </v-form>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, ref, PropType, Ref } from 'vue'
import { AccessDefinitionForm, AccessDefinitionFormEmit, AccessDefinitionTokenResponse } from '@/models/AccessDefinition'
import { AccessExchangeTypes, AccessHashAlgorithms, AccessTypes } from '@/models/enums/AccessDefinitionTypes'
import { isNotEmpty } from '@/validators'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { useAccessHelper } from '@/composables/useAccessHelper'
import InfoBox from '@/components/InfoBox.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultCheckbox from '@/components/baseComponents/checkboxes/DefaultCheckbox.vue'
import DefaultSelect from '@/components/baseComponents/inputs/DefaultSelect.vue'
import { InputDensity } from '@/models/enums/components/Inputs'

const { t } = useI18n()

const props = defineProps({
  accessDefinitionObject: {
    type: Object as PropType<AccessDefinitionTokenResponse>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])

const { getAccessDefinitionTypes, getAccessValidityPeriodListinYears, getAccessMaxTokenLengthList } = useAccessHelper()

const accessDefinitionForm = ref(false)
const accessDefinition: Ref<AccessDefinitionForm> = ref(
  props.accessDefinitionObject
    ? {
        accessExchangeType: props.accessDefinitionObject.accessExchangeType,
        accessType: { ...props.accessDefinitionObject.accessType },
        defaultDefinition: props.accessDefinitionObject.defaultDefinition,
      }
    : { accessExchangeType: undefined, accessType: {}, defaultDefinition: false }
)

const accessExchangeTypeNames = computed(() =>
  getAccessDefinitionTypes().filter((accessDefinitionType) => accessDefinitionType.key !== AccessExchangeTypes.MANUAL_EXCHANGE)
)

const hashAlgorithmItems = computed(() => Object.values(AccessHashAlgorithms))

const requireSelectionRules = computed(() => [(v: string): boolean | string => isNotEmpty(v)])

/**
 * submitAccessDefinition
 */
async function submitAccessDefinition(): Promise<void> {
  if (accessDefinitionForm.value) {
    accessDefinition.value.accessType.type = AccessTypes.ACCESS_TOKEN
    emit('submit', accessDefinition.value as AccessDefinitionFormEmit)
  }
}
</script>

<i18n lang="yaml">
de:
  accessDefinition:
    form:
      createHeadline: Authentifizierungsmethode hinzufügen
      updateHeadline: Authentifizierungsmethode bearbeiten
      info: Bei der Access Token Generierung wird das Hashverfahren genutzt. Du kannst die Stringlänge und Laufzeit auf deine Bedürfnisse konfigurieren.
      setDefault: Als Standard festlegen
      exchangeType:
        label: Authentifizierungsmethode*
      subHead: Einstellungen
      validityPeriod:
        label: Laufzeit*
      maxTokenLength:
        label: Stringlänge*
      hashAlgorithm:
        label: Hashverfahren*
</i18n>
