<template>
  <v-item-group
    v-model="selectedPlans"
    :multiple="multiSelectable"
    mandatory
    :disabled="props.bundleState !== StatusTypes.DRAFT && !multiSelectable && !!getVisibleSubscriptions(bundleProductSubscriptions).length"
  >
    <swiper-container
      ref="productPlansSwiperRef"
      class="product-plans bg-secondary mb-4 px-8"
      :slides-per-view="3"
      :space-between="16"
      :pagination="{ clickable: true }"
      :navigation="{ enabled: true, prevEl: '.swiper-prev-btn', nextEl: '.swiper-next-btn', disabledClass: 'swiper-nav-btn__disabled' }"
      :prevent-clicks="false"
      :prevent-clicks-propagation="false"
      :grab-cursor="true"
      :keyboard="true"
      :set-wrapper-size="true"
      :data-test="ProductPlansTest.SWIPER"
    >
      <swiper-slide
        v-for="plan in availableProductPlans"
        :key="plan.id"
        class="swiper-slide"
      >
        <v-item
          v-slot="{ isSelected, toggle }"
          :value="plan.id"
        >
          <ProductPlanItem
            :selectable="selectable"
            :disabled="isPlanDisabled(plan)"
            :plan="plan"
            :is-selected="isSelected"
            :class="{ '--selected': isSelected }"
            :data-test="ProductPlansTest.ITEM"
            v-on="isPlanDisabled(plan) ? {} : { click: toggle }"
            @update="(_plan: PlanResponse) => emit('update', _plan)"
            @delete="(_plan: PlanResponse) => emit('delete', _plan)"
          />
        </v-item>
      </swiper-slide>
    </swiper-container>
    <IconButton
      density="comfortable"
      class="swiper-prev-btn"
      :icon="Icons.CHEVRON_LEFT"
    />

    <IconButton
      density="comfortable"
      class="swiper-next-btn"
      :icon="Icons.CHEVRON_RIGHT"
    />
  </v-item-group>
</template>

<script lang="ts" setup>
import { register as registerSwiper } from 'swiper/element/bundle'
import ProductPlanItem from '@/components/products/ProductPlanItem.vue'
import { PlanResponse } from '@/models/Plan'
import { PropType, ref, watch } from 'vue'
import { BundleResponse } from '@/models/Bundle'
import { SubscriptionResponse } from '@/models/Subscription'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { useBundleApprovalHelper } from '@/composables/useBundleApprovalHelper'
import { ProductPlansTest } from '@/models/enums/test/ProductPlansTest'
import { Icons } from '@/models/enums/IconTypes'
import IconButton from '../baseComponents/buttons/IconButton.vue'

// register Swiper custom elements
registerSwiper()

const selectedPlans = ref<Array<PlanResponse['id']> | PlanResponse['id']>()

const productPlansSwiperRef = ref()

const props = defineProps({
  availableProductPlans: {
    type: Array<PlanResponse>,
    default: [],
  },
  selectable: {
    type: Boolean,
    default: false,
  },
  multiSelectable: {
    type: Boolean,
    default: false,
  },
  bundleProductSubscriptions: {
    type: Array<SubscriptionResponse>,
    default: [],
  },
  bundleState: {
    type: String as PropType<BundleResponse['contractStatus']>,
    default: undefined,
  },
})

/**
 * updateSwiper
 * Updates the Swiper instance to reflect any changes.
 */
function updateSwiper(): void {
  productPlansSwiperRef.value?.swiper.update()
}

const emit = defineEmits(['valid', 'changeSelectedPlans', 'update', 'delete'])
const { isMostRecentApprovalNotRejectedOrTerminated } = useBundleApprovalHelper()

selectedPlans.value = props.bundleProductSubscriptions
  ? getVisibleSubscriptions(props.bundleProductSubscriptions).map((subscription) => subscription.planId)
  : []

watch(selectedPlans, (newValue) => {
  emit('valid', !!newValue?.length)
})

/**
 * isPlanDisabled
 * @param {plan} plan
 * @return {boolean}
 */
function isPlanDisabled(plan: PlanResponse): boolean {
  return (
    !!getVisibleSubscriptions(props.bundleProductSubscriptions).find((subscription) => subscription.planId === plan.id) &&
    props.bundleState !== StatusTypes.DRAFT
  )
}

/**
 * getSelectedPlans
 * @return {Array<PlanResponse['id']> | undefined}
 */
function getSelectedPlans(): Array<PlanResponse['id']> | undefined {
  if (Array.isArray(selectedPlans.value)) {
    return selectedPlans.value
  } else if (selectedPlans.value) {
    return [selectedPlans.value]
  } else {
    return undefined
  }
}

/**
 * getVisibleSubscriptions
 * @param {Array<SubscriptionResponse>} subscriptions
 * @return {Array<SubscriptionResponse>}
 */
function getVisibleSubscriptions(subscriptions: Array<SubscriptionResponse>): Array<SubscriptionResponse> {
  return subscriptions.filter((subscription) => isMostRecentApprovalNotRejectedOrTerminated(subscription.approvals))
}

defineExpose({
  getSelectedPlans,
  updateSwiper,
})
</script>
<style lang="scss">
.product-plans {
  height: 100%;
}

.swiper-slide {
  height: auto;
  overflow: hidden;
}

.swiper-prev-btn,
.swiper-next-btn {
  z-index: 2;
  position: absolute;
  top: calc(50% - var(--v-btn-height));
}

.swiper-nav-btn__disabled {
  display: none;
}

.swiper-prev-btn {
  left: -0.5rem;
}

.swiper-next-btn {
  right: -0.5rem;
}

.swiper-container {
  position: relative;

  &::part(container) {
    padding: 2rem 0;
  }

  &::part(pagination) {
    --swiper-pagination-bottom: 0.4rem;
  }

  &::part(bullet-active) {
    background-color: rgb(var(--v-theme-black));
  }
}

/*
overrides swipers overflow:hidden together with position:fixed on button-prev und button-next
without showing additional slides.
This allows to set the navigation-sides outside of the swiper HTML Element
*/

.v-item-group {
  transform: translate(0, 0);
}
</style>
