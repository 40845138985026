<template>
  <div v-if="organization">
    <GeneralPageTitle>
      <template #prepend>
        <OrganizationLogo
          :size="80"
          :organization-logo="organization.logo"
        />
      </template>
      <template #title>
        {{ organization.name }}
      </template>
      <template #append>
        <DefaultButton
          target="_blank"
          :color="PlattformColors.PRIMARY"
          :href="`mailto:${organization.email}`"
          :prepend-icon="Icons.EMAIL"
        >
          {{ t('providerDetails.contact') }}
        </DefaultButton>
      </template>
    </GeneralPageTitle>
    <v-tabs>
      <v-tab
        replace
        exact
        :to="{ name: Routes.CONSUMER_PROVIDERS_FILES, params: { providerId: organization.id } }"
      >
        {{ t('providerDetails.files') }}
      </v-tab>
    </v-tabs>

    <TabContainer />
  </div>
</template>

<script lang="ts" setup>
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import OrganizationLogo from '@/components/organizations/OrganizationLogo.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import TabContainer from '@/components/TabContainer.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import { Routes } from '@/models/enums/Routes'
import { useOrganizationStore } from '@/store/organizations'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const route = useRoute()
const organizationStore = useOrganizationStore()

const { organization } = storeToRefs(organizationStore)

await organizationStore.GET_ORGANIZATION(route.params.providerId as string)
</script>

<i18n lang="yaml">
de:
  providerDetails:
    contact: Kontakt
    files: Dokumente
</i18n>
