<template>
  <DefaultList class="bg-transparent">
    <ContactElement
      v-for="contact in contacts"
      :key="contact.id"
      :contact="contact"
    >
      <template #append>
        <DefaultTag
          v-if="contact.defaultContact"
          class="mr-4"
          :variant="TagVariants.READ_ONLY"
        >
          {{ t('contactList.default') }}
        </DefaultTag>
        <v-menu
          location="bottom"
          transition="slide-y-transition"
        >
          <template #activator="{ props }">
            <IconButton
              v-bind="props"
              :icon="Icons.OPTIONS_VERTICAL"
            />
          </template>
          <v-list density="compact">
            <v-list-item
              v-if="!contact.defaultContact"
              flat
              @click="emit('setDefault', contact)"
            >
              <template #prepend>
                <v-icon :icon="Icons.AUTHENTICATION" />
              </template>
              <v-list-item-title>{{ t('contactList.menu.setDefault') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              flat
              @click="emit('update', contact)"
            >
              <template #prepend>
                <v-icon :icon="Icons.EDIT" />
              </template>
              <v-list-item-title>{{ t('contactList.menu.edit') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              flat
              @click="emit('delete', contact)"
            >
              <template #prepend>
                <v-icon :icon="Icons.DELETE" />
              </template>
              <v-list-item-title>{{ t('contactList.menu.delete') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </ContactElement>
  </DefaultList>
</template>
<script lang="ts" setup>
import { ContactResponse } from '@/models/Contact'
import { Icons } from '@/models/enums/IconTypes'
import { useI18n } from 'vue-i18n'
import ContactElement from './ContactElement.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DefaultTag from '@/components/baseComponents/tags/DefaultTag.vue'
import { TagVariants } from '@/models/enums/components/Tags'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'

const { t } = useI18n()

const emit = defineEmits(['update', 'setDefault', 'delete'])

defineProps({
  contacts: { type: Array<ContactResponse>, default: undefined },
})
</script>
<i18n lang="yaml">
de:
  contactList:
    default: Standard
    menu:
      setDefault: Als Standard festlegen
      edit: Bearbeiten
      delete: Löschen
</i18n>
