<template>
  <LinkTeaser>
    <template #title>
      {{ t('consumerRequestsTeaser.headline') }}
    </template>
    <template #text>
      <v-list v-if="bundlesWithRequests.length">
        <v-list-item
          v-for="(bundle, i) in bundlesWithRequests"
          :key="bundle.organizationId"
          :to="{
            name: Routes.PROVIDER_BUNDLES_PRODUCTS,
            params: { bundleId: bundle.id },
          }"
          class="pa-0"
          :class="{ 'mt-3': i > 0 }"
          :data-cy="ConsumerRequestsTeaserTest.REQUESTED_BUNDLE_ITEM"
        >
          <template #prepend>
            <BundleAvatar :size="48" />
          </template>
          <template #title>
            <p class="sm mb-0 highlight">{{ bundle.name }}</p>
          </template>
          <template #subtitle>
            <p class="xs mb-0">{{ bundle.organizationName }}</p>
          </template>
          <template #append>
            <StatusIcon
              class="mr-2"
              :status-type="
                bundle.contractStatus === StatusTypes.REQUESTED
                  ? getStatusSetFromStatusKey(StatusTypes.IN_PROGRESS)?.key
                  : getStatusSetFromStatusKey(bundle.contractStatus)?.key
              "
              :width="20"
            />
          </template>
        </v-list-item>
      </v-list>
      <div
        v-else
        class="d-flex justify-center align-center flex-column h-100 border-light-grey rounded-lg pa-8"
      >
        <p class="text-grey font-weight-bold mb-0">{{ t('consumerRequestsTeaser.placeholder') }}</p>
      </div>
    </template>
  </LinkTeaser>
</template>

<script lang="ts" setup>
import { ConsumerRequestsTeaserTest } from '@/models/enums/test/ConsumerRequestsTeaserTest'
import { useI18n } from 'vue-i18n'
import { BundleProcessResponse, BundleResponse } from '@/models/Bundle'
import { OrganizationPublicResponse } from '@/models/Organization'
import { Routes } from '@/models/enums/Routes'
import { computed } from 'vue'
import LinkTeaser from '@/components/baseComponents/teasers/LinkTeaser.vue'
import BundleAvatar from '@/components/bundles/BundleAvatar.vue'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { useStatusHelper } from '@/composables/useStatusHelper'
import StatusIcon from '@/components/baseComponents/icons/StatusIcon.vue'

interface ExtendedBundles extends BundleResponse {
  organizationName?: OrganizationPublicResponse['name']
}

const props = defineProps({
  bundles: { type: Array<ExtendedBundles>, required: true },
  bundleProcesses: { type: Array<BundleProcessResponse>, required: true },
})

const { t } = useI18n()

const { getStatusSetFromStatusKey } = useStatusHelper()

const bundlesWithRequests = computed(() => {
  const bundleIds: string[] = []
  for (const bundleProcess of props.bundleProcesses) {
    if (
      bundleProcess.subscriptionProcess?.approvalStatus === StatusTypes.TERMINATED ||
      bundleProcess.subscriptionProcess?.approvalStatus === StatusTypes.IN_TERMINATION
    ) {
      continue // skip all bundleProcesses with StatusType TERMINATED and IN_TERMINATION since no action from provider is required
    }
    const bundleProcessId = bundleProcess.accessProcess?.bundleId || bundleProcess.bundleProcess?.bundleId || bundleProcess.subscriptionProcess?.bundleId
    if (bundleProcessId && !bundleIds.includes(bundleProcessId)) {
      bundleIds.push(bundleProcessId)
    }
  }
  return props.bundles.filter((bundle) => bundleIds.includes(bundle.id) && ![StatusTypes.REJECTED, StatusTypes.TERMINATED].includes(bundle.contractStatus))
})
</script>
<i18n lang="yaml">
de:
  consumerRequestsTeaser:
    headline: Deine Kundenanfragen
    placeholder: Keine Kundenanfragen gefunden
</i18n>
