<template>
  <DefaultList v-if="accessDefinitions?.length">
    <ListElement
      v-for="accessDefinition in accessDefinitions"
      :key="accessDefinition.id"
      class="bg-white"
    >
      <template #prepend>
        <DefaultAvatar
          size="48"
          color="grey-lighten-4"
        >
          <v-icon
            v-if="!accessDefinition.active"
            size="24"
            >{{ Icons.CIRCLE_ERROR }}</v-icon
          >
          <v-icon
            v-else
            size="24"
            >{{ getIconFromAccessDefinition(accessDefinition) }}</v-icon
          >
        </DefaultAvatar>
      </template>
      <template #title>{{ getNameFromAccessDefinition(accessDefinition) }}</template>
      <!-- wenn verfügbar -->
      <!-- <template #subtitle>{{ t('accessDefinitionList.lastEdited') }}</template> -->
      <template #append>
        <DefaultTag
          v-if="!accessDefinition.active"
          :variant="TagVariants.READ_ONLY"
          class="mr-4"
        >
          {{ t('accessDefinitionList.disabled') }}
        </DefaultTag>
        <DefaultTag
          v-if="accessDefinition.defaultDefinition"
          :variant="TagVariants.READ_ONLY"
          class="mr-4"
        >
          {{ t('accessDefinitionList.default') }}
        </DefaultTag>
        <template v-if="productView">
          <slot name="button"></slot>
        </template>
        <template v-else>
          <DefaultTag
            v-if="mappedProducts && accessDefinition.id && accessDefinition.id in mappedProducts"
            :prepend-icon="Icons.LINK_ALT"
            :append-icon="Icons.CHEVRON_DOWN"
            class="mr-4"
          >
            {{ t('accessDefinitionList.products', mappedProducts[accessDefinition.id].length) }}
            <v-menu
              open-on-click
              activator="parent"
              class="access-definition-list-menu"
              transition="slide-y-transition"
              :offset="5"
              :max-height="325"
            >
              <v-list
                density="compact"
                class="pa-2"
              >
                <div
                  v-for="(item, index) in mappedProducts[accessDefinition.id]"
                  :key="index"
                >
                  <v-list-item
                    class="pa-2"
                    @click="handleClick(item.id)"
                  >
                    <template #prepend>
                      <DefaultAvatar
                        size="20"
                        class="mr-2"
                      >
                        <v-icon
                          color="primary"
                          size="12"
                          >{{ Icons.PRODUCT }}</v-icon
                        >
                      </DefaultAvatar>
                    </template>
                    <p class="mb-0 sm font-weight-bold">{{ item.name }}</p>
                  </v-list-item>
                  <v-divider
                    v-if="index < mappedProducts[accessDefinition.id].length - 1"
                    class="my-1"
                  ></v-divider>
                </div>
              </v-list>
            </v-menu>
          </DefaultTag>

          <DefaultTag
            v-else
            :variant="TagVariants.READ_ONLY"
            :prepend-icon="Icons.LINK_ALT"
            class="mr-4"
          >
            {{ t('accessDefinitionList.noProducts') }}
          </DefaultTag>
          <div class="d-flex align-center">
            <v-menu
              location="bottom"
              transition="slide-y-transition"
            >
              <template #activator="{ props }">
                <IconButton
                  v-bind="props"
                  :icon="Icons.OPTIONS_VERTICAL"
                ></IconButton>
              </template>
              <v-list density="compact">
                <v-list-item
                  v-if="isGenerateToken(accessDefinition.accessExchangeType)"
                  class="context-menu-list-item"
                  flat
                  @click="toggleActivation(accessDefinition)"
                >
                  <template #prepend>
                    <v-icon :icon="accessDefinition.active ? Icons.CIRCLE_ERROR : Icons.CIRCLE_CHECK" />
                  </template>
                  <v-list-item-title v-if="accessDefinition.active">{{ t('accessDefinitionList.menu.deactivate') }}</v-list-item-title>
                  <v-list-item-title v-else>{{ t('accessDefinitionList.menu.activate') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="context-menu-list-item"
                  flat
                  @click="emit('setDefault', accessDefinition)"
                >
                  <template #prepend>
                    <v-icon>{{ Icons.AUTHENTICATION }}</v-icon>
                  </template>
                  <v-list-item-title>{{ t('accessDefinitionList.menu.default') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="isGenerateToken(accessDefinition.accessExchangeType)"
                  class="context-menu-list-item"
                  flat
                  @click="emit('update', accessDefinition)"
                >
                  <template #prepend>
                    <v-icon>{{ Icons.EDIT }}</v-icon>
                  </template>
                  <v-list-item-title>{{ t('accessDefinitionList.menu.edit') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="isGenerateToken(accessDefinition.accessExchangeType)"
                  flat
                  @click="emit('delete', accessDefinition)"
                >
                  <template #prepend>
                    <v-icon>{{ Icons.DELETE }}</v-icon>
                  </template>
                  <v-list-item-title>{{ t('accessDefinitionList.menu.delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </template>
      <template
        v-if="isGenerateToken(accessDefinition.accessExchangeType)"
        #additional
      >
        <div class="pl-12 ml-5">
          <DefaultTag
            :variant="TagVariants.READ_ONLY"
            class="mr-4"
          >
            {{ accessDefinition.accessType?.hashAlgorithm }}
          </DefaultTag>
          <DefaultTag
            :variant="TagVariants.READ_ONLY"
            class="mr-4"
          >
            {{ accessDefinition.accessType?.maxTokenLength }} {{ t('accessDefinitionList.maxTokenLength') }}
          </DefaultTag>
          <DefaultTag
            :variant="TagVariants.READ_ONLY"
            class="mr-4"
          >
            {{ t('validityPeriod', { validity: getRightPeriod(accessDefinition.accessType?.validityPeriod) }) }}
          </DefaultTag>
        </div>
      </template>
    </ListElement>
  </DefaultList>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { AccessDefinitionResponse } from '@/models/AccessDefinition'
import { AccessExchangeTypes } from '@/models/enums/AccessDefinitionTypes'
import { Icons } from '@/models/enums/IconTypes'
import { ProductResponse, ProductTeaser } from '@/models/Product'
import { PropType } from 'vue'
import { router } from '@/router'
import { Routes } from '@/models/enums/Routes'
import { useAccessHelper } from '@/composables/useAccessHelper'
import { TagVariants } from '@/models/enums/components/Tags'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DefaultTag from '@/components/baseComponents/tags/DefaultTag.vue'
import DefaultList from '../baseComponents/lists/DefaultList.vue'
import ListElement from '../baseComponents/lists/ListElement.vue'

const { t } = useI18n()
const emit = defineEmits(['toggleActivation', 'setDefault', 'update', 'delete'])

const { getIconFromAccessDefinition, getNameFromAccessDefinition, getRightPeriod } = useAccessHelper()

defineProps({
  accessDefinitions: {
    type: Array<AccessDefinitionResponse>,
    default: undefined,
  },
  mappedProducts: {
    type: Object as PropType<{ [key: string]: ProductTeaser[] }>,
    default: undefined,
  },
  productView: { type: Boolean, default: false },
})

/**
 * handleClick
 * @param {productId} productId
 */
function handleClick(productId: ProductResponse['id']): void {
  router.push({ name: Routes.PROVIDER_PRODUCTS_ACCESSES, params: { productId } })
}

/**
 * isGenerateToken
 * @param {accessExchangeType} accessExchangeType
 * @return {boolean}
 */
function isGenerateToken(accessExchangeType: AccessDefinitionResponse['accessExchangeType']): boolean {
  return accessExchangeType === AccessExchangeTypes.GENERATE_TOKEN
}

/**
 * toggleActivation
 * @param {accessDefinition} accessDefinition
 */
function toggleActivation(accessDefinition: AccessDefinitionResponse): void {
  emit('toggleActivation', { ...accessDefinition, active: !accessDefinition.active })
}
</script>

<i18n lang="yaml">
de:
  accessDefinitionList:
    maxTokenLength: Zeichen
    manualChip: Manuelle Übergabe des Authentifizierungsmittels
    default: Standard
    disabled: Inaktiv
    lastEdited: Zuletzt bearbeitet am
    products: '{count} API | {count} API-Produkte'
    noProducts: Keine API-Produkte
    menu:
      activate: Aktivieren
      deactivate: Deaktivieren
      default: Als Standard festlegen
      edit: Bearbeiten
      delete: Löschen
</i18n>
