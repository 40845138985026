<template>
  <v-tooltip
    location="bottom"
    transition="fade-transition"
    :max-width="240"
    content-class="rich-tooltip elevation-5 pa-4"
  >
    <template #activator="{ props }">
      <slot
        name="activator"
        :props="props"
      ></slot>
    </template>
    <template #default>
      <p
        v-if="$slots.headline"
        class="mb-0 font-weight-bold"
      >
        <slot name="headline"></slot>
      </p>
      <p
        v-if="$slots.copy"
        class="sm mb-0"
      >
        <slot name="copy"></slot>
      </p>
      <div
        v-if="$slots.actions"
        class="mt-4"
      >
        <slot name="actions"></slot>
      </div>
    </template>
  </v-tooltip>
</template>
<style lang="scss">
.rich-tooltip {
  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid rgb(var(--v-theme-white));
    transform: translateX(-50%);
    z-index: 1;
  }
}
</style>
