<template>
  <div class="content-container">
    <MdPreview
      v-if="binaryFileContent"
      :model-value="binaryFileContent"
      language="en-US"
      no-iconfont
      no-mermaid
      no-katex
      no-highlight
    />

    <p
      v-else
      class="mb-0"
    >
      {{ t('consumerProductFaq.noFaqAvailable') }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useFilesStore } from '@/store/files'
import { FilesAndFolderNodeTypes } from '@/models/enums/FilesAndFolderTypes'
import { ProductDocumentationFolderResponse } from '@/models/Folder'
import { MdPreview } from 'md-editor-v3'
import 'md-editor-v3/lib/style.css'

const { t } = useI18n()
const route = useRoute()
const filesStore = useFilesStore()
const { filesAndFolders } = storeToRefs(filesStore)
const productId = route.params.productId as string
const binaryFileContent = ref<string>()

try {
  await filesStore.GET_PRODUCT_DOCUMENTATION_STRUCTURE(productId)

  const faqFolder = filesAndFolders.value.find((folder) => {
    return folder.nodeType === FilesAndFolderNodeTypes.FAQ
  })

  const faqFile = (faqFolder as ProductDocumentationFolderResponse)?.files.find((file) => file.active)

  if (faqFile) {
    const binaryFileContentBlob = await filesStore.GET_PRODUCT_DOCUMENTATION_FILE_BY_ID(faqFile?.id)
    binaryFileContent.value = await binaryFileContentBlob.text()
  }
} catch {
  Promise.resolve()
}
</script>

<i18n lang="yaml">
de:
  consumerProductFaq:
    noFaqAvailable: Keine FAQ gefunden.
</i18n>
