<template>
  <div class="bg-white d-flex pa-16 w-100 rounded-lg border">
    <div class="d-flex my-0 mx-auto w-50 align-center flex-column text-center">
      <DefaultAvatar
        size="64"
        class="mb-8"
        color="grey-lighten-4"
      >
        <v-icon size="32">{{ icon }}</v-icon>
      </DefaultAvatar>
      <h3 class="create-teaser_headline">{{ headline }}</h3>
      <p v-if="copy">{{ copy }}</p>

      <DefaultButton
        class="mt-4"
        :color="PlattformColors.PRIMARY"
        :loading="loading"
        @click="emit('submit')"
        >{{ btnText }}</DefaultButton
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'

const emit = defineEmits(['submit'])

defineProps({
  icon: {
    type: String,
    required: true,
  },
  headline: {
    type: String,
    required: true,
  },
  copy: {
    type: String,
    default: '',
  },
  btnText: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})
</script>
