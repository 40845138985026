export enum NavBarTest {
  HELP_MENU = 'nav-bar-context-menu',
  DOCS_LINK = 'nav-bar-docs-link',
  SUPPORT_LINK = 'nav-bar-support-link',
  PRIVACY_LINK = 'nav-bar-privacy-link',
  TERMS_LINK = 'nav-bar-terms-link',
  IMPRINT_LINK = 'nav-bar-imprint-link',
  USER_MENU = 'nav-bar-user-menu',
  LOG_IN = 'nav-bar-log-in',
}
