<template>
  <DialogDefaultLayoutEnter>
    <template #content>
      <h1>{{ t('selectOrganizationOverlay.headline') }}</h1>
      <p class="mb-8">{{ t('selectOrganizationOverlay.copy') }}</p>
      <DefaultList>
        <v-item-group v-model="selectedElement">
          <v-item
            v-for="element in groupedProviderOrganizations"
            :key="element?.organizationId"
            v-slot="{ isSelected, toggle }"
            :value="element"
          >
            <ListElement
              class="bg-secondary"
              clickable
              @click="toggle"
            >
              <template #prepend>
                <OrganizationLogo :organization-logo="element.logo" />
              </template>
              <template #title>{{ element.name }}</template>
              <template #subtitle>{{ t('selectOrganizationOverlay.organizationType.provider') }}</template>
              <template #append>
                <DefaultRadioButton :model-value="isSelected" />
              </template>
            </ListElement>
          </v-item>
          <v-item
            v-for="element in groupedConsumerOrganizations"
            :key="element?.organizationId"
            v-slot="{ isSelected, toggle }"
            :value="element"
          >
            <ListElement
              class="bg-secondary"
              clickable
              @click="toggle"
            >
              <template #prepend>
                <OrganizationLogo :organization-logo="element.logo" />
              </template>
              <template #title>{{ element.name }}</template>
              <template #subtitle>{{ t('selectOrganizationOverlay.organizationType.consumer') }}</template>
              <template #append>
                <DefaultRadioButton :model-value="isSelected" />
              </template>
            </ListElement>
          </v-item>
          <v-item
            v-if="groupedTenantOrganization"
            v-slot="{ isSelected, toggle }"
            :value="groupedTenantOrganization"
          >
            <ListElement
              class="bg-secondary"
              clickable
              @click="toggle"
            >
              <template #prepend>
                <DefaultAvatar
                  size="48"
                  :color="PlattformColors.LIGHT"
                >
                  <v-icon>{{ Icons.ORGANIZATION_SETTINGS }}</v-icon>
                </DefaultAvatar>
              </template>
              <template #title>
                {{ t('selectOrganizationOverlay.organizationType.tenant') }}
              </template>
              <template #append>
                <DefaultRadioButton :model-value="isSelected" />
              </template>
            </ListElement>
          </v-item>
        </v-item-group>
      </DefaultList>
    </template>
    <template #buttons>
      <DefaultButton
        :disabled="!selectedElement"
        @click="emit('submit', selectedElement)"
        >{{ t('buttons.next') }}</DefaultButton
      >
    </template>
  </DialogDefaultLayoutEnter>
</template>
<script lang="ts" setup>
import DialogDefaultLayoutEnter from '@/components/dialogs/layout/DialogDefaultLayoutEnter.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import ListElement from '@/components/baseComponents/lists/ListElement.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import OrganizationLogo from '@/components/organizations/OrganizationLogo.vue'
import { useI18n } from 'vue-i18n'
import { computed, ComputedRef, ref } from 'vue'
import { AuthorizationsContexts } from '@/models/enums/AuthorizationsContextTypes'
import { UserOrganization } from '@/models/User'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import DefaultRadioButton from '../baseComponents/radio/DefaultRadioButton.vue'

const props = defineProps({
  myOrganizations: { type: Array<UserOrganization>, required: true },
})

const emit = defineEmits(['submit'])

const { t } = useI18n()

const selectedElement = ref<UserOrganization>()

const groupedTenantOrganization: ComputedRef<UserOrganization | undefined> = computed(() => {
  return props.myOrganizations.find((userOrganization) => {
    return userOrganization.authorizations.some((authorization) => {
      return authorization.contexts.find((context) => context.context === AuthorizationsContexts.TENANT)
    })
  })
})

const groupedProviderOrganizations: ComputedRef<UserOrganization[]> = computed(() => {
  return props.myOrganizations.filter((userOrganization) => {
    return userOrganization.authorizations.some((authorization) => {
      return authorization.contexts.find((context) => context.context === AuthorizationsContexts.PROVIDER)
    })
  })
})

const groupedConsumerOrganizations: ComputedRef<UserOrganization[]> = computed(() => {
  return props.myOrganizations.filter((userOrganization) => {
    return userOrganization.authorizations.some((authorization) => {
      return authorization.contexts.find((context) => context.context === AuthorizationsContexts.CONSUMER)
    })
  })
})
</script>

<i18n lang="yaml">
de:
  selectOrganizationOverlay:
    headline: Ansicht wählen
    copy: Wähle eine deiner Organisationen oder die Verwaltungskonsole um fortzufahren.
    organizationType:
      provider: API anbieten
      consumer: API nutzen
      tenant: Verwaltungskonsole
</i18n>
