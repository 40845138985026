<template>
  <v-row
    v-if="product?.detailedDescription"
    class="content-container product-details"
  >
    <v-col :cols="product.productLinks?.length ? 9 : 11"
      ><MdPreview
        v-model="product.detailedDescription"
        language="en-US"
        no-iconfont
        no-mermaid
        no-katex
        no-highlight
    /></v-col>
    <v-col
      :cols="product.productLinks?.length ? 3 : 1"
      class="px-0"
    >
      <div class="d-flex justify-end">
        <IconButton
          :icon="Icons.EDIT"
          @click="detailedDescriptionDialogRef?.open()"
        />
      </div>
      <template v-if="product.productLinks?.length">
        <p class="highlight xs mt-2">{{ t('providerProductDetail.links.subline') }}</p>
        <DefaultButton
          v-for="productLink in product.productLinks"
          :key="productLink.id"
          :color="PlattformColors.SECONDARY"
          :append-icon="Icons.EXTERNAL_LINK"
          :href="productLink.url"
          :size="ButtonSizes.DEFAULT"
          block
          target="_blank"
          class="px-4 mb-2 justify-space-between"
        >
          <div class="text-truncate">
            {{ productLink.name }}
          </div>
        </DefaultButton>
      </template>
    </v-col>
  </v-row>
  <div v-else>
    <CreateTeaser
      :icon="Icons.ORDERED_LIST"
      :headline="t('providerProductDetail.teaser.headline')"
      :copy="t('providerProductDetail.teaser.copy')"
      :btn-text="t('providerProductDetail.teaser.button')"
      @submit="detailedDescriptionDialogRef?.open()"
    />
  </div>
  <Dialog
    ref="detailedDescriptionDialogRef"
    @on-close="detailedDescriptionDialogRef?.close()"
  >
    <DialogProductFormDetail
      :product-object="product"
      :loading="loading"
      @cancel="detailedDescriptionDialogRef?.close()"
      @submit="(detailedDescription: string, productLinks?: ProductLink[]) => updateProduct(detailedDescription, productLinks)"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { MdPreview } from 'md-editor-v3'
import { ref } from 'vue'
import { useProductStore } from '@/store/products'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { Icons } from '@/models/enums/IconTypes'
import { ProductLink } from '@/models/Product'
import { PlattformColors } from '@/models/enums/ColorSets'
import CreateTeaser from '@/components/layout/CreateTeaser.vue'
import Dialog from '@/components/layout/Dialog.vue'
import DialogProductFormDetail from '@/components/dialogs/DialogProductFormDetail.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { ButtonSizes } from '@/models/enums/components/Buttons'

const { t } = useI18n()

const productStore = useProductStore()
const alertStore = useAlertStore()
const { product } = storeToRefs(productStore)

const loading = ref(false)
const detailedDescriptionDialogRef = ref<InstanceType<typeof Dialog>>()

/**
 * updateProduct
 * @param {string} detailedDescription
 * @param {ProductLink[]} productLinks
 */
async function updateProduct(detailedDescription: string, productLinks?: ProductLink[]): Promise<void> {
  if (product?.value) {
    try {
      loading.value = true
      await productStore.UPDATE({ detailedDescription, productLinks }, product.value.id)
      alertStore.add({
        text: t('providerProductDetail.update.success'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      return Promise.resolve()
    } finally {
      loading.value = false
      detailedDescriptionDialogRef.value?.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.product-details {
  :deep(.v-btn__content) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

<i18n lang="yaml">
de:
  providerProductDetail:
    update:
      success: Deine API wurde erfolgreich aktualisiert!
    teaser:
      headline: Was macht deine API aus?
      copy: Füge eine Beschreibung hinzu, um deine API zu spezifizieren.
      button: Beschreibung hinzufügen
    links:
      subline: Externe Links
</i18n>
