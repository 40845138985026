<template>
  <DialogDefaultLayoutEnter :on-submit="() => handleSubmit()">
    <template #content>
      <h1>{{ t('chooseContact.headline') }}</h1>
      <v-list
        v-if="availableContacts?.length"
        class="choose-contact-list"
      >
        <v-item-group
          v-model="selectedContact"
          mandatory
        >
          <v-item
            v-for="contact in availableContacts"
            :key="contact.id"
            v-slot="{ isSelected, toggle }"
            :value="contact"
          >
            <v-list-item
              class="py-4 px-6 mb-4 bg-secondary"
              @click="toggle"
            >
              <template #prepend>
                <DefaultAvatar
                  size="40"
                  :color="PlattformColors.INFO"
                >
                  <v-icon :icon="Icons.USER" />
                </DefaultAvatar>
              </template>
              <v-list-item-title class="choose-contact-list__title">{{ contact.name }}</v-list-item-title>
              <template #append>
                <DefaultRadioButton :model-value="isSelected" />
              </template>
            </v-list-item>
          </v-item>
        </v-item-group>
      </v-list>
      <InfoBox
        v-else
        :headline="t('chooseContact.noContacts.headline')"
        :copy="t('chooseContact.noContacts.copy')"
      />
    </template>
    <template #buttons>
      <DefaultButton
        :color="PlattformColors.PRIMARY"
        :disabled="isSubmitButtonDisabled"
        :loading="loading"
        @click="handleSubmit()"
      >
        {{ t('buttons.send') }}
      </DefaultButton>

      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click="emit('cancel')"
      >
        {{ t('buttons.cancel') }}
      </DefaultButton>
    </template>
  </DialogDefaultLayoutEnter>
</template>
<script lang="ts" setup>
import { ContactResponse } from '@/models/Contact'
import { Icons } from '@/models/enums/IconTypes'
import { computed, ref } from 'vue'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import { useI18n } from 'vue-i18n'
import { PropType } from 'vue'
import InfoBox from '../InfoBox.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import DefaultRadioButton from '../baseComponents/radio/DefaultRadioButton.vue'

const { t } = useI18n()

const emit = defineEmits(['submit', 'cancel'])

const props = defineProps({
  availableContacts: { type: Array<ContactResponse>, default: undefined },
  preSelectedContact: { type: Object as PropType<ContactResponse>, default: undefined },
})

const loading = ref(false)
const selectedContact = ref<ContactResponse>()
if (props.preSelectedContact) selectedContact.value = props.preSelectedContact

const isSubmitButtonDisabled = computed(() => !selectedContact.value || selectedContact.value.id === props.preSelectedContact?.id)

/**
 * handleSubmit
 */
function handleSubmit(): void {
  emit('submit', selectedContact.value)
  loading.value = true
}
</script>
<style lang="scss" scoped>
.choose-contact-list {
  &__title {
    font-weight: 700;
  }
}
</style>
<i18n lang="yaml">
de:
  chooseContact:
    headline: Kontaktperson wählen
    noContacts:
      headline: Keine Kontaktpersonen gefunden
      copy: Füge Kontaktpersonen über den Reiter “Meine Organisation” hinzu, um sie deinen Apps zuzuweisen.
</i18n>
