<template>
  <v-form
    v-model="teamNameValid"
    @submit.prevent="emit('submit', teamName)"
  >
    <DialogDefaultLayout>
      <template #content>
        <h1>{{ t('createTeam.headline') }}</h1>
        <p>{{ t('createTeam.copy') }}</p>

        <DefaultTextField
          v-model="teamName"
          autofocus
          :label="t('createTeam.label')"
          :rules="teamNameRules"
          required
        />
      </template>
      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          :disabled="!teamNameValid"
        >
          {{ t('buttons.create') }}
        </DefaultButton>

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayout>
  </v-form>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { isNotEmpty, isMaxLength } from '@/validators'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultTextField from '@/components/baseComponents/inputs/DefaultTextField.vue'

const { t } = useI18n()

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
})

const teamName = ref<string>()
const teamNameValid = ref(false)

const teamNameRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 50)])

const emit = defineEmits(['submit', 'cancel'])
</script>

<i18n lang="yaml">
de:
  createTeam:
    headline: Neues Team erstellen
    copy: Bitte gib deinem Team einen Namen. Beachte dabei, dass der Teamname eindeutig sein muss.
    label: Teamname*
</i18n>
