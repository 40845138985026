<template>
  <div>
    <v-form
      v-model="settingsPlanForm"
      @submit.prevent="submitProductPlanSettings()"
    >
      <DialogDefaultLayout>
        <template #content>
          <h1>{{ t('productPlanSettings.headline') }}</h1>
          <p>{{ t('productPlanSettings.copy') }}</p>

          <v-radio-group v-model="product.supportMultiplePlans">
            <DefaultRadioButton :value="false">
              <template #label>{{ t('productPlanSettings.form.single') }}</template>
            </DefaultRadioButton>
            <DefaultRadioButton :value="true">
              <template #label>{{ t('productPlanSettings.form.multiple') }}</template>
            </DefaultRadioButton>
          </v-radio-group>
        </template>

        <template #buttons>
          <DefaultButton
            type="submit"
            :color="PlattformColors.PRIMARY"
            :disabled="!settingsPlanForm"
            :loading="loading"
          >
            {{ t('buttons.save') }}
          </DefaultButton>

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="emit('cancel')"
            >{{ t('buttons.cancel') }}</DefaultButton
          >
        </template>
      </DialogDefaultLayout>
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { ProductResponse } from '@/models/Product'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultRadioButton from '../baseComponents/radio/DefaultRadioButton.vue'

const { t } = useI18n()

const emit = defineEmits(['submit', 'cancel'])

const props = defineProps({
  productObject: { type: Object as PropType<ProductResponse>, default: undefined },
})

const settingsPlanForm = ref(false)
const loading = ref(false)
const product = ref({
  supportMultiplePlans: props.productObject?.supportMultiplePlans,
})
/**
 * submitPlan
 */
function submitProductPlanSettings(): void {
  loading.value = true
  emit('submit', product.value)
}
</script>

<i18n lang="yaml">
de:
  productPlanSettings:
    headline: Einstellungen Pläne
    copy: Konfiguriere die Auswahlmöglichkeiten deiner Pläne
    form:
      single: Einfachauswahl (z.B. bei Paketpreisen)
      multiple: Mehrfachauswahl (z.B. bei einzelnen Funktionen)
</i18n>
