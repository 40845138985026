<template>
  <div class="mb-5">
    <v-list-item
      class="pa-6"
      min-height="96"
      :to="to"
      :data-test="dataTest"
      v-on="clickable ? { click: () => emit('click') } : {}"
    >
      <template
        v-if="$slots.prepend"
        #prepend
      >
        <div class="mr-6">
          <slot name="prepend"></slot>
        </div>
      </template>
      <template
        v-if="$slots.title"
        #title
      >
        <h3 class="mb-0">
          <slot name="title"></slot>
        </h3>
      </template>
      <template
        v-if="$slots.subtitle"
        #subtitle
      >
        <p class="mb-0 xs">
          <slot name="subtitle"></slot>
        </p>
      </template>
      <template #append>
        <slot name="append"></slot>
      </template>
    </v-list-item>
    <div
      v-if="$slots.additional"
      class="px-6 pb-6"
    >
      <slot name="additional"></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

defineProps({
  to: { type: Object as PropType<RouteLocationRaw>, default: undefined },
  dataTest: { type: String, default: undefined },
  clickable: { type: Boolean, default: false },
})

const emit = defineEmits(['click'])
</script>
