<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('consumer.overview.headline') }}
    </template>
  </GeneralPageTitle>

  <Searchbar
    :filter-options="getFilterOptionsOrganizationStatus()"
    :sort-options="getSortOptionsOrganizationRelationConsumer()"
    :searchbar-label="t('searchbox.searchbarLabel')"
    :result-label="t('searchbox.countedConsumer', { count: organizationRelations.length })"
    :loading="searchbarLoading && !initLoading"
    @updated-filter="handleFilter"
  />

  <div
    v-if="initLoading"
    class="d-flex justify-center"
  >
    <v-progress-circular
      indeterminate
      size="100"
      :color="PlattformColors.PRIMARY"
    />
  </div>
  <Organizations
    v-else-if="organizationRelations.length"
    :organization-relations="organizationRelations"
    :organization-context="activeOrganization?.provider ? OrganizationTypes.CONSUMER : OrganizationTypes.PROVIDER"
    @handle-click="handleClick"
  />

  <p v-else>
    {{ t('consumer.overview.noConsumers') }}
  </p>
</template>
<script lang="ts" setup>
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import Organizations from '@/components/organizations/Organizations.vue'
import Searchbar from '@/components/Searchbar.vue'
import { OrganizationPublicResponse } from '@/models/Organization'
import { Routes } from '@/models/enums/Routes'
import { useSearchHelper } from '@/composables/useSearchHelper'
import { useOrganizationStore } from '@/store/organizations'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { SearchbarValues } from '@/models/SearchbarValues'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { FilterValuesOrganization } from '@/models/enums/FilterValues'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()
const router = useRouter()
const organizationStore = useOrganizationStore()
const myOrganizationStore = useMyOrganizationStore()

const { organizationRelations } = storeToRefs(organizationStore)
const { getSortOptionsOrganizationRelationConsumer, getFilterOptionsOrganizationStatus } = useSearchHelper()
const { activeOrganization } = storeToRefs(myOrganizationStore)

const searchbarLoading = ref(false)
const initLoading = ref(true)

/**
 * handleClick
 * @param {consumerId} consumerId
 */
function handleClick(consumerId: OrganizationPublicResponse['id']): void {
  router.push({ name: Routes.PROVIDER_CONSUMERS_BUNDLES, params: { consumerId } })
}

/**
 * handleFilter
 * @param {searchbarValues} searchbarValues
 */
async function handleFilter(searchbarValues: SearchbarValues): Promise<void> {
  if (activeOrganization?.value) {
    searchbarLoading.value = true
    let filter = searchbarValues?.selectedFilterOptionValues?.length ? searchbarValues?.selectedFilterOptionValues?.join(' OR ') : ''

    if (filter.includes(FilterValuesOrganization.INACTIVE)) {
      filter = filter + ' OR ' + FilterValuesOrganization.INITIAL + ' OR ' + FilterValuesOrganization.IN_TERMINATION
    }

    try {
      await organizationStore.GET_ORGANIZATIONS_IN_RELATION(activeOrganization?.value?.id, {
        ...(searchbarValues.searchQuery ? { q: searchbarValues.searchQuery } : {}),
        filter: filter,
        sort: searchbarValues.selectedSortOptionValue,
      })
    } finally {
      searchbarLoading.value = false
      initLoading.value = false
    }
  }
}
</script>

<i18n lang="yaml">
de:
  consumer:
    overview:
      headline: Deine Kunden
      subHeadline: '{count} Kunde gefunden. | {count} Kunden gefunden.'
      copy: Hier erhältst du eine Übersicht der Kunden, die deine API-Produkte bereits nutzen.
      noConsumers: Aktuell hast du noch keine Kunden.
  searchbox:
    searchbarLabel: Kunden durchsuchen
    countedConsumer: '{count} Kunden'
</i18n>
