<template>
  <div class="content-container">
    <div class="d-flex align-center mb-8">
      <h2 class="mb-0 flex-grow-1">{{ t('businessCaseList.headline') }}</h2>
      <div class="d-flex align-center">
        <iconButton
          v-if="bundle?.contractStatus === StatusTypes.DRAFT || (bundle?.contractStatus === StatusTypes.APPROVED && !isProvider)"
          :icon="Icons.EDIT"
          @click="emit('openEditDialog')"
        ></iconButton>
      </div>
    </div>
    <div class="w-75">
      <template
        v-for="businessCase in bundle?.businessCases"
        :key="businessCase.businessCaseKey"
      >
        <p class="highlight">{{ getBundleBusinessCaseName(businessCase.businessCaseKey) }}</p>
        <p class="mb-8 text-break">{{ businessCase.pendingText || businessCase.text }}</p>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useBundleBusinessCaseHelper } from '@/composables/useBundleBusinessCaseHelper'
import { BundleResponse } from '@/models/Bundle'
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import iconButton from '@/components/baseComponents/buttons/IconButton.vue'

const { t } = useI18n()

const { getBundleBusinessCaseName } = useBundleBusinessCaseHelper()

const emit = defineEmits(['openEditDialog'])

defineProps({
  bundle: { type: Object as PropType<BundleResponse>, default: undefined },
  isProvider: { type: Boolean, default: false },
})
</script>

<i18n lang="yaml">
de:
  businessCaseList:
    headline: Business Case
    pendingConsumer: Deine Business Case Änderung wurde eingereicht.
    pendingProvider: Eine Business Case Änderung wurde eingereicht.
</i18n>
