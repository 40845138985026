<template>
  <v-text-field
    v-model="model"
    :autofocus="autofocus"
    :clearable="clearable"
    :color="PlattformColors.CHANGE"
    :counter="counter"
    :density="density"
    :hide-details="hideDetails"
    :hint="hint"
    :label="label"
    :readonly="readonly"
    variant="outlined"
    :width="width"
  >
    <template
      v-if="$slots['prepend-inner']"
      #prepend-inner
    >
      <slot name="prepend-inner"></slot>
    </template>
    <template
      v-if="$slots['append-inner']"
      #append-inner
    >
      <slot name="append-inner"></slot>
    </template>
    <template
      v-if="$slots.append"
      #append
    >
      <slot name="append"></slot>
    </template>
  </v-text-field>
</template>
<script lang="ts" setup>
import { PlattformColors } from '@/models/enums/ColorSets'
import { InputDensity } from '@/models/enums/components/Inputs'
import { PropType } from 'vue'

defineProps({
  autofocus: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  counter: { type: Boolean, default: false },
  density: { type: String as PropType<InputDensity>, default: InputDensity.DEFAULT },
  hideDetails: { type: Boolean, default: false },
  hint: { type: String, default: undefined },
  label: { type: String, default: undefined },
  readonly: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  width: { type: String, default: undefined },
})

const model = defineModel<string | null>()
</script>
