<template>
  <div v-if="bundles">
    <Searchbar
      :filter-options="getFilterOptionsBundleStatus()"
      :sort-options="getDefaultSortOptions()"
      :searchbar-label="t('searchbox.searchbarLabel')"
      :result-label="t('searchbox.results', { count: bundlesNoDraftStatus.length })"
      :loading="searchbarLoading && !initLoading"
      @updated-filter="handleFilter"
    />
    <div
      v-if="initLoading"
      class="d-flex justify-center"
    >
      <v-progress-circular
        indeterminate
        size="100"
        :color="PlattformColors.PRIMARY"
      />
    </div>
    <Bundles
      v-else
      :bundles="bundlesNoDraftStatus"
      :bundle-link="OrganizationTypes.PROVIDER"
    />
  </div>
</template>

<script lang="ts" setup>
import Bundles from '@/components/bundles/Bundles.vue'
import Searchbar from '@/components/Searchbar.vue'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { SearchbarValues } from '@/models/SearchbarValues'
import { useBundleStore } from '@/store/bundles'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useSearchHelper } from '@/composables/useSearchHelper'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()
const route = useRoute()
const bundleStore = useBundleStore()

const { bundles } = storeToRefs(bundleStore)

const { getDefaultSortOptions, getFilterOptionsBundleStatus } = useSearchHelper()

const searchbarLoading = ref(false)
const initLoading = ref(true)

// TODO remove filter for bundleStatus, when DRAFT-Bundles get filtered by BE
const bundlesNoDraftStatus = computed(() => bundles.value.filter((bundle) => bundle.contractStatus !== StatusTypes.DRAFT))

const organizationFilter: string = `organizationId:${route.params.consumerId}`

/**
 * handleFilter
 * @param {searchbarValues} searchbarValues
 */
async function handleFilter(searchbarValues: SearchbarValues): Promise<void> {
  searchbarLoading.value = true
  const filter = searchbarValues?.selectedFilterOptionValues?.length
    ? searchbarValues?.selectedFilterOptionValues?.join(' OR ') + ' AND ' + organizationFilter
    : organizationFilter
  try {
    await bundleStore.GET({
      ...(searchbarValues.searchQuery ? { q: searchbarValues.searchQuery } : {}),
      filter: filter,
      sort: searchbarValues.selectedSortOptionValue,
    })
  } finally {
    searchbarLoading.value = false
    initLoading.value = false
  }
}
</script>
<i18n lang="yaml">
de:
  searchbox:
    searchbarLabel: Apps durchsuchen
    results: '{count} App | {count} Apps'
</i18n>
