<template>
  <LinkTeaser :link="Routes.CONSUMER_BUNDLES">
    <template #title>
      {{ t('appTeaser.headline') }}
    </template>
    <template #text>
      <v-list
        v-if="bundles.length"
        class="pa-0"
      >
        <v-list-item
          v-for="(bundle, i) in bundles.slice(0, 5)"
          :key="bundle.id"
          :to="{ name: Routes.CONSUMER_BUNDLES_PRODUCTS, params: { bundleId: bundle.id } }"
          :data-cy="AppTeaserTest.BUNDLE_TEASER_ITEM"
          class="pa-0"
          :class="{ 'mt-3': i > 0 }"
        >
          <template #prepend>
            <BundleAvatar :size="48" />
          </template>
          <template #title>
            <p class="sm mb-0 highlight">{{ bundle.name }}</p>
          </template>
          <template #subtitle>
            <p class="xs mb-0">{{ bundle.providerOrganization?.name }}</p>
          </template>

          <template #append>
            <StatusIcon
              class="mr-2"
              :status-type="
                bundle.contractStatus === StatusTypes.REQUESTED
                  ? getStatusSetFromStatusKey(StatusTypes.IN_PROGRESS)?.key
                  : getStatusSetFromStatusKey(bundle.contractStatus)?.key
              "
              :width="20"
            />
          </template>
        </v-list-item>
      </v-list>
      <DefaultAvatar
        v-else
        size="120"
        class="mx-auto"
      >
        <v-icon
          color="primary"
          size="60"
          >{{ Icons.APPS }}</v-icon
        >
      </DefaultAvatar>
    </template>
    <template #actions>
      <DefaultButton
        v-if="bundles.length < 5"
        :prepend-icon="Icons.CIRCLE_ADD"
        :variant="ButtonVariants.PLAIN"
        :color="PlattformColors.TERTIERY"
        @click="emit('showCreateBundleDialog')"
        >{{ t('appTeaser.button') }}</DefaultButton
      >
    </template>
  </LinkTeaser>
</template>

<script lang="ts" setup>
import { AppTeaserTest } from '@/models/enums/test/AppTeaserTest'
import { useI18n } from 'vue-i18n'
import { BundleResponse } from '@/models/Bundle'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import { useStatusHelper } from '@/composables/useStatusHelper'
import { PlattformColors } from '@/models/enums/ColorSets'
import StatusIcon from '@/components/baseComponents/icons/StatusIcon.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { ButtonVariants } from '@/models/enums/components/Buttons'
import LinkTeaser from '@/components/baseComponents/teasers/LinkTeaser.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import BundleAvatar from '@/components/bundles/BundleAvatar.vue'
import { StatusTypes } from '@/models/enums/StatusTypes'

const { t } = useI18n()

const emit = defineEmits(['showCreateBundleDialog'])

const { getStatusSetFromStatusKey } = useStatusHelper()

defineProps({
  bundles: { type: Array<BundleResponse>, required: true },
})
</script>
<i18n lang="yaml">
de:
  appTeaser:
    headline: Deine Apps
    button: Neue App erstellen
</i18n>
