<template>
  <div>
    <DialogDefaultLayoutEnter :on-submit="() => handleSubmit()">
      <template #content>
        <h1>{{ t('editAccessDefinition.headline') }}</h1>
        <span class="edit-access-definition--subtitle">{{ t('editAccessDefinition.subtitle') }}</span>

        <v-list>
          <v-item-group
            v-model="selectAccessDefinition"
            mandatory
          >
            <v-item
              v-for="accessDefinition in accessDefinitions"
              :key="accessDefinition.id"
              v-slot="{ isSelected, toggle }"
              :value="accessDefinition.id"
            >
              <v-list-item
                class="edit-access-definition--list-item py-4 px-6 bg-secondary"
                @click="toggle"
              >
                <template #prepend>
                  <DefaultAvatar
                    size="40"
                    class="mr-4"
                    :color="PlattformColors.INFO"
                  >
                    <v-icon>{{ getIconFromAccessDefinition(accessDefinition) }}</v-icon>
                  </DefaultAvatar>
                </template>
                <template #title>
                  {{ getNameFromAccessDefinition(accessDefinition) }}
                </template>

                <template #append>
                  <DefaultRadioButton :model-value="isSelected" />
                </template>
              </v-list-item>
            </v-item>
          </v-item-group>
        </v-list>
        <InfoBox
          :headline="t('editAccessDefinition.info.headline')"
          :copy="t('editAccessDefinition.info.copy')"
          :info-type="StatusTypes.WARNING"
          :icon-color="PlattformColors.LIGHT"
        />
      </template>
      <template #buttons>
        <DefaultButton
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          @click="handleSubmit()"
        >
          {{ t('editAccessDefinition.acceptSelectionBtn') }}
        </DefaultButton>

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayoutEnter>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import { AccessDefinitionResponse } from '@/models/AccessDefinition'
import { ref } from 'vue'
import { useAccessHelper } from '@/composables/useAccessHelper'
import InfoBox from '../InfoBox.vue'
import { StatusTypes } from '@/models/enums/StatusTypes'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import DefaultRadioButton from '../baseComponents/radio/DefaultRadioButton.vue'

const loading = ref(false)
const props = defineProps({
  accessDefinitions: {
    type: Array<AccessDefinitionResponse>,
    default: () => [],
  },
  accessDefinitionSelected: {
    type: String,
    default: undefined,
  },
})

/**
 * handleSubmit
 */
function handleSubmit(): void {
  emit('submit', selectAccessDefinition.value)
  loading.value = true
}

const selectAccessDefinition = ref<AccessDefinitionResponse['id'] | undefined>(props.accessDefinitionSelected)
const emit = defineEmits(['submit', 'cancel'])
const { t } = useI18n()
const { getIconFromAccessDefinition, getNameFromAccessDefinition } = useAccessHelper()
</script>
<style lang="scss" scoped>
.edit-access-definition {
  &--subtitle {
    font-size: 1rem;
    line-height: 1.625rem;
    font-weight: 700;
  }

  &--list-item {
    margin-bottom: 1rem;
  }
}

:deep(.v-list-item-title) {
  font-weight: 700;
}
</style>
<i18n lang="yaml">
de:
  editAccessDefinition:
    headline: Authentifizierungsmethode ändern
    subtitle: Verfügbare Authentifizierungsmethoden
    info:
      headline: Achtung
      copy: Die bisherigen Authentifizierungsmittel bleiben weiterhin im Status aktiv und müssen manuell an der App deaktiviert werden, wenn sie nicht mehr
        unterstützt werden.
    acceptSelectionBtn: Auswahl übernehmen
</i18n>
