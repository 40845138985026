<template>
  <DefaultList>
    <ListElementExpandable
      v-for="bundle in bundles"
      :key="bundle.id"
      :to="bundleRoute(bundle.id)"
      :expandable="getAvailableBundleProducts(bundle).length > 0"
      class="bg-white"
    >
      <template #prepend>
        <BundleAvatar />
      </template>
      <template #title>
        <h3 class="mb-1">{{ bundle.name }}</h3>
      </template>
      <template #subtitle>
        <span v-if="bundle.providerOrganization.name"> {{ bundle.providerOrganization.name }} </span>
        <v-divider
          vertical
          length="12"
          thickness="1"
          color="black"
          opacity=".5"
          class="mx-2"
        />
        <span v-if="bundle.createdAt">
          {{
            t('createdAt', {
              d: format(new Date(bundle.createdAt), 'dd.MM.yyyy'),
            })
          }}
        </span>
      </template>
      <template #append>
        <PlainTooltip>
          <template #activator="{ props }">
            <DefaultAvatar
              v-if="(bundle.contractStatus === StatusTypes.APPROVED || bundle.contractStatus === StatusTypes.IN_TERMINATION) && !bundle.accessStatus.access"
              v-bind="props"
              size="32"
              :color="PlattformColors.ERROR"
              class="mr-4"
            >
              <v-icon size="16">{{ Icons.LOCK }}</v-icon>
            </DefaultAvatar>
          </template>
          <template #default>{{ t('bundlesOverview.toggleBtn.tooltips.inactive') }}</template>
        </PlainTooltip>
        <FlagStatus
          class="mr-3"
          :color="getStatusSetFromStatusKey(bundle.contractStatus)?.color"
          :status-key="getStatusSetFromStatusKey(bundle.contractStatus)?.key"
          :outlined="getStatusSetFromStatusKey(bundle.contractStatus)?.outlined"
        >
          {{ t(`bundleStatus.${bundle.contractStatus}`) }}
        </FlagStatus>
      </template>
      <template #expand>
        <div class="pl-4">
          <ProductList
            :products="bundleProductsWithLogo(bundle.providerOrganization.logo, getAvailableBundleProducts(bundle))"
            @handle-product-click="(productId: BundleProductResponse['id']) => handleProductClick(productId)"
          />
        </div>
      </template>
    </ListElementExpandable>
  </DefaultList>
</template>

<script lang="ts" setup>
import { BundleProductResponse, BundleProductWithLogo, BundleResponse } from '@/models/Bundle'
import { OrganizationPublicResponse } from '@/models/Organization'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRouter } from 'vue-router'
import { format } from 'date-fns'
import ProductList from '@/components/products/ProductList.vue'
import { Routes } from '@/models/enums/Routes'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { useStatusHelper } from '@/composables/useStatusHelper'
import FlagStatus from '@/components/baseComponents/flags/FlagStatus.vue'
import BundleAvatar from '@/components/bundles/BundleAvatar.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import ListElementExpandable from '@/components/baseComponents/lists/ListElementExpandable.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import PlainTooltip from '@/components/baseComponents/tooltips/PlainTooltip.vue'
import { useBundleProductsHelper } from '@/composables/useBundleProductsHelper'

const componentProps = defineProps({
  bundles: { type: Array<BundleResponse>, required: true },
  bundleLink: { type: String, required: true },
})

const { t } = useI18n()
const router = useRouter()

const { getStatusSetFromStatusKey } = useStatusHelper()
const { isBundleProductActive } = useBundleProductsHelper()

/**
 * @param {BundleResponse} bundle
 * @return {BundleProductResponse[]}
 */
function getAvailableBundleProducts(bundle: BundleResponse): BundleProductResponse[] {
  return bundle.bundleProducts.filter((bundleProduct) => isBundleProductActive(bundleProduct, bundle.contractStatus))
}

/**
 * @param {OrganizationPublicResponse.logo} logo
 * @param {BundleProductResponse[]} products
 * @return {BundleProductWithLogo}
 */
function bundleProductsWithLogo(logo: OrganizationPublicResponse['logo'], products: BundleProductResponse[]): BundleProductWithLogo[] {
  return products.map((product) => ({ ...product, logo }))
}

/**
 * @param {string} bundleId
 * @return {RouteLocationRaw}
 */
function bundleRoute(bundleId: BundleResponse['id']): RouteLocationRaw {
  return componentProps.bundleLink === OrganizationTypes.CONSUMER
    ? { name: Routes.CONSUMER_BUNDLES_PRODUCTS, params: { bundleId } }
    : { name: Routes.PROVIDER_BUNDLES_PRODUCTS, params: { bundleId } }
}

/**
 * handleProductClick
 * @param {productId} productId
 */
function handleProductClick(productId: BundleProductResponse['id']): void {
  if (componentProps.bundleLink === OrganizationTypes.CONSUMER) {
    router.push({ name: Routes.CONSUMER_PRODUCTS_INFOS, params: { productId } })
  } else if (componentProps.bundleLink === OrganizationTypes.PROVIDER) {
    router.push({ name: Routes.PROVIDER_PRODUCTS_DETAILS, params: { productId } })
  }
}
</script>

<i18n lang="yaml">
de:
  bundlesOverview:
    toggleBtn:
      tooltips:
        inactive: App-Zugriff ist gesperrt
</i18n>
