<template>
  <v-textarea
    v-model="model"
    :autofocus="autofocus"
    :color="PlattformColors.CHANGE"
    :counter="counter"
    :label="label"
    :required="required"
    variant="outlined"
  />
</template>
<script lang="ts" setup>
import { PlattformColors } from '@/models/enums/ColorSets'

defineProps({
  autofocus: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  counter: { type: String, default: undefined },
  label: { type: String, default: undefined },
  required: { type: Boolean, default: false },
})

const model = defineModel<string>()
</script>
