import { useAlertStore } from '@/store/alerts'
import Keycloak from 'keycloak-js'
import { i18n } from './i18n'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { ResolveTenantResponse } from '@/models/Tenant'

let keycloak: Keycloak
/**
 * initKeycloak
 * @param {TenantResponse} tenantResponse
 */
export async function initKeycloak(tenantResponse: ResolveTenantResponse): Promise<void> {
  let options
  try {
    const response = await fetch(`/auth/realms/${tenantResponse.authRealm}/.well-known/openid-configuration`)
    options = await response.json()
  } catch (e) {
    console.error(e)
  }

  const initOptions = {
    url: `${new URL(options.issuer).origin}/auth`,
    realm: tenantResponse.authRealm,
    clientId: tenantResponse.authClientId,
  }

  keycloak = new Keycloak(initOptions)

  await keycloak.init({ onLoad: 'check-sso', checkLoginIframe: false, pkceMethod: 'S256' })
}

/**
 * loginToKeycloak
 */
export function loginToKeycloak(redirectLink?: string): void {
  keycloak.login({
    redirectUri: redirectLink ? `${window.origin}${redirectLink}` : window.origin,
  })
}

/**
 * logoutFromKeycloak
 */
export function logoutFromKeycloak(): void {
  keycloak.logout({
    redirectUri: window.origin,
  })
}

/**
 * getKeycloakToken
 * @return {string | undefined} token
 */
export function getKeycloakToken(): Keycloak['token'] {
  return keycloak.token
}

/**
 * isLoggedIn
 * @return {boolean} true if logged in
 */
export let isLoggedIn = (): boolean | undefined => {
  return keycloak.authenticated
}

/**
 * changePassword
 */
export function changePassword(): void {
  keycloak.login({ action: 'UPDATE_PASSWORD' })
}

/**
 * updateKeycloakToken
 */
export async function updateKeycloakToken(): Promise<void> {
  try {
    await keycloak.updateToken(0) // update access-Token, when expired
  } catch {
    const alertStore = useAlertStore()
    alertStore.add({
      text: i18n.t('accessTokenError'),
      type: AlertTypes.ERROR,
    })
    window.location.reload()
  }
}

export const mockLoggedInTrue = (): void => {
  isLoggedIn = (): boolean => true
}

export const mockLoggedInFalse = (): void => {
  isLoggedIn = (): boolean => false
}
