<template>
  <ListControls>
    <template #title>
      {{ t('credentials.count', { count: accessSets.length }, accessSets.length) }}
    </template>
  </ListControls>
  <BundleCredentialsElement
    v-for="accessSet in accessSets"
    :key="accessSet.accessDefinition.id"
    :access-set="accessSet"
    :request-enabled="isRequestButtonEnabled"
    @request-credential="(accessDefinition: AccessDefinitionResponse) => emit('requestCredential', accessDefinition)"
    @request-deactivate-credential="(access: AccessResponse) => emit('requestDeactivateCredential', access)"
    @deactivate-requested-credential="(access: AccessResponse) => emit('deactivateRequestedCredential', access)"
    @deactivate-credential="(access: AccessResponse) => emit('deactivateCredential', access)"
    @activate-credential="(access: AccessResponse) => emit('activateCredential', access)"
    @handle-request-credential="(access: AccessResponse) => emit('handleRequestCredential', access)"
  />
</template>
<script lang="ts" setup>
import BundleCredentialsElement from '@/components/bundles/BundleCredentialsElement.vue'
import { AccessResponse, AccessResponseAccessToken, AccessSet } from '@/models/Access'
import { AccessDefinitionResponse } from '@/models/AccessDefinition'
import { AccessTokenAuthenticatorResponse } from '@/models/Authenticator'
import { BundleResponse } from '@/models/Bundle'
import { AccessExchangeTypes } from '@/models/enums/AccessDefinitionTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { computed } from 'vue'
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import ListControls from '../ListControls.vue'
import { useBundleProductsHelper } from '@/composables/useBundleProductsHelper'

const props = defineProps({
  bundle: { type: Object as PropType<BundleResponse>, default: undefined },
  accessDefinitions: { type: Array<AccessDefinitionResponse>, default: undefined },
})

const { isBundleProductActive } = useBundleProductsHelper()

const isRequestButtonEnabled = computed(() => {
  return props.bundle?.contractStatus !== StatusTypes.TERMINATED
})

const emit = defineEmits([
  'requestCredential',
  'deactivateCredential',
  'deactivateRequestedCredential',
  'activateCredential',
  'requestDeactivateCredential',
  'handleRequestCredential',
])

const { t } = useI18n()

const activeBundleProducts = computed(() =>
  props.bundle?.bundleProducts.filter((bundleProduct) => isBundleProductActive(bundleProduct, props.bundle?.contractStatus))
)

const accessSets = computed(() => {
  const result: Array<AccessSet> = []

  props.accessDefinitions?.forEach((accessDefinition) => {
    const accessSet: AccessSet = {
      accessDefinition,
      accessGroups: [],
      products: [],
    }

    activeBundleProducts.value?.forEach((product) => {
      if (product.accessDefinitionId === accessDefinition.id) {
        accessSet.products.push({ ...product, logo: props.bundle?.providerOrganization.logo })
      }
    })

    if (accessDefinition.accessExchangeType === AccessExchangeTypes.GENERATE_TOKEN) {
      const filterBundleAccesses = props.bundle?.accesses.filter((access) => access.accessExchangeType === accessDefinition.accessExchangeType)
      let filteredAccesses = filterBundleAccesses as AccessResponseAccessToken[]

      // cluster accesses by values
      while (filteredAccesses?.length) {
        const groupedAccesses: Array<AccessResponse> = []

        filteredAccesses = filteredAccesses.filter((access) => {
          if (compareAuthenticator(access.authenticator, filteredAccesses[0].authenticator)) {
            groupedAccesses.push(access)
            return false
          } else {
            return true
          }
        })

        accessSet.accessGroups.push(groupedAccesses)
      }
    }

    // only add accessSets with products in them
    if (accessSet.products.length) {
      // sort accesses in access groups
      accessSet.accessGroups.map((accesses) => {
        accesses.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      })

      // sort access groups by first index of sorted accesses
      accessSet.accessGroups.sort((a, b) => new Date(b[0].createdAt).getTime() - new Date(a[0].createdAt).getTime())

      result.push(accessSet)
    }
  })

  return result.filter((set) => set.accessGroups.length || set.products.length)
})

/**
 * compareAuthenticator
 * @param {AccessTokenAuthenticatorResponse} authenticatorA
 * @param {AccessTokenAuthenticatorResponse} authenticatorB
 * @return {boolean}
 */
function compareAuthenticator(authenticatorA: AccessTokenAuthenticatorResponse, authenticatorB: AccessTokenAuthenticatorResponse): boolean {
  return (
    authenticatorA.hashAlgorithm === authenticatorB.hashAlgorithm &&
    authenticatorA.maxTokenLength === authenticatorB.maxTokenLength &&
    authenticatorA.validityPeriod === authenticatorB.validityPeriod
  )
}
</script>

<i18n lang="yaml">
de:
  credentials:
    count: '{count} genutzte Authentifizierungsmethode | {count} genutzte Authentifizierungsmethoden'
</i18n>
