<template>
  <v-container
    v-if="alerts.length"
    class="alert-wrapper position-fixed ml-n4 py-0"
  >
    <v-alert
      v-for="alert in alerts"
      :key="alert.id"
      :type="alert.type"
      :border="'start'"
      closable
      class="mx-n4 mb-4"
      @input="alertStore.del(alert.id)"
    >
      {{ alert.text }}
    </v-alert>
  </v-container>
</template>

<script lang="ts" setup>
import { useAlertStore } from '@/store/alerts'
import { storeToRefs } from 'pinia'

const alertStore = useAlertStore()

const { alerts } = storeToRefs(alertStore)
</script>
<style lang="scss" scoped>
.alert-wrapper {
  z-index: 1;
}
</style>
