import { defineStore } from 'pinia'
import useAxios from '@/plugins/axios'
import { v4 as uuidv4 } from 'uuid'

import {
  FileExchangeFolderResponse,
  FileExchangeFolderResponseList,
  Folder,
  ProductDocumentationFolderResponse,
  ProductDocumentationFolderResponseList,
} from '@/models/Folder'
import { FileExchangeFileResponse, FileExchangeFileResponseList, ProductDocumentationFileResponse, ProductDocumentationFileResponseList } from '@/models/File'
import { OrganizationPublicResponse } from '@/models/Organization'
import { ProductResponse } from '@/models/Product'
import { FilesAndFolderNodeTypes } from '@/models/enums/FilesAndFolderTypes'
import { StoreName } from '@/models/enums/StoreName'
import { useFileHelper } from '@/composables/useFileHelper'

interface FilesStateProperties {
  filesAndFolders: Folder[]
  newGhostFolder: boolean
}

export const useFilesStore = defineStore(StoreName.FILES, {
  state: (): FilesStateProperties => ({
    filesAndFolders: [],
    newGhostFolder: false,
  }),
  actions: {
    async GET(organizationId: OrganizationPublicResponse['id']) {
      try {
        const {
          data,
        }: {
          data: FileExchangeFolderResponseList
        } = await useAxios.get(`/api/organizations/${organizationId}/file-exchange`)
        this.filesAndFolders = data.folders
      } catch {
        return Promise.reject()
      }
    },
    async GET_PRODUCT_DOCUMENTATION_STRUCTURE(productId: ProductResponse['id']) {
      try {
        const {
          data,
        }: {
          data: ProductDocumentationFolderResponseList
        } = await useAxios.get(`/api/products/${productId}/documentations`)
        this.filesAndFolders = data.folders
      } catch {
        return Promise.reject()
      }
    },
    async CREATE_FOLDER(name: FileExchangeFolderResponse['name'], parentFolder: Folder) {
      try {
        const {
          data,
        }: {
          data: FileExchangeFolderResponse
        } = await useAxios.post(`/api/organizations/file-exchange/folders`, {
          name,
          parentFolderId: parentFolder.id,
        })
        parentFolder.ghostFolder = undefined
        parentFolder.subfolders.push(data) // replace ghost-folder with actual folder
      } catch {
        parentFolder.ghostFolder = undefined
        return Promise.reject()
      } finally {
        this.newGhostFolder = false
      }
    },
    async CREATE_PRODUCT_DOCUMENTATION_FOLDER(name: ProductDocumentationFolderResponse['name'], parentFolder: Folder) {
      try {
        const {
          data,
        }: {
          data: ProductDocumentationFolderResponse
        } = await useAxios.post(`/api/products/documentations/folders`, {
          name,
          parentFolderId: parentFolder.id,
        })
        parentFolder.ghostFolder = undefined
        parentFolder.subfolders.push(data) // replace ghost-folder with actual folder
      } catch {
        parentFolder.ghostFolder = undefined
        return Promise.reject()
      } finally {
        this.newGhostFolder = false
      }
    },
    async DELETE_FOLDER(folderId: FileExchangeFolderResponse['id'], parentFolder: Folder) {
      try {
        await useAxios.delete(`/api/organizations/file-exchange/folders/${folderId}`)
        parentFolder.subfolders = parentFolder.subfolders?.filter((folder: Folder) => folder.id !== folderId)
      } catch {
        return Promise.reject()
      }
    },
    async DELETE_PRODUCT_DOCUMENTATION_FOLDER(folderId: ProductDocumentationFolderResponse['id'], parentFolder: Folder) {
      try {
        await useAxios.delete(`/api/products/documentations/folders/${folderId}`)
        parentFolder.subfolders = parentFolder.subfolders?.filter((folder) => folder.id !== folderId)
      } catch {
        return Promise.reject()
      }
    },
    async GET_FILE_BY_ID(fileId: FileExchangeFileResponse['id']) {
      try {
        const { data } = await useAxios.get(`/api/organizations/file-exchange/files/${fileId}/content`, { responseType: 'blob' })
        return data
      } catch {
        return Promise.reject()
      }
    },
    async GET_PRODUCT_DOCUMENTATION_FILE_BY_ID(fileId: ProductDocumentationFileResponse['id']) {
      try {
        const { data } = await useAxios.get(`/api/products/documentations/files/${fileId}/content`, { responseType: 'blob' })
        return data
      } catch {
        return Promise.reject()
      }
    },
    async UPLOAD_FILE(parentFolder: Folder, file: File) {
      const { validateFile } = useFileHelper()

      if (!validateFile(file, parentFolder)) {
        return Promise.reject()
      }
      const tempId = uuidv4()
      const newFile = {
        id: tempId,
        name: file.name,
        upload: true,
        createdAt: new Date().toISOString(),
      }
      parentFolder.uploadFiles = parentFolder.uploadFiles ? [...parentFolder.uploadFiles, newFile] : [newFile]
      try {
        const formData = new FormData()
        formData.append('file', file)
        const { data }: { data: FileExchangeFileResponseList } = await useAxios.post(
          `/api/organizations/file-exchange/folders/${parentFolder.id}/files`,
          formData
        )

        // replace ghost file with actual file
        parentFolder.uploadFiles = parentFolder.uploadFiles.filter((file) => file.id !== tempId)
        ;(parentFolder.files as FileExchangeFileResponse[]).push(...data.files)
      } catch {
        parentFolder.uploadFiles = parentFolder.uploadFiles.filter((file) => file.id !== tempId) // remove ghost file when error occurs
        return Promise.reject()
      }
    },
    async UPLOAD_PRODUCT_DOCUMENTATION_FILE(parentFolder: Folder, file: File) {
      const { validateProductFile } = useFileHelper()

      if (!validateProductFile(file, parentFolder)) {
        return Promise.reject()
      }
      const tempId = uuidv4()
      const newFile = {
        id: tempId,
        name: file.name,
        upload: true,
        createdAt: new Date().toISOString(),
      }
      parentFolder.uploadFiles = parentFolder.uploadFiles ? [...parentFolder.uploadFiles, newFile] : [newFile]
      try {
        const formData = new FormData()
        formData.append('file', file)
        const { data }: { data: ProductDocumentationFileResponseList } = await useAxios.post(
          `/api/products/documentations/folders/${parentFolder.id}/files`,
          formData
        )

        // replace ghost file with actual file
        parentFolder.uploadFiles = parentFolder.uploadFiles.filter((file) => file.id !== tempId)
        parentFolder.files.push(...data.files)
      } catch {
        parentFolder.uploadFiles = parentFolder.uploadFiles.filter((file) => file.id !== tempId) // remove ghost file when error occurs
        return Promise.reject()
      }
    },
    async DELETE_FILE(fileId: FileExchangeFileResponse['id'], parentFolder: Folder) {
      try {
        await useAxios.delete(`/api/organizations/file-exchange/files/${fileId}`)
        parentFolder.files = parentFolder.files?.filter((file) => file.id !== fileId)
      } catch {
        return Promise.reject()
      }
    },
    async DELETE_PRODUCT_DOCUMENTATION_FILE(fileId: ProductDocumentationFileResponse['id'], parentFolder: Folder) {
      try {
        await useAxios.delete(`/api/products/documentations/files/${fileId}`)
        parentFolder.files = parentFolder.files?.filter((file) => file.id !== fileId)
      } catch {
        return Promise.reject()
      }
    },
    async TOGGLE_PRODUCT_DOCUMENTATION_FILE_ACTIVE(active: boolean, fileId: ProductDocumentationFileResponse['id']) {
      try {
        await useAxios.patch(`/api/products/documentations/files/${fileId}`, { active })
      } catch {
        return Promise.reject()
      }
    },
    createNewFolder(parentFolder: Folder) {
      parentFolder.ghostFolder = {
        id: uuidv4(),
        nodeType: FilesAndFolderNodeTypes.NEW_FOLDER,
      }
      this.newGhostFolder = true
    },
    removeLastFolder(parentFolder: Folder) {
      parentFolder.ghostFolder = undefined
      this.newGhostFolder = false
    },
  },
})
