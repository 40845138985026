<template>
  <div class="d-flex justify-space-between">
    <p class="d-flex align-self-center highlight mb-0">{{}}</p>
  </div>
  <ListControls>
    <template #title>{{ t('providerProductPlans.plans', { count: plans.length || 0 }) }}</template>
    <template #append>
      <DefaultButton
        :color="PlattformColors.LIGHT"
        :prepend-icon="Icons.CIRCLE_ADD"
        @click="createPlanDialogRef?.open()"
      >
        {{ t('providerProductPlans.addPlan') }}
      </DefaultButton>

      <IconButton
        :icon="Icons.SETTINGS"
        @click="openPlanSettingsDialogRef?.open()"
      />
    </template>
  </ListControls>
  <ProductPlans
    v-if="plans.length"
    ref="productPlansRef"
    :available-product-plans="plans"
    @update="(plan: PlanResponse) => openUpdateDialog(plan)"
    @delete="(plan: PlanResponse) => openDeleteDialog(plan)"
  />
  <Dialog
    ref="createPlanDialogRef"
    @on-close="createPlanDialogRef?.close()"
  >
    <DialogProductPlanForm
      @submit="(newPlan: PlanRequest) => createPlan(newPlan)"
      @cancel="createPlanDialogRef?.close()"
    />
  </Dialog>

  <Dialog
    ref="openPlanSettingsDialogRef"
    @on-close="openPlanSettingsDialogRef?.close()"
  >
    <DialogProductPlanSettings
      :product-object="product"
      @submit="(product: ProductRequest) => updateProduct(product)"
      @cancel="openPlanSettingsDialogRef?.close()"
    />
  </Dialog>

  <Dialog
    ref="updatePlanDialogRef"
    @on-close="updatePlanDialogRef?.close()"
  >
    <DialogProductPlanForm
      :plan-to-update="planToUpdate"
      @submit="(updatedPlan: PlanRequest) => updatePlan(updatedPlan)"
      @cancel="updatePlanDialogRef?.close()"
    />
  </Dialog>
  <Dialog
    ref="deletePlanDialogRef"
    @on-close="deletePlanDialogRef?.close()"
  >
    <DialogProductPlanDelete
      :plan-to-delete="planToDelete"
      @submit="(deletedPlan: PlanResponse) => deletePlan(deletedPlan)"
      @cancel="deletePlanDialogRef?.close()"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import ListControls from '@/components/ListControls.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DialogProductPlanDelete from '@/components/dialogs/DialogProductPlanDelete.vue'
import DialogProductPlanForm from '@/components/dialogs/DialogProductPlanForm.vue'
import DialogProductPlanSettings from '@/components/dialogs/DialogProductPlanSettings.vue'
import Dialog from '@/components/layout/Dialog.vue'
import ProductPlans from '@/components/products/ProductPlans.vue'
import { PlanRequest, PlanResponse } from '@/models/Plan'
import { ProductRequest } from '@/models/Product'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import { useAlertStore } from '@/store/alerts'
import { usePlanStore } from '@/store/plans'
import { useProductStore } from '@/store/products'
import { storeToRefs } from 'pinia'
import { nextTick } from 'vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const route = useRoute()
const productStore = useProductStore()
const planStore = usePlanStore()
const alertStore = useAlertStore()

const { t } = useI18n()
const { product } = storeToRefs(productStore)
const { plans } = storeToRefs(planStore)

const createPlanDialogRef = ref<InstanceType<typeof Dialog>>()
const openPlanSettingsDialogRef = ref<InstanceType<typeof Dialog>>()
const updatePlanDialogRef = ref<InstanceType<typeof Dialog>>()
const deletePlanDialogRef = ref<InstanceType<typeof Dialog>>()
const productPlansRef = ref<InstanceType<typeof ProductPlans>>()
const planToUpdate = ref<PlanResponse>()
const planToDelete = ref<PlanResponse>()
/**
 * openUpdateDialog
 * @param {plan} plan
 */
function openUpdateDialog(plan: PlanResponse): void {
  planToUpdate.value = plan
  updatePlanDialogRef.value?.open()
}

/**
 * openDeleteDialog
 * @param {plan} plan
 */
function openDeleteDialog(plan: PlanResponse): void {
  planToDelete.value = plan
  deletePlanDialogRef.value?.open()
}

/**
 * createPlan
 * @param {PlanRequest} newPlan
 */
async function createPlan(newPlan: PlanRequest): Promise<void> {
  if (product?.value) {
    try {
      await planStore.CREATE(product.value.id, newPlan)
      alertStore.add({
        text: t('providerProductPlans.alerts.created'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      Promise.resolve()
    } finally {
      createPlanDialogRef.value?.close()
    }
    await nextTick()
    productPlansRef.value?.updateSwiper()
  }
}

/**
 * updateProduct
 * @param {ProductRequest} updatedProduct
 */
async function updateProduct(updatedProduct: ProductRequest): Promise<void> {
  if (product?.value) {
    try {
      await productStore.UPDATE(updatedProduct, product?.value?.id)

      alertStore.add({
        text: t('providerProductPlans.alerts.productUpdated'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      Promise.resolve()
    } finally {
      openPlanSettingsDialogRef.value?.close()
    }
  }
}

/**
 * deletePlan
 * @param {deletedPlan} deletedPlan
 */
async function deletePlan(deletedPlan: PlanResponse): Promise<void> {
  try {
    await planStore.DELETE(deletedPlan)

    alertStore.add({
      text: t('providerProductPlans.alerts.deleted'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    deletePlanDialogRef.value?.close()
  }
  await nextTick()
  productPlansRef.value?.updateSwiper()
}

/**
 * updatePlan
 * @param {PlanRequest} updatedPlan
 */
async function updatePlan(updatedPlan: PlanRequest): Promise<void> {
  if (planToUpdate.value) {
    try {
      await planStore.UPDATE(updatedPlan, planToUpdate.value.id)

      alertStore.add({
        text: t('providerProductPlans.alerts.updated'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      Promise.resolve()
    } finally {
      updatePlanDialogRef.value?.close()
    }
  }
}

await Promise.allSettled([planStore.GET_PRODUCT_PLANS(route.params.productId as string)])
</script>

<i18n lang="yaml">
de:
  providerProductPlans:
    addPlan: Plan hinzufügen
    plans: '{count} Plan | {count} Pläne'
    alerts:
      created: Der Plan wurde erfolgreich erstellt!
      updated: Der Plan wurde erfolgreich bearbeitet!
      productUpdated: Die API wurde erfolgreich bearbeitet!
      deleted: Der Plan wurde erfolgreich gelöscht!
</i18n>
