<template>
  <v-img
    :width="width"
    :src="StatusIconSets.find((statusIconSet) => statusIconSet.key === statusType)?.img"
  />
</template>
<script lang="ts" setup>
import { StatusTypes } from '@/models/enums/StatusTypes'
import active from '@/assets/statusIcons/active.svg'
import inactive from '@/assets/statusIcons/inactive.svg'
import terminated from '@/assets/statusIcons/terminated.svg'
import pending from '@/assets/statusIcons/pending.svg'
import inProgress from '@/assets/statusIcons/inProgress.svg'
import created from '@/assets/statusIcons/created.svg'

interface StatusIconSet {
  key: string
  img: string
}

const StatusIconSets: StatusIconSet[] = [
  {
    key: StatusTypes.ACTIVATION_REQUESTED,
    img: inProgress,
  },
  {
    key: StatusTypes.ACTIVE,
    img: active,
  },
  {
    key: StatusTypes.APPROVED,
    img: active,
  },
  {
    key: StatusTypes.INITIAL,
    img: inactive,
  },
  {
    key: StatusTypes.INACTIVE,
    img: inactive,
  },
  {
    key: StatusTypes.IN_TERMINATION,
    img: active,
  },
  {
    key: StatusTypes.TERMINATED,
    img: terminated,
  },
  {
    key: StatusTypes.IN_PROGRESS,
    img: inProgress,
  },
  {
    key: StatusTypes.REQUESTED,
    img: pending,
  },
  {
    key: StatusTypes.PENDING,
    img: pending,
  },
  {
    key: StatusTypes.DRAFT,
    img: created,
  },
  {
    key: StatusTypes.REJECTED,
    img: inactive,
  },
  {
    key: StatusTypes.DEACTIVATION_REQUESTED,
    img: inProgress,
  },
]

defineProps({
  width: { type: Number, default: 24 },
  statusType: { type: String, default: StatusTypes.ACTIVE },
})
</script>
