<template>
  <div class="organization-settings d-flex flex-column">
    <GeneralPageTitle>
      <template #title>
        {{ t('organizationOverview.headline') }}
      </template>
    </GeneralPageTitle>
    <div class="flex-grow-1">
      <v-row>
        <v-col>
          <NavigationTeaser :to="{ name: Routes.DETAILS }">
            <template #prepend>
              <DefaultAvatar
                size="48"
                color="grey-lighten-4"
              >
                <v-icon size="24">{{ Icons.ORGANIZATIONS }}</v-icon>
              </DefaultAvatar>
            </template>
            <template #title>
              {{ t('organizationOverview.organizationInfoTeaser.title') }}
            </template>
            <template #subtitle>
              {{ t('organizationOverview.organizationInfoTeaser.subtitle') }}
            </template>
          </NavigationTeaser>
        </v-col>
        <v-col>
          <NavigationTeaser
            v-if="activeOrganization?.provider"
            :to="{ name: Routes.ACCESS_DEFINITIONS }"
          >
            <template #prepend>
              <DefaultAvatar
                size="48"
                color="grey-lighten-4"
              >
                <v-icon size="24">{{ Icons.AUTHENTICATION }}</v-icon>
              </DefaultAvatar>
            </template>
            <template #title>
              {{ t('organizationOverview.accessTeaser.title') }}
            </template>
            <template #subtitle>
              {{ t('organizationOverview.accessTeaser.subtitle') }}
            </template>
          </NavigationTeaser>

          <NavigationTeaser
            v-else
            :to="{ name: Routes.CONTACT_PERSONS }"
          >
            <template #prepend>
              <DefaultAvatar
                size="48"
                color="grey-lighten-4"
              >
                <v-icon size="24">{{ Icons.USER }}</v-icon>
              </DefaultAvatar>
            </template>
            <template #title>
              {{ t('organizationOverview.contactPersonTeaser.title') }}
            </template>
            <template #subtitle>
              {{ t('organizationOverview.contactPersonTeaser.subtitle') }}
            </template>
          </NavigationTeaser>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <NavigationTeaser :to="{ name: Routes.USERS }">
            <template #prepend>
              <DefaultAvatar
                size="48"
                color="grey-lighten-4"
              >
                <v-icon size="24">{{ Icons.USERS }}</v-icon>
              </DefaultAvatar>
            </template>
            <template #title>
              {{ t('organizationOverview.memberTeaser.title') }}
            </template>
            <template #subtitle>
              {{ t('organizationOverview.memberTeaser.subtitle') }}
            </template>
          </NavigationTeaser>
        </v-col>
        <v-col>
          <NavigationTeaser :to="{ name: Routes.TECHNICAL_USERS }">
            <template #prepend>
              <DefaultAvatar
                size="48"
                color="grey-lighten-4"
              >
                <v-icon size="24">{{ Icons.USER_SETTINGS }}</v-icon>
              </DefaultAvatar>
            </template>
            <template #title>
              {{ t('organizationOverview.technicalUserTeaser.title') }}
            </template>
            <template #subtitle>
              {{ t('organizationOverview.technicalUserTeaser.subtitle') }}
            </template>
          </NavigationTeaser>
        </v-col>
      </v-row>
    </div>
    <div class="mt-auto pb-4">
      <v-row>
        <v-col cols="9">
          <h3>{{ t('organizationOverview.delete.headline') }}</h3>
          <p>{{ t('organizationOverview.delete.copy') }}</p>
        </v-col>
        <v-col cols="3 align-self-center">
          <DefaultButton
            v-if="activeOrganization"
            :color="PlattformColors.LIGHT"
            class="text-error"
            @click="showDeletionOverlay(activeOrganization)"
          >
            {{ t('organizationOverview.delete.button') }}
          </DefaultButton>
        </v-col>
      </v-row>
      <Dialog
        ref="deleteOrganizationDialogRef"
        @on-close="deleteOrganizationDialogRef?.close()"
      >
        <DialogOrganizationDelete
          :organization="activeOrganization"
          :loading="deleteOrganizationLoading"
          @submit="deleteOrganization()"
          @cancel="deleteOrganizationDialogRef?.close()"
        />
      </Dialog>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useMyOrganizationStore } from '@/store/myOrganizations'

import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import NavigationTeaser from '@/components/baseComponents/teasers/NavigationTeaser.vue'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DialogOrganizationDelete from '@/components/dialogs/DialogOrganizationDelete.vue'
import Dialog from '@/components/layout/Dialog.vue'
import { ref } from 'vue'
import { OrganizationFullResponse } from '@/models/Organization'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()
const alertStore = useAlertStore()
const myOrganizationStore = useMyOrganizationStore()
const { activeOrganization } = storeToRefs(myOrganizationStore)

const selectedOrganizationToDelete = ref<OrganizationFullResponse>()
const deleteOrganizationLoading = ref(false)
const deleteOrganizationDialogRef = ref<InstanceType<typeof Dialog>>()

/**
 * showDeletionOverlay
 * @param {OrganizationExtendedResponse} organization
 */
function showDeletionOverlay(organization: OrganizationFullResponse): void {
  selectedOrganizationToDelete.value = organization
  deleteOrganizationDialogRef.value?.open()
}

/**
 * deleteOrganization
 */
async function deleteOrganization(): Promise<void> {
  if (selectedOrganizationToDelete.value) {
    try {
      deleteOrganizationLoading.value = true
      await myOrganizationStore.DELETE(selectedOrganizationToDelete.value.id)
      alertStore.add({
        text: t('organizationOverview.delete.success.deleted'),
        type: AlertTypes.SUCCESS,
      })
      await router.push({ name: Routes.HOME })
    } catch {
      await Promise.resolve()
    } finally {
      deleteOrganizationLoading.value = false
      deleteOrganizationDialogRef.value?.close()
    }
  }
}
</script>
<style lang="scss" scoped>
.organization-settings {
  min-height: calc(100vh - 8rem);
}
</style>
<i18n lang="yaml">
de:
  organizationOverview:
    headline: Meine Organisation
    organizationInfoTeaser:
      title: Organisationsdaten
      subtitle: Verwaltung von Organisationsnname, Anschrift, etc.
    memberTeaser:
      title: Nutzerverwaltung
      subtitle: Verwaltung von Nutzern, Teams und Berechtigungen
    technicalUserTeaser:
      title: Technische Nutzer
      subtitle: Access Token für die Nutzung der Developer-Portal-API
    accessTeaser:
      title: Authentifizierungsmethoden
      subtitle: Authentifizierungsmethoden konfigurieren
    contactPersonTeaser:
      title: Kontaktpersonen
      subtitle: Anlegen und verwalten von Kontaktpersonen
    delete:
      headline: Organisation löschen
      copy: Du kannst Deine Organisation löschen, wenn alle Aktivierungsanfragen abgelehnt und Abonnements beendet sind.
        Mehr Informationen findest du in unserer Dokumentation.
      button: Organisation löschen
      success:
        deleted: Deine Organisation wurde gelöscht!
</i18n>
