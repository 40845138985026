<template>
  <div
    class="product-plan-item"
    :data-test="ProductPlanItemTest.ITEM"
    :class="{ 'bg-white': !disabled, 'product-plan-item--selectable': selectable, 'product-plan-item--disabled bg-grey': disabled }"
  >
    <div class="product-plan-item__name">
      <div class="d-flex align-start justify-space-between">
        <h3
          class="item_name"
          :data-test="ProductPlanItemTest.NAME"
        >
          {{ plan.name }}
        </h3>

        <v-icon
          v-if="selectable"
          :data-test="ProductPlanItemTest.CHECKBOX_ICON"
          :color="PlattformColors.CHANGE"
          :icon="isSelected ? Icons.CHECKBOX_ON : Icons.CHECKBOX_OFF"
        />
        <v-menu
          v-if="activeOrganization?.provider"
          location="bottom"
          transition="slide-y-transition"
        >
          <template #activator="{ props }">
            <IconButton
              v-bind="props"
              :icon="Icons.OPTIONS_VERTICAL"
              class="menu-button"
              :data-test="ProductPlanItemTest.MENU_BUTTON"
            />
          </template>
          <v-list density="compact">
            <v-list-item
              flat
              :data-test="ProductPlanItemTest.MENU_UPDATE"
              @click="emit('update', plan)"
            >
              <template #prepend>
                <v-icon>{{ Icons.EDIT }}</v-icon>
              </template>
              <v-list-item-title>{{ t('productPlanItem.menu.edit') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              flat
              :data-test="ProductPlanItemTest.MENU_DELETE"
              @click="emit('delete', plan)"
            >
              <template #prepend>
                <v-icon>{{ Icons.DELETE }}</v-icon>
              </template>
              <v-list-item-title>{{ t('productPlanItem.menu.delete') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-divider />
    </div>

    <div
      class="product-plan-item__description"
      :data-test="ProductPlanItemTest.DESCRIPTION"
    >
      {{ plan.description }}
    </div>

    <div class="product-plan-item__info">
      <v-divider />
      <template v-if="activeOrganization?.provider">
        <p class="product-plan-item__duration">{{ t('productPlanItem.visibility') }}</p>
        <p
          class="product-plan-item__duration-time mb-4"
          :data-test="ProductPlanItemTest.VALIDITY"
        >
          <span
            v-if="plan.validity?.validFrom"
            :data-test="ProductPlanItemTest.VALID_FROM"
          >
            {{ format(new Date(plan.validity.validFrom), 'dd.MM.yyyy') }}
          </span>
          <span v-else>{{ t('productPlanItem.durationUnlimited') }}</span>
          <span> – </span>
          <span
            v-if="plan.validity?.validUntil"
            :data-test="ProductPlanItemTest.VALID_UNTIL"
          >
            {{ format(new Date(plan.validity.validUntil), 'dd.MM.yyyy') }}
          </span>
          <span v-else>{{ t('productPlanItem.durationUnlimited') }}</span>
        </p>
      </template>
      <p class="product-plan-item__duration highlight">{{ t('productPlanItem.duration') }}</p>
      <p class="product-plan-item__duration-time">
        {{ t('productPlanItem.durationUnlimited') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { PlanResponse } from '@/models/Plan'
import { Icons } from '@/models/enums/IconTypes'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { format } from 'date-fns'
import { PropType } from 'vue'
import { ProductPlanItemTest } from '@/models/enums/test/ProductPlanItemTest'
import IconButton from '../baseComponents/buttons/IconButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

const emit = defineEmits(['update', 'delete'])

defineProps({
  plan: {
    type: Object as PropType<PlanResponse>,
    required: true,
  },
  selectable: {
    type: Boolean,
    default: false,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const myOrganizationStore = useMyOrganizationStore()

const { activeOrganization } = myOrganizationStore
</script>
<style lang="scss" scoped>
.product-plan-item {
  height: 100%;
  display: flex;
  flex-direction: column;

  &--selectable {
    cursor: pointer;
  }

  &--disabled {
    cursor: default;
  }

  &__name {
    padding: 1rem 1rem 0;
    display: flex;
    flex-direction: column;
  }

  &__description {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin-bottom: auto;
    font-size: $font-size-s;
    line-height: 1.55;
    font-weight: 400;
    min-height: 12.5rem;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 0 1rem 1rem;

    p {
      line-height: 1;
      margin-bottom: 0.25rem;
    }
  }

  &__duration {
    font-size: $font-size-xs;
  }

  &__duration-time {
    font-size: $font-size-s;
  }

  hr {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
  }

  .item_name {
    overflow: hidden;
  }

  .item_select {
    position: relative;
    top: -0.5rem;
    right: -0.5rem;
  }

  .menu-button {
    margin: -0.75rem -0.75rem 0 0;
  }
}
</style>

<i18n lang="yaml">
de:
  productPlanItem:
    duration: Laufzeit
    durationItem: '{duration} Monat | {duration} Monate'
    durationUnlimited: Unbegrenzt
    visibility: Sichtbarkeit auf dem Marktplatz
    menu:
      edit: Bearbeiten
      delete: Löschen
</i18n>
