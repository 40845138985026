<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('contactPersons.headline') }}
    </template>
  </GeneralPageTitle>
  <template v-if="contacts.length">
    <ListControls>
      <template #title>
        {{ t('contactPersons.count', { count: contacts.length }) }}
      </template>
      <template #append>
        <DefaultButton
          :prepend-icon="Icons.CIRCLE_ADD"
          @click="contactPersonCreateDialogRef?.open()"
        >
          {{ t('contactPersons.add') }}
        </DefaultButton>
      </template>
    </ListControls>
    <ContactList
      :contacts="contacts"
      @update="(contact: ContactResponse) => openUpdateDialog(contact)"
      @set-default="(contact: ContactResponse) => setDefault(contact)"
      @delete="(contact: ContactResponse) => openDeleteDialog(contact)"
    />
  </template>
  <CreateTeaser
    v-else
    :icon="Icons.USER_ADD"
    :headline="t('contactPersons.createContent.headline')"
    :copy="t('contactPersons.createContent.copy')"
    :btn-text="t('buttons.add')"
    @submit="contactPersonCreateDialogRef?.open()"
  />
  <Dialog
    ref="contactPersonCreateDialogRef"
    @on-close="contactPersonCreateDialogRef?.close()"
  >
    <DialogContactForm
      @submit="(newContact: ContactRequest) => createContact(newContact)"
      @cancel="contactPersonCreateDialogRef?.close()"
    />
  </Dialog>
  <Dialog
    ref="contactPersonUpdateDialogRef"
    @on-close="contactPersonUpdateDialogRef?.close()"
  >
    <DialogContactForm
      :contact-to-update="contactToUpdate"
      @submit="(updatedContact: ContactResponse) => updateContact(updatedContact)"
      @cancel="contactPersonUpdateDialogRef?.close()"
    />
  </Dialog>
  <Dialog
    ref="contactPersonDeleteDialogRef"
    @on-close="contactPersonDeleteDialogRef?.close()"
  >
    <DialogContactDelete
      :contact="contactToDelete"
      @submit="(contact: ContactResponse) => deleteContact(contact)"
      @cancel="contactPersonDeleteDialogRef?.close()"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import ListControls from '@/components/ListControls.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import DialogContactDelete from '@/components/dialogs/DialogContactDelete.vue'
import DialogContactForm from '@/components/dialogs/DialogContactForm.vue'
import CreateTeaser from '@/components/layout/CreateTeaser.vue'
import Dialog from '@/components/layout/Dialog.vue'
import ContactList from '@/components/organizations/contacts/ContactList.vue'
import { ContactResponse, ContactRequest } from '@/models/Contact'
import { Icons } from '@/models/enums/IconTypes'
import { useContactsStore } from '@/store/contacts'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const contactStore = useContactsStore()
const myOrganizationStrore = useMyOrganizationStore()

const { contacts } = storeToRefs(contactStore)
const { activeOrganization } = storeToRefs(myOrganizationStrore)

const contactPersonCreateDialogRef = ref<InstanceType<typeof Dialog>>()
const contactPersonUpdateDialogRef = ref<InstanceType<typeof Dialog>>()
const contactPersonDeleteDialogRef = ref<InstanceType<typeof Dialog>>()
const contactToUpdate = ref<ContactResponse>()
const contactToDelete = ref<ContactResponse>()

/**
 * openUpdateDialog
 * @param {contact} contact
 */
function openUpdateDialog(contact: ContactResponse): void {
  contactToUpdate.value = contact
  contactPersonUpdateDialogRef.value?.open()
}

/**
 * openDeleteDialog
 * @param {contact} contact
 */
function openDeleteDialog(contact: ContactResponse): void {
  contactToDelete.value = contact
  contactPersonDeleteDialogRef.value?.open()
}

/**
 * createContact
 * @param {ContactRequest} newContact
 */
async function createContact(newContact: ContactRequest): Promise<void> {
  if (activeOrganization?.value) {
    const lastDefaultContact = contacts.value.find((contact) => contact.defaultContact)
    try {
      if (lastDefaultContact && newContact.defaultContact) lastDefaultContact.defaultContact = false
      await contactStore.CREATE(activeOrganization.value.id, newContact)
    } catch {
      if (lastDefaultContact && newContact.defaultContact) lastDefaultContact.defaultContact = true
      Promise.resolve()
    } finally {
      contactPersonCreateDialogRef.value?.close()
    }
  }
}

/**
 * updateContact
 * @param {updatedContact} updatedContact
 */
async function updateContact(updatedContact: ContactResponse): Promise<void> {
  try {
    await contactStore.UPDATE(updatedContact)
  } catch {
    Promise.resolve()
  } finally {
    contactPersonUpdateDialogRef.value?.close()
  }
}

/**
 * setDefault
 * @param {updatedContact} updatedContact
 */
async function setDefault(updatedContact: ContactResponse): Promise<void> {
  const lastDefaultContact = contacts.value.find((contact) => contact.defaultContact)
  try {
    if (lastDefaultContact) lastDefaultContact.defaultContact = false
    updatedContact.defaultContact = true
    await contactStore.UPDATE(updatedContact)
  } catch {
    updatedContact.defaultContact = false
    if (lastDefaultContact) lastDefaultContact.defaultContact = true
    Promise.resolve()
  }
}

/**
 * deleteContact
 * @param {contact} contact
 */
async function deleteContact(contact: ContactResponse): Promise<void> {
  try {
    await contactStore.DELETE(contact)
  } catch {
    Promise.resolve()
  } finally {
    contactPersonDeleteDialogRef.value?.close()
  }
}

if (activeOrganization?.value) await contactStore.GET_BY_ORGANIZATION_ID(activeOrganization.value.id)
</script>

<i18n lang="yaml">
de:
  contactPersons:
    headline: Kontaktpersonen
    count: '{count} Kontaktperson | {count} Kontaktpersonen'
    add: Kontaktperson hinzufügen
    createContent:
      headline: Füge deine erste Kontaktperson hinzu!
      copy: Kontaktpersonen werden deinen Apps zugeordnet und dienen Anbietern als Ansprechperson.
</i18n>
