<template>
  <v-item-group
    v-model="toggleBtn"
    class="toggle-btn bg-white"
    mandatory
    @update:model-value="handleToggleBtnChange(toggleBtn)"
  >
    <v-item
      v-slot="{ isSelected, toggle }"
      :value="false"
    >
      <PlainTooltip>
        <template #activator="{ props }">
          <div v-bind="props">
            <IconButton
              :active="isSelected"
              :class="[{ 'text-grey': !isSelected }, { 'bg-red': isSelected }]"
              :size="ButtonSizes.SMALL"
              :icon="Icons.LOCK"
              @click="toggle"
            />
          </div>
        </template>
        <template #default>
          {{ tooltips.inactive }}
        </template>
      </PlainTooltip>
    </v-item>
    <v-item
      v-slot="{ isSelected, toggle }"
      :value="true"
    >
      <PlainTooltip>
        <template #activator="{ props }">
          <div v-bind="props">
            <IconButton
              :active="isSelected"
              :class="[{ 'text-grey': !isSelected }, { 'bg-green': isSelected }]"
              :size="ButtonSizes.SMALL"
              :icon="Icons.UNLOCK"
              @click="toggle"
            />
          </div>
        </template>
        <template #default>
          {{ tooltips.active }}
        </template>
      </PlainTooltip>
    </v-item>
  </v-item-group>
  <Dialog
    ref="deactivateBundleDialogRef"
    @on-close="cancelDeactivateBundleDialog()"
  >
    <DialogBundleDeactivateNote
      @cancel="cancelDeactivateBundleDialog()"
      @submit="(accessNote: BundleAccessStatusResponse['accessNote']) => handleDialogSubmit(accessNote)"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { ref } from 'vue'
import Dialog from '@/components/layout/Dialog.vue'
import DialogBundleDeactivateNote from '@/components/dialogs/DialogBundleDeactivateNote.vue'
import { BundleAccessStatusResponse } from '@/models/Bundle'
import { Icons } from '@/models/enums/IconTypes'
import IconButton from '../baseComponents/buttons/IconButton.vue'
import { ButtonSizes } from '@/models/enums/components/Buttons'
import PlainTooltip from '../baseComponents/tooltips/PlainTooltip.vue'

interface Tooltips {
  active: string
  inactive: string
}

const deactivateBundleDialogRef = ref<InstanceType<typeof Dialog>>()

const componentProps = defineProps({
  toggleBtn: {
    type: Boolean,
    default: false,
  },
  tooltips: {
    type: Object as PropType<Tooltips>,
    default: () => {},
  },
})

const toggleBtn = ref(componentProps.toggleBtn)

const emit = defineEmits(['change'])

/**
 * handleToggleBtnChange
 * @param {toggleBtn} toggleBtn
 */
function handleToggleBtnChange(toggleBtn: boolean): void {
  if (toggleBtn) {
    const accessStatus = {
      access: toggleBtn,
      accessNote: '',
    }
    emit('change', accessStatus)
  } else {
    openDeactivateBundleDialog()
  }
}

/**
 * handleDialogSubmit
 * @param {accessNote} accessNote
 */
function handleDialogSubmit(accessNote: BundleAccessStatusResponse['accessNote']): void {
  const accessStatus = {
    access: false,
    accessNote: accessNote,
  }
  emit('change', accessStatus)
  closeDeactivateBundleDialog()
}

/**
 * openDeactivateBundleDialog
 */
function openDeactivateBundleDialog(): void {
  deactivateBundleDialogRef.value?.open()
}

/**
 * cancelDeactivateBundleDialog
 */
function cancelDeactivateBundleDialog(): void {
  toggleBtn.value = !toggleBtn.value
  closeDeactivateBundleDialog()
}

/**
 * closeDeactivateBundleDialog
 */
function closeDeactivateBundleDialog(): void {
  deactivateBundleDialogRef.value?.close()
}
</script>
<style lang="scss" scoped>
.toggle-btn {
  border-radius: 1.25rem;
  display: flex;
  height: max-content;
  padding: 0.25rem;

  :deep(.v-btn) {
    opacity: 1;
  }
}
</style>
