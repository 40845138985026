<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('organizationDetails.headline') }}
    </template>
  </GeneralPageTitle>

  <OrganizationCard :organization="activeOrganization">
    <template #append>
      <IconButton
        v-if="isOrganizationAddressComplete(activeOrganization)"
        :icon="Icons.EDIT"
        @click="editOrganizationDialogRef?.open()"
      />

      <DefaultButton
        v-else
        :color="PlattformColors.PRIMARY"
        @click="editOrganizationDialogRef?.open()"
      >
        {{ t('organizationDetails.toComplete') }}
      </DefaultButton>
    </template>
  </OrganizationCard>

  <Dialog
    ref="editOrganizationDialogRef"
    @on-close="editOrganizationDialogRef?.close()"
  >
    <DialogOrganizationForm
      :organization-object="activeOrganization"
      :loading="editOrganizationLoading"
      @cancel="editOrganizationDialogRef?.close()"
      @submit="(updatedOrganizationData: OrganizationForm) => updateOrganization(updatedOrganizationData)"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { AlertTypes } from '@/models/enums/AlertTypes'
import { Icons } from '@/models/enums/IconTypes'
import { useAlertStore } from '@/store/alerts'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Dialog from '@/components/layout/Dialog.vue'
import DialogOrganizationForm from '@/components/dialogs/DialogOrganizationForm.vue'
import { OrganizationForm, OrganizationFullResponse } from '@/models/Organization'
import OrganizationCard from '@/components/organizations/OrganizationCard.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'

const { t } = useI18n()
const myOrganizationStore = useMyOrganizationStore()
const alertStore = useAlertStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)

const editOrganizationLoading = ref(false)
const editOrganizationDialogRef = ref<InstanceType<typeof Dialog>>()

/**
 * isOrganizationAddressComplete
 * @param {organization} organization
 * @return {boolean} true or false
 */
function isOrganizationAddressComplete(organization?: OrganizationFullResponse): boolean {
  return (
    !!organization?.name &&
    !!organization?.email &&
    !!organization?.address?.street &&
    !!organization?.address?.number &&
    !!organization?.address?.zipCode &&
    !!organization?.address?.city
  )
}

/**
 * updateOrganization
 * @param {organization} organization
 */
async function updateOrganization(organization: OrganizationForm): Promise<void> {
  if (organization) {
    try {
      editOrganizationLoading.value = true
      await myOrganizationStore.UPDATE(organization)
      alertStore.add({
        text: t('editOrganization.update.success'),
        type: AlertTypes.SUCCESS,
      })
    } finally {
      editOrganizationLoading.value = false
      editOrganizationDialogRef.value?.close()
    }
  }
}
</script>

<i18n lang="yaml">
de:
  editOrganization:
    update:
      success: Deine Organisationsdaten wurden erfolgreich aktualisiert.
  organizationDetails:
    headline: Organisationsdaten
    toComplete: Vervollständigen
</i18n>
