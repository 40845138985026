interface FormatHelper {
  getFirstLetterUpperCase: (text?: string) => string
  stringSort: (stringA: string, stringB: string) => number
}

/**
 * @return {FormatHelper}
 */
export function useFormatHelper(): FormatHelper {
  /**
   * return the first letter of the text in uppercase
   * @param {string | undefined} text
   * @return {string} first letter of text in uppercase
   */
  function getFirstLetterUpperCase(text?: string): string {
    return text ? text.slice(0, 1).toUpperCase() : '?'
  }

  /**
   * compare two strings to sort them
   * @param {string} stringA
   * @param {string} stringB
   * @return {number} sort result
   */
  function stringSort(stringA: string, stringB: string): number {
    const a = stringA.toLowerCase()
    const b = stringB.toLowerCase()
    if (a > b) {
      return 1
    } else if (a < b) {
      return -1
    }
    return 0
  }

  return {
    getFirstLetterUpperCase,
    stringSort,
  }
}
