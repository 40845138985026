<template>
  <div v-if="bundle">
    <GeneralPageTitle>
      <template #prepend>
        <BundleAvatar :size="80" />
      </template>
      <template #title>
        {{ bundle.name }}
      </template>
      <template #subtitle>
        <b>{{ t('providerBundleDetail.appId') }}</b> {{ bundle.id }}
      </template>
      <template #append>
        <FlagStatus
          class="mx-4"
          :color="getStatusSetFromStatusKey(bundle.contractStatus)?.color"
          :status-key="getStatusSetFromStatusKey(bundle.contractStatus)?.key"
          :outlined="getStatusSetFromStatusKey(bundle.contractStatus)?.outlined"
        >
          {{ t(`bundleStatus.${bundle.contractStatus}`) }}
        </FlagStatus>

        <AccessStatusToggleButton
          v-if="
            bundle.contractStatus === StatusTypes.APPROVED ||
            bundle.contractStatus === StatusTypes.IN_TERMINATION ||
            bundle.contractStatus === StatusTypes.TERMINATED
          "
          :toggle-btn="bundle.accessStatus?.access"
          :tooltips="{ active: t('providerBundleDetail.toggleBtn.tooltips.active'), inactive: t('providerBundleDetail.toggleBtn.tooltips.inactive') }"
          :readonly="false"
          :loading="AccessStatusToggleButtonLoading"
          @change="(accessStatus) => toggleBundleAccessStatus(accessStatus)"
        />
        <DefaultButton
          v-if="bundle?.contractStatus === StatusTypes.REQUESTED || bundle?.contractStatus === StatusTypes.IN_PROGRESS"
          :color="PlattformColors.PRIMARY"
          :loading="changeStatusButtonLoadingState"
          @click="changeStatusDialogRef?.open()"
        >
          {{ t(`providerBundleDetail.btn.changeStatus`) }}
        </DefaultButton>
        <v-menu
          location="bottom"
          transition="slide-y-transition"
        >
          <template #activator="{ props }">
            <IconButton
              v-if="
                bundle.contractStatus === StatusTypes.APPROVED ||
                bundle.contractStatus === StatusTypes.IN_TERMINATION ||
                bundle.contractStatus === StatusTypes.TERMINATED
              "
              v-bind="props"
              :icon="Icons.OPTIONS_VERTICAL"
            />
          </template>
          <v-list density="compact">
            <v-list-item
              v-for="(item, i) in bundleDetailMenuList"
              :key="i"
              class="context-menu-list-item"
              flat
              :disabled="item.disabled ? true : false"
              @click="item.action"
            >
              <template #prepend>
                <v-icon>{{ item.icon }}</v-icon>
              </template>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </GeneralPageTitle>
    <p class="mb-6">{{ bundle.description }}</p>
    <InfoCardContainer
      :bundle="bundle"
      @handle-request="(change: InfoElement) => handleChange(change)"
    />
    <v-tabs>
      <v-tab
        replace
        exact
        :to="{ name: Routes.PROVIDER_BUNDLES_PRODUCTS, params: { bundleId: bundle?.id } }"
      >
        {{ t('providerBundleDetail.productsTab') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.PROVIDER_BUNDLES_BUSINESSCASE, params: { bundleId: bundle?.id } }"
      >
        {{ t('providerBundleDetail.businessCaseTab') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.PROVIDER_BUNDLES_CREDENTIALS, params: { bundleId: bundle?.id } }"
      >
        {{ t('providerBundleDetail.credentialsTab') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.PROVIDER_BUNDLES_CONTACT, params: { bundleId: bundle?.id } }"
      >
        {{ t('providerBundleDetail.bundleContactTab') }}
      </v-tab>
    </v-tabs>
    <TabContainer />
  </div>
  <Dialog
    ref="changeStatusDialogRef"
    @on-close="changeStatusDialogRef?.close()"
  >
    <DialogBundleChangeStatus
      :current-status="bundle?.contractStatus"
      @cancel="changeStatusDialogRef?.close()"
      @change-status="(contractStatus: StatusTypes, note: string) => handleChangeStatus(contractStatus, note)"
    />
  </Dialog>
  <Dialog
    ref="redirectCredentialsDialogRef"
    :size="DialogSizes.EXTRA_LARGE"
    @on-close="redirectToCredentials()"
  >
    <DialogBundleCredentialRedirect
      :accesses="bundle?.accesses"
      @done="redirectToCredentials()"
    />
  </Dialog>
  <Dialog
    ref="subscriptionChangeDialogRef"
    @on-close="subscriptionChangeDialogRef?.close()"
  >
    <DialogBundleChangeSubscription
      :changed-subscription="bundleChangedSubscription"
      :bundle-name="bundle?.name"
      @cancel="subscriptionChangeDialogRef?.close()"
      @approve="(subscriptionId: SubscriptionResponse['id']) => handleApproveSubscriptionChanges(subscriptionId)"
      @reject="(subscriptionId: SubscriptionResponse['id'], note: string) => handleRejectSubscriptionChanges(subscriptionId, note)"
    />
  </Dialog>
  <Dialog
    ref="businessCaseChangeDialogRef"
    @on-close="businessCaseChangeDialogRef?.close()"
  >
    <DialogBundleBusinessCaseChangeHandle
      :bundle="bundle"
      @cancel="businessCaseChangeDialogRef?.close()"
      @approve="(bundleId: BundleResponse['id']) => handleApproveBusinessCaseChanges(bundleId)"
      @reject="(bundleId: BundleResponse['id'], note: string) => handleRejectBusinessCaseChanges(bundleId, note)"
    />
  </Dialog>
  <Dialog
    ref="accessRequestedDialogRef"
    @on-close="accessRequestedDialogRef?.close()"
  >
    <DialogBundleCredentialHandleRequest
      :selected-access="bundleAccessRequested"
      @cancel="accessRequestedDialogRef?.close()"
      @activate="(access: AccessResponse) => activateAccess(access)"
      @decline="(access: AccessResponse, note: AccessStatus['note']) => declineAccess(access, note)"
    />
  </Dialog>
  <Dialog
    ref="accessRequestedDeactivationDialogRef"
    @on-close="accessRequestedDeactivationDialogRef?.close()"
  >
    <DialogBundleCredentialDeactivateRequested
      :selected-access="bundleAccessRequested"
      @cancel="accessRequestedDeactivationDialogRef?.close()"
      @submit="(access: AccessResponse) => deactivateAccess(access, '')"
    />
  </Dialog>
  <Dialog
    ref="terminateBundleDialogRef"
    @on-close="terminateBundleDialogRef?.close()"
  >
    <DialogBundleTerminate
      :bundle="bundle"
      :loading="terminateBundleLoading"
      provider
      @cancel="terminateBundleDialogRef?.close()"
      @submit="(note: string | undefined) => terminateBundle(note)"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { useBundleStore } from '@/store/bundles'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { BundleAccessStatusResponse, BundleResponse } from '@/models/Bundle'
import AccessStatusToggleButton from '@/components/bundles/AccessStatusToggleButton.vue'
import InfoCardContainer from '@/components/bundles/InfoCardContainer.vue'
import TabContainer from '@/components/TabContainer.vue'
import { Routes } from '@/models/enums/Routes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import Dialog from '@/components/layout/Dialog.vue'
import DialogBundleChangeStatus from '@/components/dialogs/DialogBundleChangeStatus.vue'
import DialogBundleCredentialRedirect from '@/components/dialogs/DialogBundleCredentialRedirect.vue'
import DialogBundleCredentialHandleRequest from '@/components/dialogs/DialogBundleCredentialHandleRequest.vue'
import DialogBundleCredentialDeactivateRequested from '@/components/dialogs/DialogBundleCredentialDeactivateRequested.vue'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { InfoElement, InfoElementChangedSubscription } from '@/models/InfoElement'
import DialogBundleChangeSubscription from '@/components/dialogs/DialogBundleChangeSubscription.vue'
import DialogBundleBusinessCaseChangeHandle from '@/components/dialogs/DialogBundleBusinessCaseChangeHandle.vue'
import { SubscriptionResponse } from '@/models/Subscription'
import { useSubscriptionStore } from '@/store/subscriptions'
import { ChangeTypes } from '@/models/enums/ChangeTypes'
import { AccessResponse, AccessStatus } from '@/models/Access'
import { useAccessesStore } from '@/store/accesses'
import { DialogSizes } from '@/models/enums/DialogSizes'
import { Icons } from '@/models/enums/IconTypes'
import DialogBundleTerminate from '@/components/dialogs/DialogBundleTerminate.vue'
import { useStatusHelper } from '@/composables/useStatusHelper'
import FlagStatus from '@/components/baseComponents/flags/FlagStatus.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import BundleAvatar from '@/components/bundles/BundleAvatar.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const bundleStore = useBundleStore()
const alertStore = useAlertStore()
const subscriptionStore = useSubscriptionStore()
const accessStore = useAccessesStore()

const { getStatusSetFromStatusKey } = useStatusHelper()

const { bundle } = storeToRefs(bundleStore)
const bundleId = route.params.bundleId as string

const AccessStatusToggleButtonLoading = ref(false)
const changeStatusDialogRef = ref<InstanceType<typeof Dialog>>()
const redirectCredentialsDialogRef = ref<InstanceType<typeof Dialog>>()
const businessCaseChangeDialogRef = ref<InstanceType<typeof Dialog>>()
const subscriptionChangeDialogRef = ref<InstanceType<typeof Dialog>>()
const accessRequestedDialogRef = ref<InstanceType<typeof Dialog>>()
const accessRequestedDeactivationDialogRef = ref<InstanceType<typeof Dialog>>()
const terminateBundleDialogRef = ref<InstanceType<typeof Dialog>>()
const changeStatusButtonLoadingState = ref(false)
const terminateBundleLoading = ref(false)
const bundleChangedSubscription = ref<InfoElementChangedSubscription>()
const bundleAccessRequested = ref<AccessResponse>()

/**
 * toggleBundleAccessStatus
 * @param {accessStatus} accessStatus
 */
async function toggleBundleAccessStatus(accessStatus: BundleAccessStatusResponse): Promise<void> {
  try {
    await bundleStore.PUT_ACCESS_STATUS(bundleId, accessStatus)
  } catch {
    Promise.resolve()
  }
}
interface MenuItem {
  title: string
  icon: Icons
  action: () => void
  disabled?: boolean
}
const menuItems: { [key: string]: MenuItem } = {
  terminate: {
    title: t('providerBundleDetail.menu.cancel'),
    icon: Icons.CLOSE,
    action: (): void => terminateBundleDialogRef.value?.open(),
  },
  contact: {
    title: t('providerBundleDetail.menu.contact'),
    icon: Icons.EMAIL,
    action: (): string => (window.location.href = `mailto:${bundle?.value?.contact?.email}`),
  },
}
const bundleDetailMenuList = computed(() => {
  switch (bundle?.value?.contractStatus) {
    case StatusTypes.APPROVED:
      return [menuItems.terminate, menuItems.contact]
    case StatusTypes.IN_TERMINATION:
      return [{ ...menuItems.terminate, disabled: true }, menuItems.contact]
    case StatusTypes.TERMINATED:
      return [menuItems.contact]
    default:
      return []
  }
})

/**
 * handleChangeStatus
 * @param {contractStatus} contractStatus
 * @param {note} note
 */
async function handleChangeStatus(contractStatus: StatusTypes, note: string): Promise<void> {
  try {
    changeStatusDialogRef.value?.close()
    changeStatusButtonLoadingState.value = true
    await bundleStore.ADD_APPROVAL(bundleId, contractStatus, note, true)
    alertStore.add({
      text: t('providerBundleDetail.statusChanged'),
      type: AlertTypes.SUCCESS,
    })
    if (contractStatus === StatusTypes.APPROVED && bundle?.value?.accesses?.length) {
      redirectCredentialsDialogRef.value?.open()
    }
  } catch {
    Promise.resolve()
  } finally {
    changeStatusButtonLoadingState.value = false
  }
}

/**
 * terminateBundle
 * @param {note} note
 */
async function terminateBundle(note: string | undefined): Promise<void> {
  if (bundle?.value) {
    try {
      terminateBundleLoading.value = true
      await bundleStore.TERMINATE(bundle?.value?.id, note)
    } catch {
      Promise.resolve()
    } finally {
      terminateBundleLoading.value = false
      terminateBundleDialogRef.value?.close()
    }
  }
}

/**
 * redirectToCredentials
 */
function redirectToCredentials(): void {
  redirectCredentialsDialogRef.value?.close()
  router.push({ name: Routes.PROVIDER_BUNDLES_CREDENTIALS, params: { bundleId: bundle?.value?.id } })
}

/**
 * handleChange
 * @param {change} change
 */
async function handleChange(change: InfoElement): Promise<void> {
  if (change.changeType === ChangeTypes.BUSINESS_CASE) {
    businessCaseChangeDialogRef.value?.open()
  } else if (change.changeType === ChangeTypes.SUBSCRIPTION) {
    bundleChangedSubscription.value = change.changedSubscription
    subscriptionChangeDialogRef.value?.open()
  } else if (change.changeType === ChangeTypes.ACCESS_REQUESTED) {
    bundleAccessRequested.value = change.requestedAccess
    accessRequestedDialogRef.value?.open()
  } else if (change.changeType === ChangeTypes.ACCESS_REQUESTED_DEACTIVATION) {
    bundleAccessRequested.value = change.requestedAccess
    accessRequestedDeactivationDialogRef.value?.open()
  }
}

/**
 * handleApproveSubscriptionChanges
 * @param {subscriptionId} subscriptionId
 */
async function handleApproveSubscriptionChanges(subscriptionId: SubscriptionResponse['id']): Promise<void> {
  try {
    const updatedBundle = await subscriptionStore.APPROVE(subscriptionId, StatusTypes.APPROVED)
    if (bundle?.value) bundle.value = updatedBundle
    alertStore.add({
      text: t('providerBundleDetail.change.approved'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    subscriptionChangeDialogRef.value?.close()
  }
}

/**
 * handleRejectSubscriptionChanges
 * @param {subscriptionId} subscriptionId
 * @param {note} note
 */
async function handleRejectSubscriptionChanges(subscriptionId: SubscriptionResponse['id'], note: string): Promise<void> {
  try {
    const updatedBundle = await subscriptionStore.REJECT(subscriptionId, note)
    if (bundle?.value) bundle.value = updatedBundle
    alertStore.add({
      text: t('providerBundleDetail.change.rejected'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    subscriptionChangeDialogRef.value?.close()
  }
}

/**
 * handleRejectBusinessCaseChanges
 * @param {bundleId} bundleId
 * @param {note} note
 */
async function handleRejectBusinessCaseChanges(bundleId: BundleResponse['id'], note: string): Promise<void> {
  try {
    await bundleStore.ADD_APPROVAL(bundleId, StatusTypes.REJECTED, note)
    alertStore.add({
      text: t('providerBundleDetail.change.rejected'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    businessCaseChangeDialogRef.value?.close()
  }
}

/**
 * handleApproveBusinessCaseChanges
 * @param {bundleId} bundleId
 */
async function handleApproveBusinessCaseChanges(bundleId: BundleResponse['id']): Promise<void> {
  try {
    await bundleStore.ADD_APPROVAL(bundleId, StatusTypes.APPROVED)
    alertStore.add({
      text: t('providerBundleDetail.change.approved'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    businessCaseChangeDialogRef.value?.close()
  }
}

/**
 * activateAccess
 * @param {access} access
 */
async function activateAccess(access: AccessResponse): Promise<void> {
  try {
    const updatedAccess = await accessStore.ACTIVATE(access.id, 'access activated')
    bundleStore.updateBundleAccesses(updatedAccess)
    alertStore.add({
      text: t('providerBundleDetail.access.activate'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    accessRequestedDialogRef.value?.close()
  }
}

/**
 * declineAccess
 * @param {access} access
 * @param {declineNote} declineNote
 */
async function declineAccess(access: AccessResponse, declineNote: string): Promise<void> {
  try {
    const updatedAccess = await accessStore.DECLINE(access.id, declineNote)
    bundleStore.updateBundleAccesses(updatedAccess)
    alertStore.add({
      text: t('providerBundleDetail.access.decline'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    accessRequestedDialogRef.value?.close()
  }
}

/**
 * deactivateAccess
 * @param {access} access
 * @param {note} note
 */
async function deactivateAccess(access: AccessResponse, note: AccessStatus['note']): Promise<void> {
  try {
    const updatedAccess = await accessStore.DEACTIVATE(access.id, note)
    bundleStore.updateBundleAccesses(updatedAccess)
    alertStore.add({
      text: t('providerBundleDetail.access.deactivate'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    accessRequestedDeactivationDialogRef.value?.close()
  }
}

await bundleStore.GET_BY_ID(bundleId)
</script>
<i18n lang="yaml">
de:
  providerBundleDetail:
    appId: App ID
    productsTab: API-Produkte
    businessCaseTab: Business Case
    credentialsTab: Authentifizierungsmittel
    bundleContactTab: Kontakt
    btn:
      changeStatus: Status ändern
    approvals:
      note:
        headline: 'Ablehnungsgrund:'
        description: Die App wurde abgelehnt.
    toggleBtn:
      tooltips:
        active: App-Zugriff freigeben
        inactive: App-Zugriff sperren
    statusChanged: Der Status wurde erfolgreich geändert!
    change:
      approved: Die Änderung wurde erfolgreich akzeptiert!
      rejected: Die Änderung wurde erfolgreich abgelehnt!
    access:
      activate: Das Authentifizierungsmittel wurde aktiviert!
      decline: Das Authentifizierungsmittel wurde abgelehnt!
      deactivate: Das Authentifizierungsmittel wurde deaktiviert!
    menu:
      cancel: App kündigen
      contact: Kunde kontaktieren
</i18n>
