<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('legalContent.headline') }}
    </template>
  </GeneralPageTitle>
  <v-row>
    <v-col>
      <NavigationTeaser>
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.LINK }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('legalContent.imprintTeaser.title') }}
        </template>
        <template #subtitle>
          {{ activeTenant?.legalInformation.imprintUrl || t('legalContent.imprintTeaser.subtitleNotSet') }}
        </template>
        <template #append>
          <IconButton
            :icon="Icons.EDIT"
            @click.stop="showEditTenantLegalImprintDialog()"
          />
        </template>
      </NavigationTeaser>
    </v-col>
    <v-col>
      <NavigationTeaser>
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.LINK }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('legalContent.privacyPolicyTeaser.title') }}
        </template>
        <template #subtitle>
          {{ activeTenant?.legalInformation.privacyPolicyUrl || t('legalContent.privacyPolicyTeaser.subtitleNotSet') }}
        </template>
        <template #append>
          <IconButton
            :icon="Icons.EDIT"
            @click.stop="showEditTenantLegalPrivacyPolicyDialog()"
          />
        </template>
      </NavigationTeaser>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <NavigationTeaser>
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.LINK }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('legalContent.termsOfUseTeaser.title') }}
        </template>
        <template #subtitle>
          {{ activeTenant?.legalInformation.termsOfUseUrl || t('legalContent.termsOfUseTeaser.subtitleNotSet') }}
        </template>
        <template #append>
          <IconButton
            :icon="Icons.EDIT"
            @click.stop="showEditTenantLegalTermsOfUseDialog()"
          />
        </template>
      </NavigationTeaser>
    </v-col>
    <v-col>
      <NavigationTeaser>
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.LINK }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('legalContent.supportTeaser.title') }}
        </template>
        <template #subtitle>
          {{ activeTenant?.legalInformation.supportUrl || t('legalContent.supportTeaser.subtitleNotSet') }}
        </template>
        <template #append>
          <IconButton
            :icon="Icons.EDIT"
            @click.stop="showEditTenantSupportDialog()"
          />
        </template>
      </NavigationTeaser>
    </v-col>
  </v-row>

  <Dialog
    ref="dialogLegalImprintRef"
    @on-close="closeEditTenantLegalImprintDialog()"
  >
    <DialogTenantLegalUrlForm
      :legal-object="legalImprintObject"
      :loading="loading"
      @cancel="closeEditTenantLegalImprintDialog()"
      @submit="(tenantLegalImprintUrl: LegalInformation['imprintUrl']) => editTenantLegalImprintUrl(tenantLegalImprintUrl)"
    />
  </Dialog>

  <Dialog
    ref="dialogPrivacyPolicyRef"
    @on-close="closeEditTenantLegalPrivacyPolicyDialog()"
  >
    <DialogTenantLegalUrlForm
      :legal-object="legalPrivacyPolicyObject"
      :loading="loading"
      @cancel="closeEditTenantLegalPrivacyPolicyDialog()"
      @submit="(tenantLegalPrivacyPolicyUrl: LegalInformation['privacyPolicyUrl']) => editTenantLegalPrivacyPolicyUrl(tenantLegalPrivacyPolicyUrl)"
    />
  </Dialog>

  <Dialog
    ref="dialogTermsOfUseRef"
    @on-close="closeEditTenantLegalTermsOfUseDialog()"
  >
    <DialogTenantLegalUrlForm
      :legal-object="legalTermsOfUseObject"
      :loading="loading"
      @cancel="closeEditTenantLegalTermsOfUseDialog()"
      @submit="(tenantLegalImprintUrl: LegalInformation['privacyPolicyUrl']) => editTenantLegalTermsOfUseUrl(tenantLegalImprintUrl)"
    />
  </Dialog>

  <Dialog
    ref="dialogSupportRef"
    @on-close="closeEditTenantSupportDialog()"
  >
    <DialogTenantLegalUrlForm
      :legal-object="supportObject"
      :loading="loading"
      @cancel="closeEditTenantSupportDialog()"
      @submit="(tenantSupportUrl: LegalInformation['supportUrl']) => editTenantSupportUrl(tenantSupportUrl)"
    />
  </Dialog>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { Icons } from '@/models/enums/IconTypes'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { LegalInformation } from '@/models/Tenant'
import Dialog from '@/components/layout/Dialog.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import NavigationTeaser from '@/components/baseComponents/teasers/NavigationTeaser.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DialogTenantLegalUrlForm from '@/components/dialogs/DialogTenantLegalUrlForm.vue'
import { useTenantStore } from '@/store/tenants'
import { useAlertStore } from '@/store/alerts'

const { t } = useI18n()
const loading = ref(false)
const dialogLegalImprintRef = ref<InstanceType<typeof Dialog>>()
const dialogPrivacyPolicyRef = ref<InstanceType<typeof Dialog>>()
const dialogTermsOfUseRef = ref<InstanceType<typeof Dialog>>()
const dialogSupportRef = ref<InstanceType<typeof Dialog>>()

const tenantStore = useTenantStore()
const alertStore = useAlertStore()
const { activeTenant } = storeToRefs(tenantStore)

const legalImprintObject = computed(() => {
  return {
    headline: t('legalContent.imprintDialog.headline'),
    copy: t('legalContent.imprintDialog.copy'),
    inputLabel: t('legalContent.imprintDialog.inputLabel'),
    inputValue: activeTenant?.value?.legalInformation.imprintUrl || '',
  }
})

const legalPrivacyPolicyObject = computed(() => {
  return {
    headline: t('legalContent.privacyPolicyDialog.headline'),
    copy: t('legalContent.privacyPolicyDialog.copy'),
    inputLabel: t('legalContent.privacyPolicyDialog.inputLabel'),
    inputValue: activeTenant?.value?.legalInformation.privacyPolicyUrl || '',
  }
})

const legalTermsOfUseObject = computed(() => {
  return {
    headline: t('legalContent.termsOfUseDialog.headline'),
    copy: t('legalContent.termsOfUseDialog.copy'),
    inputLabel: t('legalContent.termsOfUseDialog.inputLabel'),
    inputValue: activeTenant?.value?.legalInformation.termsOfUseUrl || '',
  }
})

const supportObject = computed(() => {
  return {
    headline: t('legalContent.supportDialog.headline'),
    copy: t('legalContent.supportDialog.copy'),
    inputLabel: t('legalContent.supportDialog.inputLabel'),
    inputValue: activeTenant?.value?.legalInformation.supportUrl || '',
  }
})

/**
 * showEditTenantLegalImprintDialog
 */
function showEditTenantLegalImprintDialog(): void {
  dialogLegalImprintRef.value?.open()
}

/**
 * closeEditTenantLegalImprintDialog
 */
function closeEditTenantLegalImprintDialog(): void {
  dialogLegalImprintRef.value?.close()
}

/**
 * editTenantLegalImprintUrl(LegalInformation)
 * @param {LegalInformation} tenantLegalImprintUrl
 */
async function editTenantLegalImprintUrl(tenantLegalImprintUrl: LegalInformation['imprintUrl']): Promise<void> {
  if (activeTenant?.value && tenantLegalImprintUrl) {
    try {
      loading.value = true
      await tenantStore.UPDATE(activeTenant?.value.id, { imprintUrl: tenantLegalImprintUrl })
      alertStore.add({
        text: t('legalContent.imprintDialog.update.success'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      return Promise.resolve()
    } finally {
      loading.value = false
      closeEditTenantLegalImprintDialog()
    }
  }
}

/**
 * showEditTenantLegalPrivacyPolicyDialog
 */
function showEditTenantLegalPrivacyPolicyDialog(): void {
  dialogPrivacyPolicyRef.value?.open()
}

/**
 * closeEditTenantLegalPrivacyPolicyDialog
 */
function closeEditTenantLegalPrivacyPolicyDialog(): void {
  dialogPrivacyPolicyRef.value?.close()
}

/**
 * editTenantLegalPrivacyPolicyUrl(LegalInformation)
 * @param {LegalInformation} tenantLegalPrivacyPolicyUrl
 */
async function editTenantLegalPrivacyPolicyUrl(tenantLegalPrivacyPolicyUrl: LegalInformation['privacyPolicyUrl']): Promise<void> {
  if (activeTenant?.value && tenantLegalPrivacyPolicyUrl) {
    try {
      loading.value = true
      await tenantStore.UPDATE(activeTenant?.value.id, { privacyPolicyUrl: tenantLegalPrivacyPolicyUrl })
      alertStore.add({
        text: t('legalContent.privacyPolicyDialog.update.success'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      return Promise.resolve()
    } finally {
      loading.value = false
      closeEditTenantLegalPrivacyPolicyDialog()
    }
  }
}

/**
 * showEditTenantLegalTermsOfUseDialog
 */
function showEditTenantLegalTermsOfUseDialog(): void {
  dialogTermsOfUseRef.value?.open()
}

/**
 * closeEditTenantLegalTermsOfUseDialog
 */
function closeEditTenantLegalTermsOfUseDialog(): void {
  dialogTermsOfUseRef.value?.close()
}

/**
 * editTenantLegalTermsOfUseUrl(LegalInformation)
 * @param {LegalInformation} tenantLegalTermsOfUseUrl
 */
async function editTenantLegalTermsOfUseUrl(tenantLegalTermsOfUseUrl: LegalInformation['termsOfUseUrl']): Promise<void> {
  if (activeTenant?.value && tenantLegalTermsOfUseUrl) {
    try {
      loading.value = true
      await tenantStore.UPDATE(activeTenant?.value.id, { termsOfUseUrl: tenantLegalTermsOfUseUrl })
      alertStore.add({
        text: t('legalContent.termsOfUseDialog.update.success'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      return Promise.resolve()
    } finally {
      loading.value = false
      closeEditTenantLegalTermsOfUseDialog()
    }
  }
}

/**
 * showEditTenantSupportDialog
 */
function showEditTenantSupportDialog(): void {
  dialogSupportRef.value?.open()
}

/**
 * closeEditTenantSupportDialog
 */
function closeEditTenantSupportDialog(): void {
  dialogSupportRef.value?.close()
}

/**
 * editTenantSupportUrl(LegalInformation)
 * @param {LegalInformation} tenantSupportUrl
 */
async function editTenantSupportUrl(tenantSupportUrl: LegalInformation['supportUrl']): Promise<void> {
  if (activeTenant?.value && tenantSupportUrl) {
    try {
      loading.value = true
      await tenantStore.UPDATE(activeTenant?.value.id, { supportUrl: tenantSupportUrl })
      alertStore.add({
        text: t('legalContent.supportDialog.update.success'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      return Promise.resolve()
    } finally {
      loading.value = false
      closeEditTenantSupportDialog()
    }
  }
}
</script>
<i18n lang="yaml">
de:
  legalContent:
    headline: Rechtliche Inhalte
    imprintTeaser:
      title: Impressum
      subtitleNotSet: Impressum-URL nicht hinterlegt
    imprintDialog:
      headline: Impressum
      copy: Verlinke dein Impressum mittels deiner URL. Dieses wird in deinem DevPortal deinen Kundinnen angezeigt.
      inputLabel: Impressum-URL
      update:
        success: Impressum-URL wurde erfolgreich aktualisiert.
    privacyPolicyTeaser:
      title: Datenschutz
      subtitleNotSet: Datenschutz-URL nicht hinterlegt
    privacyPolicyDialog:
      headline: Datenschutz
      copy: Verlinke dein Datenschutz mittels deiner URL. Dieser wird in deinem DevPortal deinen Kundinnen angezeigt.
      inputLabel: Datenschutz-URL
      update:
        success: Datenschutz-URL wurde erfolgreich aktualisiert.
    termsOfUseTeaser:
      title: Nutzungsbedingungen
      subtitleNotSet: Nutzungsbedingungen-URL nicht hinterlegt
    termsOfUseDialog:
      headline: Nutzungsbedingungen
      copy: Verlinke deine Nutzungsbedingungen mittels deiner URL. Dieser wird in deinem DevPortal deinen Kundinnen angezeigt.
      inputLabel: Nutzungsbedingungen-URL
      update:
        success: Nutzungsbedingungen-URL wurde erfolgreich aktualisiert.
    supportTeaser:
      title: Support
      subtitleNotSet: Support-URL nicht hinterlegt
    supportDialog:
      headline: Support
      copy: Verlinke deinen Support Service mittels deiner URL. Dieser wird in deinem DevPortal deinen Kundinnen angezeigt.
      inputLabel: Support-URL
      update:
        success: Support-URL wurde erfolgreich aktualisiert.
</i18n>
