<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('consumerDashboard.headline') }}
    </template>
  </GeneralPageTitle>
  <v-row>
    <v-col>
      <OrganizationTeaser :active-organization="activeOrganization" />
    </v-col>
  </v-row>
  <v-row class="d-flex align-stretch">
    <v-col>
      <MarketplaceTeaser />
    </v-col>
    <v-col>
      <AppTeaser
        :bundles="bundles"
        @show-create-bundle-dialog="createBundleDialog?.open()"
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <TeamTeaser
        :team-count="teamsLength"
        :member-count="usersLength"
      />
    </v-col>
    <v-col>
      <DocsTeaser />
    </v-col>
  </v-row>
  <Dialog
    ref="createBundleDialog"
    @on-close="closeCreateBundleDialog()"
  >
    <DialogBundleForm
      :available-organizations="organizations"
      :loading="createBundleLoading"
      @cancel="closeCreateBundleDialog()"
      @submit="(bundle: BundleCreateRequest) => createBundle(bundle)"
    />
  </Dialog>
</template>
<script lang="ts" setup>
import { useBundleStore } from '@/store/bundles'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useTeamStore } from '@/store/teams'
import { useUserStore } from '@/store/users'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { SortValues } from '@/models/enums/SortValues'
import { ref } from 'vue'
import { useOrganizationStore } from '@/store/organizations'
import { BundleCreateRequest } from '@/models/Bundle'
import { Routes } from '@/models/enums/Routes'
import { useRouter } from 'vue-router'
import AppTeaser from '@/components/dashboards/consumer/AppTeaser.vue'
import DocsTeaser from '@/components/dashboards/general/DocsTeaser.vue'
import MarketplaceTeaser from '@/components/dashboards/user/MarketplaceTeaser.vue'
import OrganizationTeaser from '@/components/dashboards/general/OrganizationTeaser.vue'
import TeamTeaser from '@/components/dashboards/general/TeamTeaser.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import Dialog from '@/components/layout/Dialog.vue'
import DialogBundleForm from '@/components/dialogs/DialogBundleForm.vue'

const { t } = useI18n()

const router = useRouter()

const userStore = useUserStore()
const myOrganizationStore = useMyOrganizationStore()
const organizationStore = useOrganizationStore()
const bundleStore = useBundleStore()
const teamStore = useTeamStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)
const { organizations } = storeToRefs(organizationStore)
const { bundles } = storeToRefs(bundleStore)
const { teamsLength } = storeToRefs(teamStore)
const { usersLength } = storeToRefs(userStore)

const createBundleLoading = ref(false)
const createBundleDialog = ref<InstanceType<typeof Dialog>>()

/**
 *
 * createBundle
 * @param {bundle} bundle
 */
async function createBundle(bundle: BundleCreateRequest): Promise<void> {
  try {
    createBundleLoading.value = true
    const bundleResponse = await bundleStore.CREATE(bundle)
    await router.push({ name: Routes.CONSUMER_BUNDLES_PRODUCTS, params: { bundleId: bundleResponse.id } })
  } catch {
    closeCreateBundleDialog()
  } finally {
    createBundleLoading.value = false
  }
}

/**
 * closeCreateBundleDialog
 */
function closeCreateBundleDialog(): void {
  createBundleDialog.value?.close()
}

await Promise.allSettled([bundleStore.GET({ sort: SortValues.CREATED_AT }), teamStore.GET(), userStore.GET(), organizationStore.GET_ALL_PROVIDERS()])
</script>
<i18n lang="yaml">
de:
  consumerDashboard:
    headline: Kunden Dashboard
</i18n>
