<template>
  <v-menu
    :close-on-content-click="false"
    open-on-hover
    class="progress-button-menu"
    transition="slide-y-transition"
    :offset="20"
  >
    <template #activator="{ props }">
      <div v-bind="props">
        <v-btn-progress
          type="submit"
          :color="progressItemsFinishedPercentage < 100 ? PlattformColors.LIGHT : PlattformColors.PRIMARY"
          :class="progressItemsFinishedPercentage < 100 ? 'light' : 'dark'"
          size="large"
          flat
          :disabled="checkMandatoryFields"
          @click="emit('submit')"
        >
          <template #prepend>
            <v-progress-circular
              v-if="progressItemsFinishedPercentage < 100"
              :color="PlattformColors.SUCCESS"
              :model-value="progressItemsFinishedPercentage"
              :size="24"
              width="3"
            />
            <v-icon
              v-else
              :color="PlattformColors.LIGHT"
              :size="24"
            >
              {{ Icons.CIRCLE_CHECK }}
            </v-icon>
          </template>

          {{ buttonText }}
        </v-btn-progress>
      </div>
    </template>
    <v-list
      density="compact"
      class="bg-white"
    >
      <v-list-subheader class="px-0 mb-0">
        <div class="d-flex justify-space-between">
          <p class="highlight mb-0 pr-6">{{ t('progressButton.status') }}</p>
          <p class="highlight mb-0 pr-4 text-green">{{ progressItemsFinishedPercentage }}%</p>
        </div>
      </v-list-subheader>
      <v-list-item
        v-for="(item, index) in progressItems"
        :key="index"
        class="pr-4"
        @click="item.link"
      >
        <template #prepend>
          <v-icon
            :color="item.status.value ? 'green' : 'grey'"
            size="8"
          >
            {{ Icons.CIRCLE_FULL }}</v-icon
          >
        </template>
        <p class="mb-0">{{ item.name }}</p>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ComputedRef, computed } from 'vue'
import { Icons } from '@/models/enums/IconTypes'
import { PlattformColors } from '@/models/enums/ColorSets'

interface ProgressItem {
  name: string
  link: () => void
  status: ComputedRef<boolean>
  required: boolean
}

const componentProps = defineProps({
  progressItems: {
    type: Array<ProgressItem>,
    default: [],
  },
  buttonText: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['submit'])

const { t } = useI18n()

const checkMandatoryFields = computed(() => !componentProps.progressItems.every((item) => !item.required || item.status.value))

const progressItemsFinishedPercentage = computed(() => {
  const finishedCount = componentProps.progressItems.filter((item) => item.status.value).length
  const total = componentProps.progressItems.length
  return total > 0 ? Math.round((finishedCount / total) * 100) : 0
})
</script>
<style lang="scss" scoped>
.progress-button-menu {
  :deep(.v-overlay__content) {
    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid rgb(var(--v-theme-white));
      transform: translateX(-50%);
      z-index: 1;
    }
  }
}
</style>
<i18n lang="yaml">
de:
  progressButton:
    status: Bearbeitungsstatus
</i18n>
