<template>
  <v-form
    v-model="organizationForm"
    @submit.prevent="emit('submit', organization)"
  >
    <DialogDefaultLayout>
      <template #content>
        <h1 class="mb-8">{{ headline }}</h1>
        <p
          v-if="!organizationObject"
          class="mb-8"
        >
          <span class="font-weight-bold">{{ t('organizationForm.copy.bold') }}</span>
          {{ t('organizationForm.copy.standard') }}
        </p>

        <v-card
          elevation="0"
          class="bg-grey-lighten-4 mb-8"
        >
          <v-card-item>
            <DropZone @upload-file="(files) => onLogoSelected(files[0])" />
            <template #prepend>
              <OrganizationLogo
                :organization-logo="organization.logo"
                :size="80"
                class="mr-4"
              />
            </template>

            <div>
              <v-card-subtitle>
                <p class="xs mb-0">
                  {{ activeOrganization?.provider ? t('organizationForm.copy.logo.first.provider') : t('organizationForm.copy.logo.first.consumer') }}
                </p>
                <p class="xs mb-0">{{ t('organizationForm.copy.logo.second') }}</p>
              </v-card-subtitle>
              <v-card-actions class="pl-0 pr-0">
                <DefaultButton
                  :size="ButtonSizes.DEFAULT"
                  :color="PlattformColors.LIGHT"
                  :prepend-icon="Icons.CLOUD_UPLOAD"
                  :slim="false"
                  @click="triggerShowLogoInput()"
                >
                  {{ t('organizationForm.form.selectLogo') }}
                </DefaultButton>

                <IconButton
                  v-if="organization.logo"
                  :icon="Icons.DELETE"
                  :size="ButtonSizes.SMALL"
                  @click="onClearLogoUpload()"
                />

                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/svg+xml"
                  @change="(evt: Event) => fileSelected(evt)"
                />
              </v-card-actions>
            </div>
          </v-card-item>
        </v-card>
        <DefaultTextField
          v-model="organization.name"
          autofocus
          :label="t('organizationForm.form.name')"
          required
          :rules="organizationNameRules"
        />
        <DefaultTextField
          v-model="organization.email"
          :label="t('organizationForm.form.email')"
          required
          :rules="organizationEmailRules"
        />
        <div class="d-flex">
          <DefaultTextField
            v-model="organization.address.street"
            :label="t('organizationForm.form.street')"
            required
            :rules="organizationCityStreetRules"
            class="w-66 mr-6"
          />
          <DefaultTextField
            v-model="organization.address.number"
            :label="t('organizationForm.form.number')"
            required
            :rules="organizationNumberRules"
            class="w-33"
          />
        </div>
        <div class="d-flex">
          <DefaultTextField
            v-model="organization.address.zipCode"
            :label="t('organizationForm.form.zipCode')"
            required
            :rules="organizationZipCodeRules"
            class="w-33 mr-6"
          />
          <DefaultTextField
            v-model="organization.address.city"
            :label="t('organizationForm.form.city')"
            required
            :rules="organizationCityStreetRules"
            class="w-66"
          />
        </div>
        <DefaultTextField
          v-model="organization.registrationNumber"
          :label="t('organizationForm.form.registrationNumber')"
        />
      </template>
      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          :disabled="!organizationForm"
        >
          {{ organizationObject ? t('buttons.save') : t('buttons.create') }}
        </DefaultButton>

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayout>
  </v-form>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { computed, PropType, ref } from 'vue'
import { OrganizationFullResponse } from '@/models/Organization'
import { isMaxLength, isMinLength, isNotEmpty } from '@/validators'
import { ButtonSizes } from '@/models/enums/components/Buttons'
import { Icons } from '@/models/enums/IconTypes'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'
import OrganizationLogo from '@/components/organizations/OrganizationLogo.vue'
import DefaultTextField from '@/components/baseComponents/inputs/DefaultTextField.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DropZone from '@/components/filesAndFolders/DropZone.vue'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'

const { t } = useI18n()

const emit = defineEmits(['submit', 'cancel'])

const props = defineProps({
  organizationObject: {
    type: Object as PropType<OrganizationFullResponse>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const uploader = ref<HTMLTextAreaElement | null>(null)
const alertStore = useAlertStore()
const myOrganizationStore = useMyOrganizationStore()
const { activeOrganization } = storeToRefs(myOrganizationStore)

const organizationForm = ref(false)
const organization = ref({
  name: props.organizationObject?.name || '',
  email: props.organizationObject?.email || '',
  logo: props.organizationObject?.logo,
  address: {
    street: props.organizationObject?.address?.street,
    number: props.organizationObject?.address?.number,
    city: props.organizationObject?.address?.city,
    zipCode: props.organizationObject?.address?.zipCode,
  },
  registrationNumber: props.organizationObject?.registrationNumber,
})

const organizationNameRules = computed(() => [
  (v: string): boolean | string => isNotEmpty(v),
  (v: string): boolean | string => isMinLength(v, 3),
  (v: string): boolean | string => isMaxLength(v, 50),
])

const organizationEmailRules = computed(() => [
  (v: string): boolean | string => isNotEmpty(v),
  (v: string): boolean | string => isMinLength(v, 3),
  (v: string): boolean | string => isMaxLength(v, 64),
])

const organizationCityStreetRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 90)])
const organizationNumberRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 10)])
const organizationZipCodeRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 15)])

const headline = computed(() => (props.organizationObject ? t('organizationForm.headline.edit') : t('organizationForm.headline.create')))

async function onClearLogoUpload(): Promise<void> {
  organization.value.logo = ''
}

function triggerShowLogoInput(): void {
  uploader.value?.click()
}

function fileSelected(evt: Event): void {
  const elLogoInput = evt.target as HTMLInputElement
  if (elLogoInput && elLogoInput?.files) {
    onLogoSelected(elLogoInput.files[0])
  }
}

function onLogoSelected(file: File): void {
  try {
    const reader = new FileReader()
    reader.onloadend = (): void => {
      organization.value.logo = reader.result as string
    }
    reader.readAsDataURL(file)
  } catch {
    alertStore.add({
      text: t('organizationForm.errors.readLogo', { filename: file.name }),
      type: AlertTypes.ERROR,
    })
  }
}
</script>
<i18n lang="yaml">
de:
  organizationForm:
    errors:
      readLogo: 'Die Datei: {filename} konnte nicht gelesen werden.'
    headline:
      create: Organisation erstellen
      edit: Organisationsdaten
    copy:
      bold: Deine Organisation ist bereits vorhanden?
      standard: Lass Dich von Deinem Admin zur Organisation hinzufügen.
      logo:
        first:
          consumer: 'Dein Logo ist für API-Anbieter und dich sichtbar.'
          provider: 'Dein Logo ist für deine Kunden und auf dem Marktplatz sichtbar.'
        second: 'Optimale Größe: 96x96 px; Datei-Format: .svg'
    form:
      name: Organisationsname*
      email: Email*
      street: Straße*
      number: Hausnummer*
      city: Stadt*
      zipCode: Postleitzahl*
      registrationNumber: Handelsregisternummer
      selectLogo: Logo hochladen
</i18n>
