<template>
  <h1 class="mb-12">{{ t('xs2aNewsletter.headline') }}</h1>
  <div class="content-container">
    <v-form
      ref="newsletterSubscriptionFormRef"
      v-model="newsletterSubscriptionForm"
      class="my-4"
      @submit.prevent="sendNewsletterRequest()"
    >
      <v-row class="mb-4">
        <v-col cols="8">
          <DefaultTextField
            v-model="newsletterRequestForm.email"
            :label="t('xs2aNewsletter.label.email')"
            required
            :rules="emailRules"
          />
        </v-col>
        <v-col
          cols="3"
          offset="1"
        >
          <DefaultSelect
            v-model="newsletterRequestForm.language"
            :items="languages"
            :label="t('xs2aNewsletter.label.lang')"
            required
            :rules="notEmptyRules"
          />
        </v-col>
      </v-row>
      <DefaultCheckbox
        v-model="newsletterRequestForm.generalNewsletter"
        :label="t('xs2aNewsletter.checkbox.general')"
      />
      <DefaultCheckbox
        v-model="newsletterRequestForm.tppNewsletter"
        :label="t('xs2aNewsletter.checkbox.tpp')"
      />
      <Transition name="slide-fade">
        <div
          v-if="newsletterRequestForm.tppNewsletter"
          class="mt-8"
        >
          <v-row>
            <v-col
              cols="8"
              class="py-0"
            >
              <DefaultTextField
                v-model="tppInfos.tppNewsletterId"
                required
                :rules="notEmptyRules"
                :label="t('xs2aNewsletter.label.tppId')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="8"
              class="py-0"
            >
              <DefaultTextField
                v-model="tppInfos.tppNewsletterName"
                required
                :rules="notEmptyRules"
                :label="t('xs2aNewsletter.label.tppName')"
              />
            </v-col>
          </v-row>
        </div>
      </Transition>
      <DefaultCheckbox
        v-model="acceptedPrivacy"
        :label="t('xs2aNewsletter.checkbox.acceptTerms')"
        required
        :rules="checkboxRules"
      />
      <p class="ml-10 mb-8">
        {{ `( ${t('xs2aNewsletter.checkbox.privacy.text')}` }}
        <a
          href="https://www.f-i.de/Service/Datenschutz"
          target="_blank"
          class="text-info"
        >
          {{ ` ${t('xs2aNewsletter.checkbox.privacy.link')}` }}</a
        >{{ ' )' }}
      </p>
      <div class="d-flex justify-end">
        <DefaultButton
          :type="ButtonTypes.SUBMIT"
          :disabled="isSubmitButtonDisabled"
          :loading="buttonLoading"
        >
          {{ t('buttons.subscribe') }}
        </DefaultButton>
      </div>
    </v-form>
  </div>
  <Dialog
    ref="successfulSubscriptionDialogRef"
    @on-close="successfulSubscriptionDialogRef?.close()"
  >
    <DialogNewsletterSucess @submit="successfulSubscriptionDialogRef?.close" />
  </Dialog>
</template>
<script setup lang="ts">
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import DefaultCheckbox from '@/components/baseComponents/checkboxes/DefaultCheckbox.vue'
import DefaultSelect from '@/components/baseComponents/inputs/DefaultSelect.vue'
import DefaultTextField from '@/components/baseComponents/inputs/DefaultTextField.vue'
import DialogNewsletterSucess from '@/components/dialogs/DialogNewsletterSucess.vue'
import Dialog from '@/components/layout/Dialog.vue'
import { ButtonTypes } from '@/models/enums/components/Buttons'
import { Languages } from '@/models/enums/Languages'
import { useNewsletterStore } from '@/store/newsletter'
import { isEmail, isMaxLength, isNotEmpty } from '@/validators'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const languages = [
  {
    title: t('xs2aNewsletter.languages.german'),
    value: Languages.DE,
  },
  {
    title: t('xs2aNewsletter.languages.english'),
    value: Languages.EN,
  },
]

const newsletterStore = useNewsletterStore()

const newsletterSubscriptionForm = ref(false)
const newsletterSubscriptionFormRef = ref<HTMLFormElement>()
const acceptedPrivacy = ref(false)
const successfulSubscriptionDialogRef = ref<InstanceType<typeof Dialog>>()
const buttonLoading = ref(false)
const newsletterRequestForm = ref({
  email: '',
  generalNewsletter: false,
  language: Languages.DE,
  tppNewsletter: false,
})

const tppInfos = ref({
  tppNewsletterId: undefined,
  tppNewsletterName: undefined,
})

const emailRules = computed(() => [
  (v: string): boolean | string => isNotEmpty(v),
  (v: string): boolean | string => isEmail(v),
  (v: string): boolean | string => isMaxLength(v, 255),
])
const notEmptyRules = computed(() => [(v: string): boolean | string => isNotEmpty(v)])
const checkboxRules = computed(() => [(v: boolean): boolean | string => v || t('xs2aNewsletter.mandatory.info')])

const isSubmitButtonDisabled = computed(
  () => !(newsletterRequestForm.value.generalNewsletter || newsletterRequestForm.value.tppNewsletter) || !newsletterSubscriptionForm.value
)

async function sendNewsletterRequest(): Promise<void> {
  try {
    buttonLoading.value = true
    let newsletterRequest = newsletterRequestForm.value
    if (newsletterRequestForm.value.tppNewsletter) {
      newsletterRequest = { ...newsletterRequestForm.value, ...tppInfos.value }
    }
    await newsletterStore.PUT_SUBSCRIPTION(newsletterRequest)
    successfulSubscriptionDialogRef.value?.open()
    newsletterSubscriptionFormRef.value?.reset()
  } catch {
    Promise.resolve()
  } finally {
    buttonLoading.value = false
  }
}
</script>
<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(1.5rem);
  opacity: 0;
}
</style>
<i18n lang="yaml">
de:
  xs2aNewsletter:
    headline: Newsletter abonnieren
    label:
      email: E-Mail-Adresse*
      lang: Newslettersprache wählen
      tppId: TPP-ID gemäß Zertifikat*
      tppName: TPP-Name*
    checkbox:
      general: Allgemeiner Newsletter – Ich willige ein, dass mich die Finanz Informatik GmbH & Co. KG per E-Mail über XS2A-Produkte informiert. Meine Daten
        werden ausschließlich zu diesem Zweck genutzt. Eine Weitergabe an Dritte erfolgt nicht. Ich kann die Einwilligung jederzeit widerrufen.
      tpp: TPP Newsletter – Neben unserem allgemeinen Newsletter können Sie sich auch mit unserem TPP Newsletter zusätzlich über aktuelle Updates informieren
        lassen.
      acceptTerms: Ich habe die Datenschutzerklärung gelesen und nehme sie an. Mit dem Absenden des Kontaktformulars erklären Sie sich damit einverstanden,
        dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden.
      privacy:
        text: Weitere Informationen und Widerrufshinweise finden Sie in der
        link: Datenschutzerklärung
    languages:
      german: Deutsch
      english: Englisch
    mandatory:
      info: Bitte akzeptiere die Datenschutzerklärung.
</i18n>
