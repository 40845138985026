<template>
  <div class="credential-container">
    <DialogSplitLayout>
      <template #content-left>
        <div class="d-flex flex-column step-wrapper">
          <div class="step-item text-center">
            <v-icon
              size="48"
              color="red"
              class="mb-2"
              >{{ !isOnlyManuelAccessDefinition ? Icons.CODE : Icons.MESSAGE }}</v-icon
            >
            <h2>{{ t('redirectCredentials.step1.headline') }}</h2>
            <p v-if="!isOnlyManuelAccessDefinition">
              {{ t('redirectCredentials.step1.copy') }}
            </p>
            <p v-else>
              {{ t('redirectCredentials.step1.copyManuel') }}
            </p>
          </div>

          <div class="step-item text-center">
            <v-icon
              size="48"
              color="red"
              class="mb-2"
              >{{ Icons.ROCKET }}</v-icon
            >
            <h2>{{ t('redirectCredentials.step2.headline') }}</h2>
            <p v-if="!isOnlyManuelAccessDefinition">{{ t('redirectCredentials.step2.copy') }}</p>
            <p v-else>{{ t('redirectCredentials.step2.copyManuel') }}</p>
          </div>
        </div>
      </template>
      <template #content-right>
        <div class="access-container">
          <h1>{{ t('redirectCredentials.headline') }}</h1>
          <template v-if="!isOnlyManuelAccessDefinition">
            <p>{{ t('redirectCredentials.copy') }}</p>
            <v-list>
              <template
                v-for="access in generateTokenAccesses"
                :key="access.id"
              >
                <v-list-item class="access-info px-0 mb-8">
                  <template #prepend>
                    <DefaultAvatar
                      size="32"
                      :color="getStatusSetFromStatusKey(getMostRecentAccessStatus(access.accessStatus)?.statusValue)?.color"
                    >
                      <v-icon size="20">
                        {{ getStatusSetFromStatusKey(getMostRecentAccessStatus(access.accessStatus)?.statusValue)?.icon }}
                      </v-icon>
                    </DefaultAvatar>
                  </template>
                  <template #title>{{ access.name }}</template>
                  <template #append>
                    <DefaultTag :variant="TagVariants.READ_ONLY">{{ t('redirectCredentials.tokenGen') }}</DefaultTag>
                  </template>
                </v-list-item>
                <DefaultTextField
                  :label="t('redirectCredentials.label')"
                  readonly
                  :model-value="access.authenticator.tokenHash"
                >
                  <template #append-inner>
                    <CopyToClipBtn :copy-value="access.authenticator.tokenHash" />
                  </template>
                </DefaultTextField>
              </template>
            </v-list>
          </template>

          <v-list>
            <v-list-item
              class="manual-info px-0"
              lines="two"
            >
              <template #prepend>
                <DefaultAvatar
                  size="32"
                  :color="PlattformColors.INFO"
                >
                  <v-icon size="20">
                    {{ Icons.CIRCLE_INFORMATION }}
                  </v-icon>
                </DefaultAvatar>
              </template>
              <template #subtitle>{{ t('redirectCredentials.manualInfo') }}</template>
            </v-list-item>
          </v-list>
        </div>
      </template>
      <template #buttons>
        <DefaultButton
          :color="PlattformColors.PRIMARY"
          @click="emit('done')"
          >{{ t('buttons.done') }}</DefaultButton
        >
      </template>
    </DialogSplitLayout>
  </div>
</template>

<script lang="ts" setup>
import DialogSplitLayout from './layout/DialogSplitLayout.vue'
import { AccessResponse, AccessResponseAccessToken } from '@/models/Access'
import { AccessExchangeTypes } from '@/models/enums/AccessDefinitionTypes'
import { computed, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Icons } from '@/models/enums/IconTypes'
import { useAccessHelper } from '@/composables/useAccessHelper'
import CopyToClipBtn from '@/components/CopyToClipBtn.vue'
import { useStatusHelper } from '@/composables/useStatusHelper'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import DefaultTag from '@/components/baseComponents/tags/DefaultTag.vue'
import { TagVariants } from '@/models/enums/components/Tags'
import DefaultTextField from '@/components/baseComponents/inputs/DefaultTextField.vue'

const { t } = useI18n()

const emit = defineEmits(['done'])

const props = defineProps({
  accesses: { type: Array<AccessResponse>, default: () => [] },
})

const { getMostRecentAccessStatus } = useAccessHelper()
const { getStatusSetFromStatusKey } = useStatusHelper()

const generateTokenAccesses = computed(() => {
  const accessesFiltered = props.accesses.filter((access) => access.accessExchangeType === AccessExchangeTypes.GENERATE_TOKEN)
  return accessesFiltered as AccessResponseAccessToken[]
})

const isOnlyManuelAccessDefinition = computed(() => !generateTokenAccesses.value.length)

/**
 * handleKeyPressed
 * @param {event} event
 */
function handleKeyPressed(event: KeyboardEvent): void {
  if (event.key == 'Enter') {
    emit('done')
  }
}
onMounted(() => {
  // if there are TokenAccesses the dialog has to be closed with a button click
  if (isOnlyManuelAccessDefinition.value) {
    document.addEventListener('keyup', handleKeyPressed)
  }
})
onUnmounted(() => {
  if (isOnlyManuelAccessDefinition.value) {
    document.removeEventListener('keyup', handleKeyPressed)
  }
})
</script>

<style lang="scss" scoped>
.credential-container {
  .step-item {
    margin-bottom: 3.5rem;

    h2 {
      margin-bottom: 0;
    }
  }

  .access-container {
    h1 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 2rem;
    }
  }
}
</style>

<i18n lang="yaml">
de:
  redirectCredentials:
    headline: Zugänge einrichten
    copy: Du hast die Anfrage für die App genehmigt. Nur noch wenige Schritte und dein Kunde kann loslegen.
    label: Hash-Token
    tokenGen: Access Token Generierung
    manualInfo: Du nutzt die Methode »Manuelle Übergabe«? Stelle sicher, dass dein Kunde seine Zugangsdaten erhält.
    step1:
      headline: Schritt 1
      copy: Hinterlege den Hash-Token in deinem Code.
      copyManuel: Setze dich mit deinem Kunden in Verbindung.
    step2:
      headline: Schritt 2
      copy: Informiere deinen Kunden, sobald du den Access Key aktiviert hast. Setze hierfür den Status des Access Keys über das Kontext-Menü.
      copyManuel: Übergib deinem Kunden seine Zugangsdaten.
</i18n>
