<template>
  <v-alert
    :color="backgroundcolor"
    class="pa-6"
    rounded="0"
  >
    <div class="d-flex align-center">
      <DefaultAvatar
        :color="getStatusSetFromStatusKey(infoType)?.color"
        size="48"
        class="mr-6"
        ><v-icon
          :icon="getStatusSetFromStatusKey(infoType)?.icon"
          :color="iconColor"
      /></DefaultAvatar>
      <div>
        <p class="mb-1 highlight">{{ headline }}</p>
        <p class="mb-0 sm">{{ copy }}</p>
      </div>
    </div>
  </v-alert>
</template>
<script lang="ts" setup>
import { useStatusHelper } from '@/composables/useStatusHelper'
import { StatusTypes } from '@/models/enums/StatusTypes'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PropType } from 'vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { getStatusSetFromStatusKey } = useStatusHelper()

defineProps({
  headline: { type: String, default: undefined },
  copy: { type: String, default: undefined },
  infoType: { type: String as PropType<StatusTypes>, default: StatusTypes.INFO },
  iconColor: { type: String as PropType<PlattformColors>, default: undefined },
  backgroundcolor: { type: String, default: PlattformColors.INFO },
})
</script>
