<template>
  <DefaultList>
    <ListElement
      v-for="organizationRelation in organizationRelations"
      :key="organizationRelation[organizationContext].id"
      class="bg-white"
      clickable
      @click="emit('handleClick', organizationRelation[organizationContext].id)"
    >
      <template #prepend>
        <OrganizationLogo :organization-logo="organizationRelation[organizationContext].logo" />
      </template>
      <template #title>{{ organizationRelation[organizationContext].name }} </template>
      <template
        v-if="
          organizationRelation.status === StatusTypes.INACTIVE ||
          organizationRelation.status === StatusTypes.INITIAL ||
          organizationRelation.status === StatusTypes.IN_TERMINATION
        "
        #append
      >
        <DefaultTag :variant="TagVariants.READ_ONLY">{{ t(`organizationRelationStatus.${organizationRelation.status}`) }}</DefaultTag>
      </template>
    </ListElement>
  </DefaultList>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { OrganizationRelationResponse } from '@/models/Organization'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { TagVariants } from '@/models/enums/components/Tags'
import DefaultTag from '@/components/baseComponents/tags/DefaultTag.vue'
import OrganizationLogo from '@/components/organizations/OrganizationLogo.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import ListElement from '@/components/baseComponents/lists/ListElement.vue'

const { t } = useI18n()
const emit = defineEmits(['handleClick'])

defineProps({
  organizationRelations: { type: Array<OrganizationRelationResponse>, required: true },
  organizationContext: { type: String as PropType<OrganizationTypes>, required: true },
})
</script>
