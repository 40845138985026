<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('platformInformation.headline') }}
      <PlainTooltip>
        <template #activator="{ props }">
          <v-icon
            v-bind="props"
            :color="PlattformColors.CHANGE"
            class="ml-2"
            >{{ Icons.CIRCLE_INFORMATION }}</v-icon
          >
        </template>
        <template #default>
          {{ t('platformInformation.tooltip') }}
        </template>
      </PlainTooltip>
    </template>
  </GeneralPageTitle>
  <v-row>
    <v-col>
      <v-card
        class="h-100"
        variant="flat"
      >
        <template #prepend>
          <DefaultAvatar
            class="mr-4"
            color="grey-lighten-4"
          >
            <v-icon :size="20">{{ Icons.GLOBE }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title
          ><h4 class="mt-4">{{ t('platformInformation.domain.title') }}</h4></template
        >
        <template #text>
          <div class="h-100">
            <p class="sm mb-6">{{ t('platformInformation.domain.copy') }}</p>

            <DefaultTextField
              :label="t('platformInformation.domain.input.label')"
              readonly
              hide-details
              :model-value="activeTenant?.subdomainName || t('platformInformation.domain.exampleValue')"
            >
              <template #append-inner>
                <CopyToClipBtn :copy-value="activeTenant?.subdomainName || t('platformInformation.domain.exampleValue')" />
              </template>
            </DefaultTextField>
            <p class="xs">{{ t('platformInformation.domain.example') }}</p>
          </div>
        </template>
      </v-card>
    </v-col>
    <v-col>
      <v-card
        class="h-100"
        variant="flat"
      >
        <template #prepend>
          <DefaultAvatar
            class="mr-4"
            color="grey-lighten-4"
          >
            <v-icon :size="20">{{ Icons.BOLD }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title
          ><h4 class="mt-4">{{ t('platformInformation.design.title') }}</h4></template
        >
        <template #text>
          <p class="sm mb-6">{{ t('platformInformation.design.copy') }}</p>

          <DefaultTextField
            class="mb-6"
            :label="t('platformInformation.design.input.label')"
            readonly
            hide-details
            :model-value="activeTenant?.theming.primaryColor"
          >
            <template #append-inner>
              <v-avatar
                size="32"
                :color="activeTenant?.theming.primaryColor"
              >
              </v-avatar>
            </template>
          </DefaultTextField>

          <div class="d-flex justify-space-between">
            <PreviewImage
              class="mr-4"
              :title="t('platformInformation.design.previewLogo.title')"
              :image-src="activeTenant?.theming.logo"
              :background-color="PlattformColors.PRIMARY"
            />
            <PreviewImage
              :title="t('platformInformation.design.previewFavicon.title')"
              :image-src="activeTenant?.theming.favicon"
            />
          </div>
        </template>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="6">
      <v-card
        class="h-100"
        variant="flat"
      >
        <template #prepend>
          <DefaultAvatar
            class="mr-4"
            color="grey-lighten-4"
          >
            <v-icon :size="20">{{ Icons.EMAIL }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title
          ><h4 class="mt-4">{{ t('platformInformation.email.title') }}</h4></template
        >
        <template #text>
          <p class="sm mb-6">{{ t('platformInformation.email.copy') }}</p>

          <template v-if="activeTenant">
            <p class="sm mb-6 font-weight-bold">
              {{ t('platformInformation.email.subline.sender') }}
            </p>
            <DefaultTextField
              class="mb-6"
              :label="t('platformInformation.email.label.name')"
              readonly
              hide-details
              :model-value="activeTenant.emailSettings.fromDisplayName"
            />
            <DefaultTextField
              class="mb-6"
              :label="t('platformInformation.email.label.email')"
              readonly
              hide-details
              :model-value="activeTenant.emailSettings.fromAddress"
            />
          </template>

          <template v-if="activeTenant?.emailSettings.replyToDisplayName || activeTenant?.emailSettings.replyToAddress">
            <p class="sm mb-6 font-weight-bold">
              {{ t('platformInformation.email.subline.answer') }}
            </p>
            <DefaultTextField
              class="mb-6"
              :label="t('platformInformation.email.label.name')"
              readonly
              hide-details
              :model-value="activeTenant.emailSettings.replyToDisplayName"
            />
            <DefaultTextField
              :label="t('platformInformation.email.label.email')"
              readonly
              hide-details
              :model-value="activeTenant.emailSettings.replyToAddress"
            />
          </template>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { Icons } from '@/models/enums/IconTypes'
import { PlattformColors } from '@/models/enums/ColorSets'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import CopyToClipBtn from '@/components/CopyToClipBtn.vue'
import PreviewImage from '@/components/baseComponents/images/PreviewImage.vue'
import { useTenantStore } from '@/store/tenants'
import DefaultTextField from '@/components/baseComponents/inputs/DefaultTextField.vue'
import PlainTooltip from '@/components/baseComponents/tooltips/PlainTooltip.vue'

const tenantStore = useTenantStore()
const { t } = useI18n()
const { activeTenant } = storeToRefs(tenantStore)
</script>
<i18n lang="yaml">
de:
  platformInformation:
    tooltip: Diese Informationen sind nur zur Ansicht da.
    headline: Plattforminformationen
    domain:
      title: Subdomain
      copy: Damit dein Developer Portal unter deiner eigenen Domain erreichbar ist, muss der Administrator deiner Domain ein sogenannter CNAME Record in den
        DNS-Einstellungen für die Domain eintragen
      input:
        label: Subdomain
      example: 'Beispiel CNAME Record: "[mandant-name-slug].console.wallis.de"'
      exampleValue: '[mandant-name-slug].console.wallis.de'
    design:
      title: Design
      copy: Das Aussehen deines Developer Portal wurde zusammen mit dir bei der Einrichtung festgelegt. Folgende Parameter können über den Support der
        Wallis GmbH geändert werden.
      input:
        label: Main Color
      previewLogo:
        title: Logo
      previewFavicon:
        title: Favicon
    email:
      title: System-Email
      copy: Folgende Parameter wurden für System-Emails des Developer Portals festgelegt. Hierfür wird der Email-Server des Developer Portals verwendet.
        Bitte stelle sicher, dass die angegebenen Email-Adressen gültig sind.
      subline:
        sender: 'Absender:'
        answer: 'Antwort an:'
      label:
        name: Name
        email: Email
</i18n>
