<template>
  <div class="content-container">
    <div
      v-if="swaggerFile"
      id="swagger-ui-container"
    ></div>
    <p
      v-else
      class="mb-0"
    >
      {{ t('noOpenApiFileAvailable') }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import SwaggerUI from 'swagger-ui'
import YAML from 'yaml'
import 'swagger-ui/dist/swagger-ui.css'
import { useFilesStore } from '@/store/files'
import { FilesAndFolderNodeTypes } from '@/models/enums/FilesAndFolderTypes'
import { ProductDocumentationFolderResponse } from '@/models/Folder'
import { ProductDocumentationFileResponse } from '@/models/File'

const { t } = useI18n()
const route = useRoute()
const filesStore = useFilesStore()

const { filesAndFolders } = storeToRefs(filesStore)

const swaggerFile = ref<ProductDocumentationFileResponse>()
const productId = route.params.productId as string

try {
  await filesStore.GET_PRODUCT_DOCUMENTATION_STRUCTURE(productId)

  const openApiFolder = filesAndFolders.value.find((folder) => {
    return folder.nodeType === FilesAndFolderNodeTypes.API
  })

  swaggerFile.value = (openApiFolder as ProductDocumentationFolderResponse)?.files.find((file) => file.active)
  if (swaggerFile.value) {
    const response = await filesStore.GET_PRODUCT_DOCUMENTATION_FILE_BY_ID(swaggerFile.value.id)
    const blob = new Blob([response], { type: response.type })
    const fileType = swaggerFile.value.name.split('.').pop()?.toLowerCase()
    let content: { [propName: string]: unknown } | undefined
    if (fileType === 'json') {
      content = JSON.parse(await blob.text())
    } else if (fileType === 'yaml' || fileType === 'yml') {
      content = YAML.parse(await blob.text())
    }
    onMounted(() => {
      SwaggerUI({
        spec: content,
        dom_id: '#swagger-ui-container',
      })
    })
  }
} catch {
  Promise.resolve()
}
</script>
<i18n lang="yaml">
de:
  noOpenApiFileAvailable: Es wurde keine OpenAPI-Datei gefunden.
</i18n>
