<template>
  <v-app-bar
    flat
    height="96"
    class="bg-secondary"
    :absolute="true"
  >
    <v-container class="d-flex justify-space-between">
      <div>
        <BackButton />
      </div>
      <div class="d-flex">
        <v-menu
          transition="slide-y-transition"
          location="bottom center"
          offset="12"
        >
          <template #activator="{ props }">
            <IconButton
              v-bind="props"
              :icon="Icons.SUPPORT_QUESTION"
              :data-cy="NavBarTest.HELP_MENU"
            />
          </template>
          <v-list
            density="compact"
            class="px-2 pb-4"
          >
            <v-list-item
              min-height="48"
              :href="getDocsURL()"
              target="_blank"
              :data-cy="NavBarTest.DOCS_LINK"
            >
              <template #prepend>
                <v-icon size="20">{{ Icons.EXTERNAL_LINK }}</v-icon>
              </template>
              <template #title>
                <p class="mb-0 font-weight-bold">{{ t('navbar.contextMenu.docs') }}</p>
              </template>
            </v-list-item>
            <v-list-item
              v-if="resolvedTenant?.legalInformation.supportUrl"
              min-height="48"
              :href="resolvedTenant.legalInformation.supportUrl"
              target="_blank"
              :data-cy="NavBarTest.SUPPORT_LINK"
            >
              <template #prepend>
                <v-icon size="20">{{ Icons.EMAIL }}</v-icon>
              </template>
              <template #title>
                <p class="mb-0 font-weight-bold">{{ t('navbar.contextMenu.support') }}</p>
              </template>
            </v-list-item>
            <v-divider class="my-2" />
            <v-list-subheader>{{ t('navbar.contextMenu.subHeader') }}</v-list-subheader>
            <v-list-item
              min-height="32"
              :href="resolvedTenant?.legalInformation.imprintUrl"
              target="_blank"
              :data-cy="NavBarTest.IMPRINT_LINK"
            >
              <template #title>
                <p class="mb-0 sm">{{ t('navbar.contextMenu.imprint') }}</p>
              </template>
            </v-list-item>
            <v-list-item
              min-height="32"
              :href="resolvedTenant?.legalInformation.privacyPolicyUrl"
              target="_blank"
              :data-cy="NavBarTest.PRIVACY_LINK"
            >
              <template #title>
                <p class="mb-0 sm">{{ t('navbar.contextMenu.privacy') }}</p>
              </template>
            </v-list-item>
            <v-list-item
              min-height="32"
              :href="resolvedTenant?.legalInformation.termsOfUseUrl"
              target="_blank"
              :data-cy="NavBarTest.TERMS_LINK"
            >
              <template #title>
                <p class="mb-0 sm">{{ t('navbar.contextMenu.termsOfUse') }}</p>
              </template>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu
          v-if="isLoggedIn()"
          transition="slide-y-transition"
          location="bottom center"
          offset="12"
        >
          <template #activator="{ props }">
            <DefaultButton
              v-bind="props"
              class="align-self-center"
              :variant="ButtonVariants.PLAIN"
              :color="PlattformColors.TERTIERY"
              :prepend-icon="Icons.USER"
              :data-cy="NavBarTest.USER_MENU"
            >
              {{ fullName }}
            </DefaultButton>
          </template>
          <v-list min-width="225">
            <div class="text-center mt-3">
              <DefaultAvatar
                size="48"
                :color="PlattformColors.INFO"
                class="mb-2"
              >
                <v-icon>{{ Icons.USER }}</v-icon>
              </DefaultAvatar>
              <p class="sm px-4">{{ activeUser?.email }}</p>
            </div>
            <v-divider class="mx-2" />
            <v-list-item
              :to="{ name: Routes.PROFILE_SETTINGS }"
              router
              exact
              flat
            >
              <template #prepend>
                <v-icon>{{ Icons.SETTINGS }}</v-icon>
              </template>
              <v-list-item-title>{{ t('navbar.profileMenu.settings') }}</v-list-item-title>
            </v-list-item>
            <v-divider class="mx-2" />
            <v-list-item
              link
              @click="logoutFromKeycloak()"
            >
              <template #prepend>
                <v-icon>{{ Icons.LOG_OUT }}</v-icon>
              </template>
              <v-list-item-title>{{ t('navbar.profileMenu.logoutBtn') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <DefaultButton
          v-else
          class="align-self-center"
          :color="PlattformColors.LIGHT"
          :prepend-icon="Icons.USER"
          :data-cy="NavBarTest.LOG_IN"
          @click="loginToKeycloak()"
        >
          {{ t('navbar.login') }}
        </DefaultButton>

        <v-app-bar-nav-icon
          class="d-flex d-md-none nav-bar-button align-self-center mr-4"
          color="primary"
          @click.stop="navigationStore.toggleDrawer()"
        />
      </div>
    </v-container>
  </v-app-bar>
</template>

<script lang="ts" setup>
import { isLoggedIn, loginToKeycloak, logoutFromKeycloak } from '@/plugins/keycloak'
import { useNavigationStore } from '@/store/navigations'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useActiveUserStore } from '@/store/activeUser'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import BackButton from '@/components/layout/BackButton.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { ButtonVariants } from '@/models/enums/components/Buttons'
import { useTenantStore } from '@/store/tenants'
import { NavBarTest } from '@/models/enums/test/NavBarTest'
import { useRouterHelper } from '@/composables/useRouterHelper'

const { t } = useI18n()

const activeUserStore = useActiveUserStore()
const navigationStore = useNavigationStore()
const tenantStore = useTenantStore()

const { fullName, activeUser } = storeToRefs(activeUserStore)
const { resolvedTenant } = storeToRefs(tenantStore)

const { getDocsURL } = useRouterHelper()
</script>

<i18n lang="yaml">
de:
  navbar:
    login: Login
    profileMenu:
      settings: Profileinstellungen
      logoutBtn: Abmelden
    contextMenu:
      docs: Dokumentation
      support: Support
      subHeader: Rechtliches
      privacy: Datenschutz
      imprint: Impressum
      termsOfUse: Nutzungsbedingungen
</i18n>
