<template>
  <CenteredTeaser>
    <template #title>
      <span class="d-block px-12">{{ t('consumerMarketplaceTeaser.headline') }}</span>
    </template>
    <template #text>
      <DefaultAvatar
        size="120"
        class="mx-auto"
      >
        <v-icon
          color="primary"
          size="60"
          >{{ Icons.PRODUCT }}</v-icon
        >
      </DefaultAvatar>
    </template>
    <template #actions>
      <DefaultButton
        :prepend-icon="Icons.SEARCH"
        :to="{ name: Routes.CONSUMER_PRODUCTS }"
        :variant="ButtonVariants.PLAIN"
        :color="PlattformColors.TERTIERY"
        >{{ t('consumerMarketplaceTeaser.button') }}</DefaultButton
      >
    </template>
  </CenteredTeaser>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import CenteredTeaser from '@/components/baseComponents/teasers/CenteredTeaser.vue'
import { ButtonVariants } from '@/models/enums/components/Buttons'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'

const { t } = useI18n()
</script>
<i18n lang="yaml">
de:
  consumerMarketplaceTeaser:
    headline: Passende API-Produkte finden und aktivieren
    button: Zu den API-Produkten
</i18n>
