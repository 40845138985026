<template>
  <div>
    <DialogDefaultLayoutEnter :on-submit="() => handleSubmit()">
      <template #content>
        <h1>{{ t('deactivateCredentialNote.headline') }}</h1>
        <p>{{ t('deactivateCredentialNote.copy') }}</p>

        <v-form v-model="validateForm">
          <DefaultTextarea
            v-model="deactivationNote"
            :label="t('deactivateCredentialNote.label')"
            required
            :rules="maxLength"
          />
        </v-form>
        <InfoBox
          class="my-8"
          :copy="t('deactivateCredentialNote.info')"
        />
      </template>

      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :disabled="!validateForm"
          :loading="loading"
          @click="handleSubmit()"
        >
          {{ t('buttons.send') }}</DefaultButton
        >

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
          >{{ t('buttons.cancel') }}</DefaultButton
        >
      </template>
    </DialogDefaultLayoutEnter>
  </div>
</template>

<script lang="ts" setup>
import { AccessResponse } from '@/models/Access'
import { isNotEmpty, isMaxLength } from '@/validators'
import { PropType, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import InfoBox from '../InfoBox.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import DefaultTextarea from '@/components/baseComponents/inputs/DefaultTextarea.vue'

const props = defineProps({
  selectedAccess: { type: Object as PropType<AccessResponse>, default: () => {} },
})

const { t } = useI18n()

const deactivationNote = ref<string>()
const validateForm = ref(false)
const loading = ref(false)

const maxLength = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 600)])

/**
 * handleSubmit
 */
function handleSubmit(): void {
  emit('submit', props.selectedAccess, deactivationNote.value)
  loading.value = true
}
const emit = defineEmits(['submit', 'cancel'])
</script>
<style lang="scss" scoped></style>

<i18n lang="yaml">
de:
  deactivateCredentialNote:
    headline: Authentifizierungsmittel deaktivieren
    copy: Gib deinem Kunden eine kurze Begründung, warum das Authentifizierungsmittel deaktiviert wurde.
    label: Deaktivierungsgrund*
    info: Du kannst die Sperrung jederzeit aufheben.
</i18n>
