<template>
  <DefaultTextField
    v-if="searchbarVariant === SearchbarVariants.LARGE"
    v-model="searchBarValues.searchQuery"
    class="mb-8"
    :label="label"
    hide-details
    clearable
    :data-test="SearchbarTest.SEARCHBAR_L"
    @update:model-value="delayedEmitUpdatedValues()"
  >
    <template #prepend-inner>
      <v-icon class="mx-1">{{ Icons.SEARCH }}</v-icon>
    </template>
  </DefaultTextField>

  <ListControls :data-test="SearchbarTest.LIST_CONTROLS">
    <template #title>{{ resultLabel }}</template>
    <template #append>
      <v-progress-circular
        v-if="loading"
        class="mr-4"
        indeterminate
        :data-test="SearchbarTest.LOADING"
      />
      <DefaultTextField
        v-if="searchbarVariant === SearchbarVariants.SMALL"
        v-model="searchBarValues.searchQuery"
        class="mb-0 mr-4"
        :label="label"
        hide-details
        clearable
        variant="outlined"
        :density="InputDensity.COMFORTABLE"
        width="240"
        :data-test="SearchbarTest.SEARCHBAR_S"
        @update:model-value="delayedEmitUpdatedValues()"
      >
        <template #prepend-inner>
          <v-icon class="mx-1">{{ Icons.SEARCH }}</v-icon>
        </template>
      </DefaultTextField>

      <DefaultFilter
        v-if="filterOptions"
        v-model="searchBarValues.selectedFilterOptionValues"
        class="mb-0 mr-4"
        :filter-options="filterOptions"
        :preset-filter="searchBarValues.selectedFilterOptionValues"
        :data-test="SearchbarTest.FILTER"
        @update:model-value="emitUpdatedValues()"
      />

      <DefaultSelect
        v-if="sortOptions"
        v-model="searchBarValues.selectedSortOptionValue"
        class="mb-0"
        :items="sortOptions"
        item-title="key"
        item-value="value"
        :label="t('searchbar.sortbarLabel')"
        hide-details
        :density="InputDensity.COMFORTABLE"
        width="212"
        :data-test="SearchbarTest.SORT"
        @update:model-value="emitUpdatedValues()"
      >
        <template #prepend-inner>
          <v-icon class="mx-1">{{ Icons.SORT }}</v-icon>
        </template>
      </DefaultSelect>
      <slot name="append"></slot>
    </template>
  </ListControls>
</template>
<script lang="ts" setup>
import ListControls from '@/components/ListControls.vue'
import { SortOption } from '@/models/SortOption'
import { FilterOption } from '@/models/FilterOption'
import { PropType, computed, ref } from 'vue'
import { useSearchbarStore } from '@/store/searchbar'
import { useRoute } from 'vue-router'
import { SearchbarValues } from '@/models/SearchbarValues'
import { Icons } from '@/models/enums/IconTypes'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { SearchbarTest } from '@/models/enums/test/SearchbarTest'
import { SearchbarVariants } from '@/models/enums/SearchbarVariants'
import { InputDensity } from '@/models/enums/components/Inputs'
import DefaultFilter from './baseComponents/inputs/DefaultFilter.vue'
import DefaultTextField from './baseComponents/inputs/DefaultTextField.vue'
import DefaultSelect from './baseComponents/inputs/DefaultSelect.vue'

const componentProps = defineProps({
  presetOptions: { type: Object as PropType<SearchbarValues>, default: undefined },
  searchbarVariant: { type: String as PropType<SearchbarVariants>, default: SearchbarVariants.SMALL },
  searchbarLabel: { type: String, default: undefined },
  loading: { type: Boolean, default: false },
  resultLabel: { type: String, default: undefined },
  sortOptions: { type: Array<SortOption>, required: true },
  filterOptions: { type: Array<FilterOption>, default: undefined },
})

const emit = defineEmits(['updatedFilter'])

const { t } = useI18n()
const route = useRoute()
const searchbarStore = useSearchbarStore()

const { getMatchedSearchbarValues } = storeToRefs(searchbarStore)

const label = computed(() => componentProps.searchbarLabel || t('searchbar.searchbarLabel'))

const preset = componentProps.presetOptions
  ? {
      searchbarRoute: route.path,
      searchQuery: componentProps.presetOptions?.searchQuery,
      selectedFilterOptionValues: componentProps.presetOptions?.selectedFilterOptionValues,
      selectedSortOptionValue: componentProps.presetOptions?.selectedSortOptionValue || componentProps.sortOptions[0].value,
    }
  : getMatchedSearchbarValues.value(route.path)

const searchBarValues = ref<SearchbarValues>(preset || { searchbarRoute: route.path, selectedSortOptionValue: componentProps.sortOptions[0].value })

let timer: ReturnType<typeof setTimeout>
function delayedEmitUpdatedValues(): void {
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(() => {
    emitUpdatedValues()
  }, 500)
}

function emitUpdatedValues(): void {
  searchbarStore.setSearchbarValues(searchBarValues.value)
  emit('updatedFilter', searchBarValues.value)
}

emit('updatedFilter', searchBarValues.value)
</script>
<i18n lang="yaml">
de:
  searchbar:
    searchbarLabel: Suche
    filterbarLabel: Filter
    sortbarLabel: Sortierung
</i18n>
