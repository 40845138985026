<template>
  <v-chip
    v-if="variant !== TagVariants.EMPTY"
    label
    :close-icon="closeIcon"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    :closable="closable"
    :variant="variant !== TagVariants.READ_ONLY ? 'tonal' : 'outlined'"
    size="small"
    :color="PlattformColors.TERTIERY"
    v-on="
      variant === TagVariants.DEFAULT
        ? {
            click: (event: Event) => {
              if (event) event.stopPropagation()
              emit('click')
            },
          }
        : {}
    "
  >
    <slot></slot>
  </v-chip>
  <v-chip
    v-else
    class="empty-tag"
    label
    :close-icon="closeIcon"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    :closable="closable"
    variant="text"
    size="small"
    :color="PlattformColors.TERTIERY"
  >
    <slot></slot>
  </v-chip>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'
import { Icons } from '@/models/enums/IconTypes'
import { TagVariants } from '@/models/enums/components/Tags'
import { PlattformColors } from '@/models/enums/ColorSets'

defineProps({
  closeIcon: { type: String as PropType<Icons>, default: undefined },
  closable: { type: Boolean, default: false },
  appendIcon: { type: String as PropType<Icons>, default: undefined },
  prependIcon: { type: String as PropType<Icons>, default: undefined },
  variant: { type: String as PropType<TagVariants>, default: TagVariants.DEFAULT },
})

const emit = defineEmits(['click'])
</script>
<style lang="scss" scoped>
.empty-tag {
  background-image:
    repeating-linear-gradient(
      -2deg,
      rgb(var(--v-theme-light-grey)),
      rgb(var(--v-theme-light-grey)) 0.25rem,
      transparent 0.25rem,
      transparent 0.5rem,
      rgb(var(--v-theme-light-grey)) 0.5rem
    ),
    repeating-linear-gradient(
      88deg,
      rgb(var(--v-theme-light-grey)),
      rgb(var(--v-theme-light-grey)) 0.25rem,
      transparent 0.25rem,
      transparent 0.5rem,
      rgb(var(--v-theme-light-grey)) 0.5rem
    ),
    repeating-linear-gradient(
      178deg,
      rgb(var(--v-theme-light-grey)),
      rgb(var(--v-theme-light-grey)) 0.25rem,
      transparent 0.25rem,
      transparent 0.5rem,
      rgb(var(--v-theme-light-grey)) 0.5rem
    ),
    repeating-linear-gradient(
      268deg,
      rgb(var(--v-theme-light-grey)),
      rgb(var(--v-theme-light-grey)) 0.25rem,
      transparent 0.25rem,
      transparent 0.5rem,
      rgb(var(--v-theme-light-grey)) 0.5rem
    );
  background-size:
    0.0625rem 100%,
    100% 0.0625rem,
    0.0625rem 100%,
    100% 0.0625rem;
  background-position:
    0 0,
    0 0,
    100% 0,
    0 100%;
  background-repeat: no-repeat;
}
</style>
