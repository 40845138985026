import { defineStore } from 'pinia'
import { StoreName } from '@/models/enums/StoreName'
import useAxios from '@/plugins/axios'
import { WallisXs2aSubscriptionRequest } from '@/models/XS2ASubscription'

export const useNewsletterStore = defineStore(StoreName.NEWSLETTER, {
  state: () => ({}),
  actions: {
    async PUT_SUBSCRIPTION(subscriptionRequest: WallisXs2aSubscriptionRequest) {
      try {
        await useAxios.put('/api/newsletter/xs2a', subscriptionRequest)
      } catch {
        return Promise.reject()
      }
    },
  },
})
