<template>
  <LinkTeaser
    :link="getDocsURL()"
    :link-type="LinkTypes.EXTERNAL"
  >
    <template #title>
      <p class="mb-0 highlight">{{ t('docsTeaser.headline') }}</p>
    </template>
  </LinkTeaser>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { LinkTypes } from '@/models/enums/LinkTypes'
import LinkTeaser from '@/components/baseComponents/teasers/LinkTeaser.vue'
import { useRouterHelper } from '@/composables/useRouterHelper'

const { t } = useI18n()

const { getDocsURL } = useRouterHelper()
</script>
<i18n lang="yaml">
de:
  docsTeaser:
    headline: Developer Portal Dokumentation
</i18n>
