<template>
  <LastFiles
    :files-and-folders="filesAndFolders"
    @handle-file-download="downloadFile"
  />

  <FilesAndFolders
    ref="filesAndFoldersRef"
    :files-and-folders="filesAndFolders"
    :organization-type="OrganizationTypes.PROVIDER"
    :view="FilesAndFoldersView.ORGANIZATION"
    @handle-file-download="downloadFile"
    @handle-create-folder="createFolder"
    @handle-delete-folder="deleteFolder"
    @handle-create-file="createFile"
    @handle-delete-file="deleteFile"
  />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { FileExchangeFileResponse } from '@/models/File'
import { Folder } from '@/models/Folder'
import { useFilesStore } from '@/store/files'

import FilesAndFolders from '@/components/filesAndFolders/FilesAndFolders.vue'
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import LastFiles from '@/components/filesAndFolders/LastFiles.vue'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { FilesAndFoldersView } from '@/models/enums/FilesAndFolderTypes'

const route = useRoute()
const filesStore = useFilesStore()

const { filesAndFolders } = storeToRefs(filesStore)

const filesAndFoldersRef = ref<InstanceType<typeof FilesAndFolders>>()

/**
 * createFolder
 * @param {folderName} folderName
 * @param {parentFolder} parentFolder
 */
async function createFolder(folderName: string, parentFolder: Folder): Promise<void> {
  try {
    filesStore.CREATE_FOLDER(folderName, parentFolder)
  } catch {
    return Promise.resolve()
  }
}

/**
 * deleteFolder
 * @param {folderId} folderId
 * @param {parentFolder} parentFolder
 */
async function deleteFolder(folderId: Folder['id'], parentFolder: Folder): Promise<void> {
  try {
    await filesStore.DELETE_FOLDER(folderId, parentFolder)
  } catch {
    Promise.resolve()
  } finally {
    filesAndFoldersRef.value?.closeDialogs()
  }
}

/**
 * createFile
 * @param {files} files
 * @param {parentFolder} parentFolder
 */
async function createFile(files: Array<File>, parentFolder: Folder): Promise<void> {
  try {
    for (const file of files) {
      filesStore.UPLOAD_FILE(parentFolder, file)
    }
  } catch {
    return Promise.resolve()
  }
}

/**
 * downloadFile
 * @param {fileObject} fileObject
 */
async function downloadFile(fileObject: FileExchangeFileResponse): Promise<void> {
  try {
    const response = await filesStore.GET_FILE_BY_ID(fileObject.id)

    const blob = new Blob([response], { type: response.type })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileObject.name || ''
    link.click()
    URL.revokeObjectURL(link.href)
  } catch {
    Promise.resolve()
  }
}

/**
 * deleteFile
 * @param {fileId} fileId
 * @param {parentFolder} parentFolder
 */
async function deleteFile(fileId: FileExchangeFileResponse['id'], parentFolder: Folder): Promise<void> {
  try {
    await filesStore.DELETE_FILE(fileId, parentFolder)
  } catch {
    Promise.resolve()
  } finally {
    filesAndFoldersRef.value?.closeDialogs()
  }
}

await filesStore.GET(route.params.consumerId as string)
</script>
