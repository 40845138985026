export enum Routes {
  INDEX = 'index',
  HOME = 'home',
  INVITATIONS = 'invitations',
  PROFILE_SETTINGS = 'profile-settings',
  ORGANIZATION = 'organization',
  DETAILS = 'details',
  TECHNICAL_USERS = 'technical-users',
  USERS = 'users',
  TEAMS = 'teams',
  ACCESS_DEFINITIONS = 'access-definitions',
  CONTACT_PERSONS = 'contact-persons',

  PROVIDER_DASHBOARD = 'provider-dashboard',
  PROVIDER_PRODUCTS = 'provider-products',
  PROVIDER_PRODUCTS_DETAILS = 'provider-products-details',
  PROVIDER_PRODUCTS_README = 'provider-products-readme',
  PROVIDER_PRODUCTS_API_EXPLORER = 'provider-products-api-explorer',
  PROVIDER_PRODUCTS_FILES = 'provider-products-files',
  PROVIDER_PRODUCTS_CONSUMERS = 'provider-products-consumers',
  PROVIDER_PRODUCTS_ACCESSES = 'provider-products-accesses',
  PROVIDER_PRODUCTS_PLANS = 'provider-products-plans',
  PROVIDER_PRODUCTS_FAQ = 'provider-products-faq',
  PROVIDER_BUNDLES_PRODUCTS = 'provider-bundles-products',
  PROVIDER_BUNDLES_BUSINESSCASE = 'provider-bundles-businesscase',
  PROVIDER_BUNDLES_CREDENTIALS = 'provider-bundles-credentials',
  PROVIDER_BUNDLES_CONTACT = 'provider-bundles-contact',
  PROVIDER_CONSUMERS = 'provider-consumers',
  PROVIDER_CONSUMERS_BUNDLES = 'provider-consumers-bundles',
  PROVIDER_CONSUMERS_FILES = 'provider-consumers-files',
  PROVIDER_CONSUMERS_ORGANIZATION = 'provider-consumers-organization',

  CONSUMER_DASHBOARD = 'consumer-dashboard',
  CONSUMER_PROVIDERS = 'consumer-providers',
  CONSUMER_PROVIDERS_FILES = 'consumer-providers-files',
  CONSUMER_BUNDLES = 'consumer-bundles',
  CONSUMER_BUNDLES_PRODUCTS = 'consumer-bundles-products',
  CONSUMER_BUNDLES_CREDENTIALS = 'consumer-bundles-credentials',
  CONSUMER_BUNDLES_BUSINESSCASE = 'consumer-bundles-businesscase',
  CONSUMER_BUNDLES_CONTACT = 'consumer-bundles-contact',
  CONSUMER_BUNDLES_GOLIVE = 'consumer-bundles-golive',
  CONSUMER_PRODUCTS = 'consumer-products',
  CONSUMER_PRODUCTS_INFOS = 'consumer-products-infos',
  CONSUMER_PRODUCTS_DOCS = 'consumer-products-docs',
  CONSUMER_PRODUCTS_OPENAPI = 'consumer-products-openapi',
  CONSUMER_PRODUCTS_PLANS = 'consumer-products-plans',
  CONSUMER_PRODUCTS_FAQ = 'consumer-products-faq',
  CONSUMER_PRODUCTS_DOWNLOADS = 'consumer-products-downloads',
  CONSUMER_PRODUCTS_CONTACT = 'consumer-products-contact',

  TENANT_DASHBOARD = 'tenant-dashboard',
  TENANT_SETTINGS = 'tenant-settings',
  TENANT_SETTINGS_TENANT_DATA = 'tenant-settings-tenant-data',
  TENANT_SETTINGS_CONTACT_PERSONS = 'tenant-settings-contact-persons',
  TENANT_SETTINGS_USER_MANAGEMENT = 'tenant-settings-user-management',
  TENANT_SETTINGS_LEGAL_CONTENT = 'tenant-settings-legal-content',
  TENANT_SETTINGS_PLATFORM_INFORMATION = 'tenant-settings-platform-information',
  TENANT_PROVIDER = 'tenant-provider',
  TENANT_PROVIDER_DETAIL = 'tenant-provider-detail',

  XS2A_Newsletter = 'xs2a-newsletter',
}
