<template>
  <div
    v-if="files.length > 0"
    class="file-list"
  >
    <p class="highlight xs mb-2">{{ t('lastFiles.label', { count: computedCount }, files.length) }}</p>
    <div class="d-flex">
      <v-hover
        v-for="file in sortAndFilterFiles(files, count)"
        :key="file.id"
      >
        <template #default="{ isHovering, props }">
          <v-card
            v-bind="props"
            width="218"
            height="102"
            elevation="0"
            class="align-content-center"
            :class="isHovering ? 'v-card--hover' : undefined"
            @click="downloadFile(file)"
          >
            <v-card-title class="d-flex justify-center">
              <DefaultAvatar
                size="40"
                :color="getFolderColor(file.nodeType)"
              >
                <v-icon>{{ Icons.DOCUMENT }}</v-icon>
              </DefaultAvatar>
            </v-card-title>
            <v-card-subtitle class="d-flex justify-center text-truncate">
              <div class="truncated-subline">
                {{ file.name }}
              </div>
            </v-card-subtitle>
          </v-card>
        </template>
      </v-hover>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { FileResponseWithNodeType } from '@/models/File'
import { FolderResponse } from '@/models/Folder'
import { computed } from 'vue'
import { Icons } from '@/models/enums/IconTypes'
import { Permissions } from '@/models/enums/Permissions'
import { useFileHelper } from '@/composables/useFileHelper'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'

const { t } = useI18n()

const { getFolderColor, getFilesInArray } = useFileHelper()

const prop = defineProps({
  filesAndFolders: { type: Array<FolderResponse>, required: true },
  count: { type: Number, default: 5 },
})

const files = computed(() => getFilesInArray(prop.filesAndFolders))

const computedCount = computed(() => {
  return files.value.length < prop.count ? files.value.length : prop.count
})

const emit = defineEmits(['handleFileDownload'])

/**
 * downloadFile
 * @param {file} file
 */
function downloadFile(file: FileResponseWithNodeType): void {
  if (file.permissions?.includes(Permissions.READ)) {
    emit('handleFileDownload', file)
  }
}

/**
 * sortAndFilterFiles
 * @param {Array<FileExchangeFileResponse>} files
 * @param {number} count
 * @return {Array<FileExchangeFileResponse>}
 */
function sortAndFilterFiles(files: Array<FileResponseWithNodeType>, count: number): Array<FileResponseWithNodeType> {
  return files.sort((a, b) => new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime()).slice(0, count)
}
</script>

<style lang="scss" scoped>
.file-list {
  margin-bottom: 2rem;

  .v-card {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }

    &-title {
      padding: 1rem;
    }

    &-subtitle {
      font-weight: 400;
      opacity: 1;
    }
  }

  .truncated-subline {
    white-space: nowrap;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .no-background-hover::before {
    background-color: transparent;
  }
}
</style>

<i18n lang="yaml">
de:
  lastFiles:
    label: Die zuletzt hinzugefügte Datei | Die {count} zuletzt hinzugefügten Dateien
</i18n>
