<template>
  <ListElement class="bg-white">
    <template #prepend>
      <DefaultAvatar
        size="48"
        color="grey-lighten-4"
      >
        <v-icon :icon="getIconFromAccessDefinition(accessSet.accessDefinition)" />
      </DefaultAvatar>
    </template>
    <template #title>{{ getNameFromAccessDefinition(accessSet.accessDefinition) }}</template>
    <template #append>
      <DefaultButton
        v-if="
          !activeOrganization?.provider &&
          accessSet.accessDefinition.accessExchangeType !== AccessExchangeTypes.MANUAL_EXCHANGE &&
          noAccessRequested(accessSet) &&
          requestEnabled
        "
        :color="PlattformColors.SECONDARY"
        :prepend-icon="Icons.CIRCLE_ADD"
        @click="emit('requestCredential', accessSet.accessDefinition)"
        >{{ t('credentialsElement.button') }}</DefaultButton
      >
    </template>
    <template #additional>
      <div class="pl-12 ml-6">
        <v-divider class="pb-6" />
        <div class="d-flex">
          <v-list class="w-33">
            <p class="sm highlight">{{ t('credentialsElement.products') }}</p>
            <v-list-item
              v-for="product in accessSet.products"
              :key="product.id"
              class="px-0"
            >
              <template #prepend>
                <ProductLogo
                  class="mr-2"
                  :size="32"
                  :product-logo="product.logo"
                />
              </template>
              <v-list-item-title>
                <p class="sm mb-0 highlight">{{ product.name }}</p>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <div
            v-if="accessSet.accessDefinition.accessExchangeType === AccessExchangeTypes.GENERATE_TOKEN"
            class="w-66"
          >
            <v-list
              v-for="(accessGroup, i) in accessSet.accessGroups as AccessResponseAccessToken[][]"
              :key="`accessGroup-${accessSet.accessDefinition.id}${i}`"
              class="access-group px-0"
            >
              <div class="d-flex justify-space-between">
                <p class="sm highlight">{{ t('credentialsElement.access') }}</p>
                <div>
                  <DefaultTag
                    class="mr-4"
                    :variant="TagVariants.READ_ONLY"
                  >
                    {{ accessGroup[0].authenticator.hashAlgorithm }}
                  </DefaultTag>
                  <DefaultTag
                    class="mr-4"
                    :variant="TagVariants.READ_ONLY"
                  >
                    {{ t('credentialsElement.chips.tokenLength', { length: accessGroup[0].authenticator.maxTokenLength }) }}
                  </DefaultTag>
                  <DefaultTag
                    class="mr-4"
                    :variant="TagVariants.READ_ONLY"
                  >
                    {{ getRightPeriod(accessGroup[0].authenticator.validityPeriod) }}
                  </DefaultTag>
                </div>
              </div>
              <v-list-item
                v-for="access in accessGroup"
                :key="access.id"
                class="px-0"
              >
                <template #prepend>
                  <StatusIcon
                    class="mr-2"
                    :status-type="getStatusSetFromStatusKey(getMostRecentAccessStatus(access.accessStatus)?.statusValue)?.key"
                  />
                </template>
                <v-list-item-title class="font-weight-bold">{{ access.name }}</v-list-item-title>
                <template #append>
                  <p
                    v-if="
                      access.authenticator.validUntil &&
                      (getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.ACTIVE ||
                        getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.DEACTIVATION_REQUESTED)
                    "
                    class="xs mb-0"
                  >
                    {{ t('validUntil', { date: format(new Date(access.authenticator.validUntil), 'dd.MM.yyyy') }) }}
                  </p>
                  <RichTooltip
                    v-if="
                      getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.INACTIVE &&
                      getMostRecentAccessStatus(access.accessStatus)?.note
                    "
                  >
                    <template #activator="{ props }">
                      <IconButton
                        v-bind="props"
                        class="text-red"
                        :icon="Icons.CIRCLE_INFORMATION"
                      />
                    </template>
                    <template #headline>{{ t('credentialsElement.reason') }}</template>
                    <template #copy>{{ getMostRecentAccessStatus(access.accessStatus)?.note }}</template>
                  </RichTooltip>
                  <CopyToClipBtn
                    v-if="activeOrganization?.provider"
                    :text="trimHash(access.authenticator.tokenHash)"
                    :copy-value="access.authenticator.tokenHash"
                    class="ml-4"
                  />
                  <BundleCredentialsContextMenu
                    :access="access"
                    @deactivate-credential="emit('deactivateCredential', access)"
                    @activate-credential="emit('activateCredential', access)"
                    @request-deactivate-credential="emit('requestDeactivateCredential', access)"
                    @deactivate-requested-credential="emit('deactivateRequestedCredential', access)"
                    @handle-request-credential="emit('handleRequestCredential', access)"
                  />
                </template>
              </v-list-item>
            </v-list>
          </div>
          <div
            v-else
            class="w-66"
          >
            <v-list>
              <div class="d-flex justify-space-between">
                <p class="sm highlight">{{ t('credentialsElement.access') }}</p>
                <div>
                  <DefaultTag
                    class="mr-4"
                    :variant="TagVariants.READ_ONLY"
                  >
                    {{ t('credentialsElement.chips.noDefinition') }}
                  </DefaultTag>
                </div>
              </div>
              <v-list-item class="px-0">
                <template #prepend>
                  <DefaultAvatar
                    size="32"
                    :color="PlattformColors.INFO"
                  >
                    <v-icon
                      size="20"
                      :icon="Icons.CIRCLE_INFORMATION"
                  /></DefaultAvatar>
                </template>
                <span>{{ t('credentialsElement.manualInfo') }}</span>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </div>
    </template>
  </ListElement>
</template>
<script lang="ts" setup>
import { useAccessHelper } from '@/composables/useAccessHelper'
import { useStatusHelper } from '@/composables/useStatusHelper'
import { AccessResponseAccessToken, AccessSet } from '@/models/Access'
import { AccessExchangeTypes } from '@/models/enums/AccessDefinitionTypes'
import { Icons } from '@/models/enums/IconTypes'
import { format } from 'date-fns'
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import BundleCredentialsContextMenu from './BundleCredentialsContextMenu.vue'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import { AccessTokenAuthenticatorResponse } from '@/models/Authenticator'
import { StatusTypes } from '@/models/enums/StatusTypes'
import CopyToClipBtn from '@/components/CopyToClipBtn.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import StatusIcon from '@/components/baseComponents/icons/StatusIcon.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import ProductLogo from '@/components/products/ProductLogo.vue'
import DefaultTag from '@/components/baseComponents/tags/DefaultTag.vue'
import { TagVariants } from '@/models/enums/components/Tags'
import ListElement from '../baseComponents/lists/ListElement.vue'
import RichTooltip from '../baseComponents/tooltips/RichTooltip.vue'

defineProps({
  accessSet: { type: Object as PropType<AccessSet>, required: true },
  requestEnabled: { type: Boolean, default: true },
})

const emit = defineEmits([
  'requestCredential',
  'deactivateCredential',
  'deactivateRequestedCredential',
  'activateCredential',
  'requestDeactivateCredential',
  'handleRequestCredential',
])

const { t } = useI18n()
const { getNameFromAccessDefinition, getIconFromAccessDefinition, getMostRecentAccessStatus, getRightPeriod } = useAccessHelper()
const { getStatusSetFromStatusKey } = useStatusHelper()

const myOrganizationStore = useMyOrganizationStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)

/**
 * noAccessRequested
 * @param {accessSet} accessSet
 * @return {boolean}
 */
function noAccessRequested(accessSet: AccessSet): boolean {
  let result = true
  accessSet.accessGroups.forEach((accessGroup) =>
    accessGroup.forEach((access) => {
      if (getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.ACTIVATION_REQUESTED) {
        result = false
        return
      }
    })
  )
  return result
}

/**
 * trimHash
 * @param {tokenHash} tokenHash
 * @return {string}
 */
function trimHash(tokenHash: AccessTokenAuthenticatorResponse['tokenHash']): string {
  return `...${tokenHash?.slice(-6)}`
}
</script>

<i18n lang="yaml">
de:
  credentialsElement:
    products: Verknüpfte API-Produkte
    access: Access Key
    chips:
      tokenLength: '{length} Zeichen'
      noDefinition: Keine Definition
    manualInfo: Manuelle Übergabe durch den Anbieter
    button: Neu beantragen
    reason: 'Begründung:'
</i18n>
