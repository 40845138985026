import { BundleProductExtended, BundleProductResponse, BundleResponse } from '@/models/Bundle'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { useBundleApprovalHelper } from '@/composables/useBundleApprovalHelper'
import { useSearchHelper } from '@/composables/useSearchHelper'
import { ProductResponse } from '@/models/Product'

const { getMostRecentApproval } = useBundleApprovalHelper()
const { getSortFunction } = useSearchHelper()

interface BundleProductsHelper {
  getAvailableBundleProductsExtended: (bundle: BundleResponse, bundleProducts: ProductResponse[], sortOption?: string) => Array<BundleProductExtended>
  isBundleProductActive: (bundleProduct: BundleProductResponse, bundleContractStatus?: BundleResponse['contractStatus']) => boolean
}

/**
 * useBundleProductsHelper
 * @return {BundleProductsHelper}
 */
export function useBundleProductsHelper(): BundleProductsHelper {
  /**
   * getAvailableBundleProducts
   * @param {BundleResponse} bundle
   * @param {ProductResponse[]} bundleProducts
   * @param {string} sortOption
   * @return {Array<BundleProductExtended>}
   */
  function getAvailableBundleProductsExtended(bundle: BundleResponse, bundleProducts: ProductResponse[], sortOption?: string): Array<BundleProductExtended> {
    const result: Array<BundleProductExtended> = []
    bundle.bundleProducts.forEach((bundleProduct) => {
      const foundProduct = bundleProducts?.find((product) => product.id === bundleProduct.id)
      if (foundProduct && isBundleProductActive(bundleProduct, bundle.contractStatus)) {
        result.push({ ...bundleProduct, ...foundProduct })
      }
    })
    return sortOption ? result.sort(getSortFunction(sortOption)) : result
  }

  /**
   * isBundleProductActive
   * @param {BundleProductResponse} bundleProduct
   * @param {StatusTypes} bundleContractStatus
   * @return {boolean}
   */
  function isBundleProductActive(bundleProduct: BundleProductResponse, bundleContractStatus?: BundleResponse['contractStatus']): boolean {
    // optional skip filter products if bundle contractStatus is DRAFT, REQUESTED or IN_PROGRESS
    if (!!bundleContractStatus && [StatusTypes.DRAFT, StatusTypes.REQUESTED, StatusTypes.IN_PROGRESS].includes(bundleContractStatus)) {
      return true
    }
    return bundleProduct.subscriptions?.some((subscription) => {
      const approvalStatus = getMostRecentApproval(subscription.approvals)?.approvalStatus
      return approvalStatus === StatusTypes.APPROVED || approvalStatus === StatusTypes.IN_TERMINATION
    })
  }

  return {
    getAvailableBundleProductsExtended,
    isBundleProductActive,
  }
}
