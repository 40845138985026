<template>
  <div class="teams-tab bg-white">
    <p class="teams-tab__copy">{{ t('organizationUsersTeamsTab.copy') }}</p>
    <v-row>
      <v-col>
        <h2>{{ t('organizationUsersTeamsTab.headline') }}</h2>
      </v-col>
      <v-col>
        <DefaultButton
          class="teams-tab__create"
          :color="PlattformColors.PRIMARY"
          :prepend-icon="Icons.USER_ADD"
          @click="createTeamDialogRef?.open()"
        >
          {{ t('organizationUsersTeamsTab.create') }}
        </DefaultButton>
      </v-col>
    </v-row>
    <div class="teams-tab__container">
      <v-autocomplete
        v-model="team"
        class="teams-tab__container-select"
        :items="teams"
        :label="t('organizationUsersTeamsTab.selectTeam')"
        item-title="name"
        return-object
        variant="outlined"
      >
        <template #no-data>
          <p class="px-4">{{ t('organizationUsersTeamsTab.noTeamAvailable') }}</p>
        </template>
      </v-autocomplete>
      <div v-if="team">
        <h3>{{ t('organizationUsersTeamsTab.properties') }}</h3>
        <p>{{ t('organizationUsersTeamsTab.containerCopy') }}</p>
        <v-row>
          <v-col
            ><v-text-field
              v-model="teamName"
              :label="t('organizationUsersTeamsTab.editTeam.label')"
              :loading="updateLoading"
              @keyup="delayKeyUp()"
          /></v-col>
        </v-row>
        <v-expansion-panels multiple>
          <v-expansion-panel
            v-for="(responsibility, i) in mappedResponsibilities"
            :key="i"
          >
            <v-expansion-panel-title disable-icon-rotate>
              {{ t('organizationUsersTeamsTab.ruleset', { index: i + 1 }) }}
              <template #actions>
                <IconButton
                  :icon="Icons.DELETE"
                  :loading="deleteLoading[i]"
                  @click.stop
                  @click="deleteResponsibility(i)"
                />
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-list>
                <v-list-subheader>{{ responsibility.title }}</v-list-subheader>
                <v-list-item
                  v-for="organization in responsibility.organizations"
                  :key="organization"
                  >{{ organization }}</v-list-item
                >
              </v-list>
              <v-divider />
              <v-list>
                <v-list-subheader>{{ `${t('organizationUsersTeamsTab.categories')} : ${responsibility.mode}` }}</v-list-subheader>
                <v-list-item
                  v-for="category in responsibility.categories"
                  :key="category"
                  >{{ category }}</v-list-item
                >
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <div class="d-flex justify-space-between mt-8">
          <DefaultButton
            :color="PlattformColors.PRIMARY"
            :prepend-icon="Icons.CIRCLE_ADD"
            :variant="ButtonVariants.OUTLINED"
            @click="showOverlayWizard"
          >
            {{ t('organizationUsersTeamsTab.addProperties') }}
          </DefaultButton>

          <DefaultButton
            :color="PlattformColors.PRIMARY"
            @click="deleteTeamDialogRef?.open()"
          >
            {{ t('organizationUsersTeamsTab.delete') }}
          </DefaultButton>
        </div>
      </div>
    </div>
    <div
      v-if="team"
      class="mt-4"
    >
      <DefaultButton
        :color="PlattformColors.PRIMARY"
        :prepend-icon="Icons.USERS"
        :to="{ name: Routes.USERS, query: queryParam }"
        :variant="ButtonVariants.TEXT"
      >
        {{ t('organizationUsersTeamsTab.showUsers') }}
      </DefaultButton>
    </div>
  </div>
  <v-overlay
    v-model="wizard"
    class="justify-center align-center"
    color="primary"
    ><div class="side-wizard-container content-container">
      <SideWizard
        :overview="wizardOverview"
        :components="wizardComponents"
        :button-loading="wizardLoading"
        @finish="saveResponsibility"
        @cancel="wizard = !wizard"
      />
    </div>
  </v-overlay>
  <Dialog
    ref="createTeamDialogRef"
    @on-close="createTeamDialogRef?.close()"
  >
    <DialogTeamCreate
      :loading="createTeamLoading"
      @cancel="createTeamDialogRef?.close()"
      @submit="(teamName: Team['name']) => createTeam(teamName)"
    />
  </Dialog>
  <Dialog
    ref="deleteTeamDialogRef"
    @on-close="deleteTeamDialogRef?.close()"
  >
    <DialogTeamDelete
      :loading="deleteTeamLoading"
      @cancel="deleteTeamDialogRef?.close()"
      @submit="deleteTeam()"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import ResponsibilityElements from '@/components/teams/steps/01_ResponsibilityElements.vue'
import ResponsibilityCategories from '@/components/teams/steps/02_ResponsibilityFolders.vue'
import ResponsibilityPermissions from '@/components/teams/steps/03_ResponsibilityPermissions.vue'
import ResponsibilityOverview from '@/components/teams/steps/04_ResponsibilityOverview.vue'

import { ResponsibilityWizard } from '@/models/sideWizard/Responsibility'
import { useI18n } from 'vue-i18n'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useCategoryStore } from '@/store/categories'
import { useTeamStore } from '@/store/teams'
import { useRoute } from 'vue-router'
import { computed, ref, toRaw, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useOrganizationStore } from '@/store/organizations'
import { useAlertStore } from '@/store/alerts'
import { useSideWizardStore } from '@/store/sideWizard'
import SideWizard from '@/components/SideWizard.vue'
import { Responsibility } from '@/models/Responsibility'
import { Team } from '@/models/Team'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import { Permissions } from '@/models/enums/Permissions'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { NotAvailable } from '@/models/enums/NotAvailable'
import Dialog from '@/components/layout/Dialog.vue'
import DialogTeamCreate from '@/components/dialogs/DialogTeamCreate.vue'
import DialogTeamDelete from '@/components/dialogs/DialogTeamDelete.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import { ButtonVariants } from '@/models/enums/components/Buttons'

interface MappedResponsibility {
  title: string
  organizations: string[]
  categories: string[]
  mode: string
}

const { t } = useI18n()
const route = useRoute()
const myOrganizationStore = useMyOrganizationStore()
const organizationStore = useOrganizationStore()
const categoryStore = useCategoryStore()
const teamStore = useTeamStore()
const alertStore = useAlertStore()
const sideWizardStore = useSideWizardStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)
const { organizationRelations } = storeToRefs(organizationStore)
const { teams, teamById, team } = storeToRefs(teamStore)
const { categoryById } = storeToRefs(categoryStore)

const updateLoading = ref(false)
const deleteLoading = ref<boolean[]>([])
const wizardLoading = ref(false)
const wizard = ref(false)
const teamName = ref('')
const createTeamDialogRef = ref<InstanceType<typeof Dialog>>()
const deleteTeamDialogRef = ref<InstanceType<typeof Dialog>>()
const createTeamLoading = ref(false)
const deleteTeamLoading = ref(false)

const queryParam = computed(() => {
  return team?.value ? { teamId: team.value.id } : undefined
})
const mappedResponsibilities = computed(() => {
  const mappedResponsibilities: MappedResponsibility[] = []
  const providerOrConsumer = activeOrganization?.value?.provider ? OrganizationTypes.PROVIDER : OrganizationTypes.CONSUMER

  team?.value?.responsibilities?.forEach((responsibility) => {
    const mappedResponsibility: MappedResponsibility = {
      title: t(`organizationUsersTeamsTab.organizationLabel.${providerOrConsumer}`),
      organizations: [],
      categories: [],
      mode: responsibility.mode,
    }

    if (responsibility.allOrganizations) {
      mappedResponsibility.organizations.push(t('selectAll'))
    } else if (responsibility.organizations?.length) {
      responsibility.organizations.forEach((organizationId) => {
        const pOrC = activeOrganization?.value?.provider ? OrganizationTypes.CONSUMER : OrganizationTypes.PROVIDER
        mappedResponsibility.organizations = [
          ...mappedResponsibility.organizations,
          organizationRelations.value.find((organization) => organizationId === organization[pOrC].id)![pOrC].name || NotAvailable.NAD,
        ]
      })
    }

    if (responsibility.allCategories) {
      mappedResponsibility.categories.push(t('selectAll'))
    } else if (responsibility.categories?.length) {
      responsibility.categories?.forEach((categoryId) => {
        mappedResponsibility.categories = [...mappedResponsibility.categories, categoryById.value(categoryId)?.name || NotAvailable.NAD]
      })
    }
    mappedResponsibilities.push(mappedResponsibility)
  })
  return mappedResponsibilities
})

// @ts-expect-error: ts-plugin
watch(team, (newValue: Team | undefined) => {
  teamName.value = newValue?.name || ''
})

const wizardOverview = {
  headline: t('organizationUsersTeamsTab.responsibility.headline'),
}
const wizardComponents = [
  {
    headline: t('organizationUsersTeamsTab.responsibility.step1'),
    componentName: ResponsibilityElements,
  },
  {
    headline: t('organizationUsersTeamsTab.responsibility.step2'),
    componentName: ResponsibilityCategories,
  },
  {
    headline: t('organizationUsersTeamsTab.responsibility.step3'),
    componentName: ResponsibilityPermissions,
  },
  {
    headline: t('organizationUsersTeamsTab.responsibility.step4'),
    componentName: ResponsibilityOverview,
  },
]

/**
 * createTeam
 * @param {teamName} teamName
 */
async function createTeam(teamName: Team['name']): Promise<void> {
  try {
    createTeamLoading.value = true
    await teamStore.CREATE(teamName)
    alertStore.add({
      text: t('organizationUsersTeamsTab.success.created'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    createTeamLoading.value = false
    createTeamDialogRef.value?.close()
  }
}

/**
 * updateTeam
 * @param {responsibilities} responsibilities
 */
async function updateTeam(responsibilities?: Responsibility[]): Promise<void> {
  try {
    if (team?.value) {
      const teamId = team?.value.id

      const teamToUpdate = {
        name: teamName.value || null,
        responsibilities: responsibilities || null,
      }
      await teamStore.UPDATE(teamId, teamToUpdate)
    }
  } finally {
    updateLoading.value = false
  }
}

/**
 * deleteTeam
 */
async function deleteTeam(): Promise<void> {
  try {
    if (team?.value) {
      deleteTeamLoading.value = true
      await teamStore.DELETE(team.value.id)
      alertStore.add({
        text: t('organizationUsersTeamsTab.success.deleted'),
        type: AlertTypes.SUCCESS,
      })
    }
  } catch {
    Promise.resolve()
  } finally {
    deleteTeamLoading.value = false
    deleteTeamDialogRef.value?.close()
  }
}

/**
 * showOverlayWizard
 */
function showOverlayWizard(): void {
  sideWizardStore.setType(activeOrganization?.value?.provider ? OrganizationTypes.PROVIDER : OrganizationTypes.CONSUMER)
  wizard.value = true
}

/**
 * deleteResponsibility
 * @param {i} i
 */
async function deleteResponsibility(i: number): Promise<void> {
  deleteLoading.value[i] = true
  const responsibilities = toRaw(team?.value?.responsibilities)
  responsibilities?.splice(i, 1)
  try {
    await updateTeam(responsibilities)
    alertStore.add({
      text: t('organizationUsersTeamsTab.success.updated'),
      type: AlertTypes.SUCCESS,
    })
  } finally {
    deleteLoading.value[i] = false
  }
}

let timer: ReturnType<typeof setTimeout>

/**
 * delayKeyUp
 */
function delayKeyUp(): void {
  updateLoading.value = true
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(() => {
    updateTeam()
  }, 1000)
}

/**
 * saveResponsibility
 * @param {wizardContent} wizardContent
 */
async function saveResponsibility(wizardContent: ResponsibilityWizard): Promise<void> {
  wizardLoading.value = true
  try {
    const responsibility: Responsibility = {
      mode: wizardContent.mode || Permissions.READ,
      allOrganizations: wizardContent.allSelected || false,
      organizations: wizardContent.organizations?.map((organization) => organization.id!),
      allCategories: wizardContent.allFolders || false,
      categories: wizardContent.folders?.map((folder) => folder.customProperties!.legacyCategoryId!),
    }
    const responsibilities = team?.value?.responsibilities?.length ? [...team.value.responsibilities, responsibility] : [responsibility]
    await updateTeam(responsibilities)
    alertStore.add({
      text: t('organizationUsersTeamsTab.success.updated'),
      type: AlertTypes.SUCCESS,
    })
  } finally {
    wizard.value = false
    wizardLoading.value = false
  }
}

if (activeOrganization?.value)
  await Promise.allSettled([organizationStore.GET_ORGANIZATIONS_IN_RELATION(activeOrganization?.value?.id), categoryStore.GET_ORGANIZATIONS(), teamStore.GET()])

// TODO use store instead of query?
if (route.query.teamId) {
  const team = teamById.value(route.query.teamId as string)
  if (team) {
    teamStore.setSelected(team)
    teamName.value = team.name
  }
}
</script>

<style lang="scss" scoped>
.teams-tab {
  &__copy {
    margin-bottom: 2rem;
  }

  &__create {
    float: right;
  }

  &__container {
    margin-top: 1rem;
    padding: 2rem 1.5rem;

    &-table.v-data-table {
      background-color: transparent;

      td {
        padding: 0.375rem 1rem;
      }

      .td-delete {
        .v-icon {
          font-size: $font-size-m;
        }
      }
    }

    &-add {
      margin-top: 1rem;
      padding-left: 1rem;
    }

    &-lower {
      margin-top: 2rem;
    }

    &-save {
      float: right;
    }

    h3 {
      margin-top: 2rem;
    }
  }

  &__links {
    margin-top: 1rem;

    &-user {
      font-weight: bold;
    }
  }
}

.side-wizard-container {
  width: 60rem;
  max-height: 100vh;
  overflow: auto;
}
</style>

<i18n lang="yaml">
de:
  organizationUsersTeamsTab:
    copy: Erstelle Teams zur Verwaltung der Dateien deiner verbundenen Organisationen. Einem Team können Berechtigung zu einer Organisation und den
      dazugehörigen Ordner-Kategorien zugewiesen werden.
    headline: Teams
    create: Neues Team erstellen
    selectTeam: Team auswählen
    noTeamAvailable: Kein Team verfügbar
    properties: Eigenschaften
    containerCopy: Hier kannst du den Teamnamen und die Berechtigungen bearbeiten. Der Teamname wird bei der Eingabe automatisch aktualisiert.
      Berechtigungssets beschreiben die Zugriffsrechte des Teams. Erstelle neue Berechtigungssets, um deinem Team Zugriff auf Organisationen und
      Ordner-Kategorien zu erteilen.
    addProperties: Berechtigungsset hinzufügen
    save: Speichern
    delete: Team löschen
    showUsers: Nutzer anzeigen und einladen
    editTeam:
      label: Teamnamen anpassen
    categories: Kategorien
    organizationLabel:
      provider: Kunden
      consumer: Anbieter
    ruleset: Berechtigungsset {index}
    responsibility:
      headline: Berechtigungen vergeben
      step1: Organisationen auswählen
      step2: Ordner auswählen
      step3: Berechtigung vergeben
      step4: Übersicht
    success:
      created: Das Team wurde erfolgreich angelegt!
      deleted: Das Team wurde erfolgreich gelöscht!
      updated: Das Team wurde erfolgreich aktualisiert!
</i18n>
