export enum PlattformColors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIERY = 'tertiery',
  LIGHT = 'white',
  SUCCESS = 'green',
  INFO = 'grey-lighten-4',
  WARNING = 'yellow',
  ERROR = 'red',
  CHANGE = 'blue',
  LOGO = 'grey-lighten-3',
}
