<template>
  <v-form
    v-model="validateForm"
    @submit.prevent="handleSubmit()"
  >
    <DialogDefaultLayout>
      <template #content>
        <h2>{{ t('changeStatus.headline') }}</h2>
        <p>{{ t('changeStatus.copy') }}</p>

        <v-radio-group v-model="radioSelect">
          <DefaultRadioButton :value="StatusTypes.IN_PROGRESS">
            <template #label>
              <DefaultAvatar
                size="12"
                class="mx-2"
                :color="getStatusSetFromStatusKey(StatusTypes.IN_PROGRESS)?.color"
              />
              <span>
                <b>{{ t('changeStatus.progress.bold') }}</b>
              </span>
            </template>
          </DefaultRadioButton>
          <DefaultRadioButton :value="StatusTypes.APPROVED">
            <template #label>
              <DefaultAvatar
                size="12"
                class="mx-2"
                :color="getStatusSetFromStatusKey(StatusTypes.APPROVED)?.color"
              />
              <span>
                <b>{{ t('changeStatus.accept.bold') }}</b>
                {{ t('changeStatus.accept.normal') }}
              </span>
            </template>
          </DefaultRadioButton>
          <DefaultRadioButton :value="StatusTypes.REJECTED">
            <template #label>
              <DefaultAvatar
                size="12"
                class="mx-2"
                :color="getStatusSetFromStatusKey(StatusTypes.REJECTED)?.color"
              />
              <span>
                <b>{{ t('changeStatus.decline.bold') }}</b>
                {{ t('changeStatus.decline.normal') }}
              </span>
            </template>
          </DefaultRadioButton>
        </v-radio-group>

        <DefaultTextarea
          v-if="radioSelect === StatusTypes.REJECTED"
          v-model="rejectionNote"
          :label="t('changeStatus.label')"
          required
          :rules="maxLength"
        />

        <p>{{ t('changeStatus.subInfo') }}</p>
      </template>

      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :disabled="isSubmitButtonDisabled"
        >
          {{ t('buttons.send') }}</DefaultButton
        >

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}</DefaultButton
        >
      </template>
    </DialogDefaultLayout>
  </v-form>
</template>

<script lang="ts" setup>
import { StatusTypes } from '@/models/enums/StatusTypes'
import { isNotEmpty, isMaxLength } from '@/validators'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { useStatusHelper } from '@/composables/useStatusHelper'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultTextarea from '@/components/baseComponents/inputs/DefaultTextarea.vue'
import DefaultRadioButton from '../baseComponents/radio/DefaultRadioButton.vue'

const props = defineProps({
  currentStatus: { type: String, default: undefined },
})

const { t } = useI18n()

const { getStatusSetFromStatusKey } = useStatusHelper()

const radioSelect = ref<string>()
if (props.currentStatus === StatusTypes.IN_PROGRESS) {
  radioSelect.value = props.currentStatus
}

const rejectionNote = ref<string>()
const validateForm = ref(false)

const isSubmitButtonDisabled = computed(
  () => !radioSelect.value || (radioSelect.value === StatusTypes.REJECTED && !validateForm.value) || radioSelect.value === props.currentStatus
)

const maxLength = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 1000)])

const emit = defineEmits(['changeStatus', 'cancel'])

/**
 * handleSubmit
 */
function handleSubmit(): void {
  const note = radioSelect.value === StatusTypes.REJECTED ? rejectionNote.value : radioSelect.value
  emit('changeStatus', radioSelect.value, note)
}
</script>
<style lang="scss" scoped>
:deep(.v-label) {
  opacity: 1;
}
</style>

<i18n lang="yaml">
de:
  changeStatus:
    headline: Status bearbeiten
    copy: Dein Kunde hat eine Anfrage zur Aktivierung einer App gestellt.
    subInfo: Beim Festlegen des Status wird der Kunde über die Änderung informiert. Der Status kann im nachhinein nicht wieder zurückgesetzt werden.
    label: Begründung*
    progress:
      bold: In Bearbeitung setzen
    accept:
      bold: Genehmigen
      normal: und Zugang einrichten
    decline:
      bold: Ablehnen
      normal: und Zugang sperren
</i18n>
