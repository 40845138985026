<template>
  <template v-if="product">
    <GeneralPageTitle>
      <template #prepend>
        <DefaultAvatar size="80">
          <v-icon
            color="primary"
            size="40"
            >{{ Icons.PRODUCT }}</v-icon
          >
        </DefaultAvatar>
      </template>
      <template #title>
        {{ product.name }}
        <v-icon v-if="product.publiclyAvailable">{{ Icons.GLOBE }}</v-icon>
        <v-icon v-else>{{ Icons.LOCK }}</v-icon>
      </template>
      <template #subtitle>
        {{
          t('createdAt', {
            d: format(new Date(`${product.createdAt}`), 'dd.MM.yyyy'),
          })
        }}
      </template>
      <template #append>
        <template v-if="product.productStatus === StatusTypes.DRAFT">
          <FlagStatus
            class="mr-4"
            :color="getStatusSetFromStatusKey(StatusTypes.DRAFT)?.color"
            :status-key="getStatusSetFromStatusKey(StatusTypes.DRAFT)?.key"
            :outlined="getStatusSetFromStatusKey(StatusTypes.DRAFT)?.outlined"
          >
            {{ t(`providedProductsDetails.productStatus.${product.productStatus}`) }}
          </FlagStatus>

          <div class="mr-4">
            <ProgressButton
              :progress-items="progressItems"
              :button-text="t('providedProductsDetails.menu.publish')"
              @submit="dialogPublishingProductRef?.open()"
            />
          </div>
        </template>
        <v-menu
          location="bottom"
          transition="slide-y-transition"
        >
          <template #activator="{ props }">
            <IconButton
              v-bind="props"
              :icon="Icons.OPTIONS_VERTICAL"
            />
          </template>
          <v-list density="compact">
            <v-list-item
              class="context-menu-list-item"
              flat
              @click="openEditProductDialog()"
            >
              <template #prepend>
                <v-icon>{{ Icons.EDIT }}</v-icon>
              </template>
              <v-list-item-title>{{ t('providedProductsDetails.menu.edit') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="!product.publiclyAvailable && product.productStatus === StatusTypes.PUBLISHED"
              class="context-menu-list-item"
              flat
              @click="openInviteOrganizationDialog()"
            >
              <template #prepend>
                <v-icon>{{ Icons.USER_ADD }}</v-icon>
              </template>
              <v-list-item-title>{{ t('providedProductsDetails.menu.invites') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              flat
              @click="dialogDeleteProductRef?.open()"
            >
              <template #prepend>
                <v-icon>{{ Icons.DELETE }}</v-icon>
              </template>
              <v-list-item-title>{{ t('providedProductsDetails.menu.delete') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </GeneralPageTitle>
    <p class="mb-6">{{ product.description }}</p>
    <v-tabs>
      <v-tab
        replace
        exact
        :to="{ name: Routes.PROVIDER_PRODUCTS_DETAILS, params: { productId: product.id } }"
      >
        {{ t('providedProductsDetails.tabs.details') }}
      </v-tab>
      <v-tab
        v-if="getAvailableFilesForNodeType(availableFiles, FilesAndFolderNodeTypes.README).length > 0"
        replace
        exact
        :to="{ name: Routes.PROVIDER_PRODUCTS_README, params: { productId: product.id } }"
      >
        {{ t('providedProductsDetails.tabs.readme') }}
      </v-tab>
      <v-tab
        v-if="getAvailableFilesForNodeType(availableFiles, FilesAndFolderNodeTypes.API).length > 0"
        replace
        exact
        :to="{ name: Routes.PROVIDER_PRODUCTS_API_EXPLORER, params: { productId: product.id } }"
      >
        {{ t('providedProductsDetails.tabs.apiExplorer') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.PROVIDER_PRODUCTS_FILES, params: { productId: product.id } }"
      >
        {{ t('providedProductsDetails.tabs.files') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.PROVIDER_PRODUCTS_ACCESSES, params: { productId: product.id } }"
      >
        {{ t('providedProductsDetails.tabs.accesses') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.PROVIDER_PRODUCTS_PLANS, params: { productId: product.id } }"
      >
        {{ t('providedProductsDetails.tabs.plans') }}
      </v-tab>

      <v-tab
        v-if="getAvailableFilesForNodeType(availableFiles, FilesAndFolderNodeTypes.FAQ).length > 0"
        replace
        exact
        :to="{ name: Routes.PROVIDER_PRODUCTS_FAQ, params: { productId: product.id } }"
      >
        {{ t('providedProductsDetails.tabs.faq') }}
      </v-tab>
    </v-tabs>
    <TabContainer />
  </template>
  <Dialog
    ref="dialogEditProductRef"
    @on-close="dialogEditProductRef?.close()"
  >
    <DialogProductFormBasic
      :product-object="product"
      :headline="t('providedProductsDetails.update.headline')"
      :loading="loading"
      :focus-desc="editProductFocusDesc"
      @cancel="dialogEditProductRef?.close()"
      @submit="(_product: ProductForm) => updateProduct(_product)"
    />
  </Dialog>
  <Dialog
    ref="dialogDeleteProductRef"
    @on-close="dialogDeleteProductRef?.close()"
  >
    <DialogProductDelete
      :product-object="product"
      :loading="productDeleteloading"
      @cancel="dialogDeleteProductRef?.close()"
      @submit="(_product: ProductResponse) => deleteProduct(_product)"
    />
  </Dialog>
  <Dialog
    ref="dialogPublishingProductRef"
    :size="DialogSizes.EXTRA_LARGE"
    @on-close="dialogPublishingProductRef?.close()"
  >
    <DialogProductPublish
      :product-object="product"
      :loading="productPublishLoading"
      @cancel="dialogPublishingProductRef?.close()"
      @submit="(_product: ProductResponse) => publishProduct(_product)"
    />
  </Dialog>
  <Dialog
    ref="dialogPublishProductSuccessRef"
    :show-close-btn="false"
  >
    <DialogProductPublishSuccess
      :loading="productOffersLoading"
      :product-object="product"
      @done="dialogPublishProductSuccessRef?.close()"
      @enable="openInviteOrganizationDialog()"
    />
  </Dialog>
  <Dialog
    ref="dialogInviteOrganizationRef"
    @on-close="dialogInviteOrganizationRef?.close()"
  >
    <DialogProductInviteOrganization
      ref="dialogProductInviteOrganizationRef"
      :product-object="product"
      :consumers="consumerOrganizations"
      :organizations="organizations"
      :product-offers="productOffers"
      :loading="productInviteLoading"
      @done="dialogInviteOrganizationRef?.close()"
      @remove="(productOffer: ProductOfferResponse) => removeInvitation(productOffer)"
      @invite="(_organizations: OrganizationPublicResponse[]) => inviteOrganizations(_organizations)"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useProductStore } from '@/store/products'
import { format } from 'date-fns'
import { RouteRecordName, useRoute, useRouter } from 'vue-router'
import TabContainer from '@/components/TabContainer.vue'
import Dialog from '@/components/layout/Dialog.vue'
import DialogProductFormBasic from '@/components/dialogs/DialogProductFormBasic.vue'
import DialogProductDelete from '@/components/dialogs/DialogProductDelete.vue'
import DialogProductPublish from '@/components/dialogs/DialogProductPublish.vue'
import DialogProductPublishSuccess from '@/components/dialogs/DialogProductPublishSuccess.vue'
import { computed, ref } from 'vue'
import { ProductForm, ProductResponse } from '@/models/Product'
import { useAlertStore } from '@/store/alerts'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { DialogSizes } from '@/models/enums/DialogSizes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import ProgressButton from '@/components/ProgressButton.vue'
import { useFilesStore } from '@/store/files'
import { FilesAndFolderNodeTypes } from '@/models/enums/FilesAndFolderTypes'
import DialogProductInviteOrganization from '@/components/dialogs/DialogProductInviteOrganization.vue'
import { useProductOfferStore } from '@/store/productOffers'
import { useOrganizationStore } from '@/store/organizations'
import { useStatusHelper } from '@/composables/useStatusHelper'
import { OrganizationPublicResponse } from '@/models/Organization'
import { ProductOfferResponse } from '@/models/ProductOffer'
import FlagStatus from '@/components/baseComponents/flags/FlagStatus.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import { ProductDocumentationFileResponse } from '@/models/File'
import { usePlanStore } from '@/store/plans'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { useFileHelper } from '@/composables/useFileHelper'

const { t } = useI18n()
const alertStore = useAlertStore()
const productStore = useProductStore()
const productOfferStore = useProductOfferStore()
const organizationStore = useOrganizationStore()
const planStore = usePlanStore()
const fileStore = useFilesStore()
const route = useRoute()
const router = useRouter()

const { getFilesInArray, getAvailableFilesForNodeType } = useFileHelper()
const { getStatusSetFromStatusKey } = useStatusHelper()

const { product } = storeToRefs(productStore)
const { filesAndFolders } = storeToRefs(fileStore)
const { productOffers } = storeToRefs(productOfferStore)
const { organizations, consumerOrganizations } = storeToRefs(organizationStore)
const { plans } = storeToRefs(planStore)

const loading = ref(false)
const productDeleteloading = ref(false)
const productPublishLoading = ref(false)
const productOffersLoading = ref(false)
const productInviteLoading = ref(false)
const dialogEditProductRef = ref<InstanceType<typeof Dialog>>()
const dialogDeleteProductRef = ref<InstanceType<typeof Dialog>>()
const dialogPublishingProductRef = ref<InstanceType<typeof Dialog>>()
const dialogPublishProductSuccessRef = ref<InstanceType<typeof Dialog>>()
const dialogInviteOrganizationRef = ref<InstanceType<typeof Dialog>>()
const dialogProductInviteOrganizationRef = ref<InstanceType<typeof DialogProductInviteOrganization>>()
const editProductFocusDesc = ref(false)

const progressItems = [
  {
    name: t(`providedProductsDetails.btn.menu.description`),
    link: (): void => handleDescriptionClick(),
    status: computed(() => !!product?.value?.description),
    required: true,
  },
  {
    name: t(`providedProductsDetails.btn.menu.access`),
    link: (): void => handleProgressItemClick(Routes.PROVIDER_PRODUCTS_ACCESSES),
    status: computed(() => !!product?.value?.accessDefinitionId),
    required: true,
  },
  {
    name: t(`providedProductsDetails.btn.menu.documentation`),
    link: (): void => handleProgressItemClick(Routes.PROVIDER_PRODUCTS_FILES),
    status: computed(
      () =>
        !!filesAndFolders.value
          .find((folder) => folder.nodeType === FilesAndFolderNodeTypes.API)
          ?.files.some((file) => (file as ProductDocumentationFileResponse).active) &&
        !!filesAndFolders.value
          .find((folder) => folder.nodeType === FilesAndFolderNodeTypes.README)
          ?.files.some((file) => (file as ProductDocumentationFileResponse).active)
    ),
    required: false,
  },
  {
    name: t(`providedProductsDetails.btn.menu.plans`),
    link: (): void => handleProgressItemClick(Routes.PROVIDER_PRODUCTS_PLANS),
    status: computed(() => !!plans.value.length),
    required: true,
  },
]

const availableFiles = computed(() => {
  return getFilesInArray(filesAndFolders.value)
})

/**
 * handleDescriptionClick
 */
function handleDescriptionClick(): void {
  editProductFocusDesc.value = true
  dialogEditProductRef.value?.open()
}

/**
 * handleProgressItemClick
 * @param {link} link
 */
function handleProgressItemClick(link: RouteRecordName): void {
  router.push({ name: link, params: { bundleId: product?.value?.id } })
}

/**
 * openEditProductDialog
 */
function openEditProductDialog(): void {
  editProductFocusDesc.value = false
  dialogEditProductRef.value?.open()
}

/**
 * updateProduct
 * @param {ProductForm} updateProduct
 */
async function updateProduct(updateProduct: ProductForm): Promise<void> {
  if (product?.value) {
    try {
      loading.value = true
      await productStore.UPDATE(updateProduct, product.value.id)

      alertStore.add({
        text: t('providedProductsDetails.update.success'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      return Promise.resolve()
    } finally {
      loading.value = false
      dialogEditProductRef.value?.close()
    }
  }
}

/**
 * publishProduct
 * @param {product} product
 */
async function publishProduct(product: ProductResponse): Promise<void> {
  try {
    productPublishLoading.value = true
    await productStore.UPDATE({ productStatus: StatusTypes.PUBLISHED }, product.id)
    alertStore.add({
      text: t('providedProductsDetails.publish.success'),
      type: AlertTypes.SUCCESS,
    })
    dialogPublishProductSuccessRef.value?.open()
  } catch {
    return Promise.resolve()
  } finally {
    productPublishLoading.value = false
    dialogPublishingProductRef.value?.close()
  }
}

/**
 * deleteProducts
 * @param {product} product
 */
async function deleteProduct(product: ProductResponse): Promise<void> {
  try {
    productDeleteloading.value = true
    await productStore.DELETE(product.id)
    router.push({ name: Routes.PROVIDER_PRODUCTS })

    alertStore.add({
      text: t('providedProductsDetails.delete.success'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    return Promise.resolve()
  } finally {
    productDeleteloading.value = false
    dialogDeleteProductRef.value?.close()
  }
}

/**
 * openInviteOrganizationDialog
 */
async function openInviteOrganizationDialog(): Promise<void> {
  if (product?.value) {
    productOffersLoading.value = true
    try {
      await Promise.all([
        organizationStore.GET_ALL_CONSUMERS(),
        organizationStore.GET_CONSUMERS_BY_PRODUCT_ID(product.value.id),
        productOfferStore.GET(product.value.id),
      ])
      dialogInviteOrganizationRef.value?.open()
    } catch {
      Promise.resolve()
    } finally {
      productOffersLoading.value = false
      dialogPublishProductSuccessRef.value?.close()
    }
  }
}

/**
 * inviteOrganizations
 * @param {organizations} organizations
 */
async function inviteOrganizations(organizations: OrganizationPublicResponse[]): Promise<void> {
  if (product?.value) {
    productInviteLoading.value = true
    try {
      await Promise.all(
        organizations.map(async (organization) => {
          await productOfferStore.CREATE(product.value!.id, organization.id)
        })
      )
      alertStore.add({
        text: t('providedProductsDetails.invited.success'),
        type: AlertTypes.SUCCESS,
      })
      dialogProductInviteOrganizationRef.value?.clearSelected()
    } catch {
      dialogInviteOrganizationRef.value?.close()
      Promise.resolve()
    } finally {
      productInviteLoading.value = false
    }
  }
}

/**
 * removeInvitation
 * @param {productOffer} productOffer
 */
async function removeInvitation(productOffer: ProductOfferResponse): Promise<void> {
  if (product?.value) {
    try {
      await productOfferStore.DELETE(product.value.id, productOffer.productOfferId)
      alertStore.add({
        text: t('providedProductsDetails.inviteDeleted.success'),
        type: AlertTypes.SUCCESS,
      })
      dialogProductInviteOrganizationRef.value?.clearDeleteLoading(productOffer.productOfferId)
    } catch {
      dialogInviteOrganizationRef.value?.close()
      Promise.resolve()
    }
  }
}

await Promise.all([productStore.GET_BY_ID(route.params.productId as string), fileStore.GET_PRODUCT_DOCUMENTATION_STRUCTURE(route.params.productId as string)])
// fetch plans if productStatus === DRAFT to check for ProgressButton
if (product?.value?.productStatus === StatusTypes.DRAFT) await planStore.GET_PRODUCT_PLANS(route.params.productId as string)
</script>

<i18n lang="yaml">
de:
  providedProductsDetails:
    headline: Details
    copy: Hier kannst du deine API aktualisieren und verwalten.
    productStatus:
      DRAFT: Entwurf
    tabs:
      details: Details
      readme: Readme
      apiExplorer: API Explorer
      files: Dokumente
      accesses: Authentifizierung
      plans: Pläne
      faq: FAQ
    menu:
      publish: Veröffentlichen
      edit: Bearbeiten
      invites: Freigaben verwalten
      delete: Löschen
    update:
      headline: API bearbeiten
      success: Deine API wurde erfolgreich aktualisiert!
    delete:
      success: Deine API wurde erfolgreich gelöscht!
    publish:
      success: Deine API wurde erfolgreich veröffentlicht!
    invited:
      success: Die Organisationen wurden für deine API freigeschaltet!
    inviteDeleted:
      success: Die Freigabe wurde entfernt!
    btn:
      menu:
        description: Kurzbeschreibung
        access: Authentifizierungsmethode
        documentation: Dokumentation (optional)
        plans: Pläne
</i18n>
