<template>
  <div class="w-100">
    <div class="row">
      <div class="col d-flex justify-center">
        <v-progress-circular
          indeterminate
          size="100"
          color="primary"
        />
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-center">
        <p class="text-center">{{ t('assignments.wait') }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useInvitationStore } from '@/store/invitations'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { Routes } from '@/models/enums/Routes'
import { useActiveUserStore } from '@/store/activeUser'
import { onMounted } from 'vue'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const invitationCode: string = route.query.invitationCode as string

const invitationStore = useInvitationStore()
const myOrganizationStore = useMyOrganizationStore()
const activeUserStore = useActiveUserStore()
const alertStore = useAlertStore()

/**
 *
 * assignUserToOrganization
 * @param {invitationCode} invitationCode
 */
async function assignUserToOrganization(invitationCode: string): Promise<void> {
  try {
    const { data } = await invitationStore.assignUserToOrganization(invitationCode)
    const organizationId = data.organizationId
    if (organizationId) {
      await Promise.all([activeUserStore.GET(), myOrganizationStore.setActiveOrganization(organizationId)])

      alertStore.add({
        text: t('assignments.success', { organizationName: myOrganizationStore.activeOrganization?.name }),
        type: AlertTypes.SUCCESS,
      })
    }
  } catch {
    Promise.resolve()
  } finally {
    router.push({ name: Routes.HOME })
  }
}

onMounted(() => {
  if (invitationCode) {
    assignUserToOrganization(invitationCode)
  } else {
    router.push({ name: Routes.HOME })
  }
})
</script>

<i18n lang="yaml">
de:
  assignments:
    wait: Bitte warten
    success: 'Du bist erfolgreich der Organisation: {organizationName} beigetreten.'
</i18n>
