<template>
  <v-form
    v-model="organizationForm"
    @submit.prevent="emit('submit', organization)"
  >
    <DialogDefaultLayout>
      <template #content>
        <h1 class="mb-8">{{ t('organizationForm.headline.create') }}</h1>

        <DefaultTextField
          v-model="organization.name"
          autofocus
          :label="t('organizationForm.form.name')"
          required
          :rules="organizationNameRules"
        />
        <div class="d-flex">
          <DefaultTextField
            v-model="organization.address.street"
            :label="t('organizationForm.form.street')"
            required
            :rules="organizationCityStreetRules"
            class="w-66 mr-6"
          />
          <DefaultTextField
            v-model="organization.address.number"
            :label="t('organizationForm.form.number')"
            required
            :rules="organizationNumberRules"
            class="w-33"
          />
        </div>
        <div class="d-flex">
          <DefaultTextField
            v-model="organization.address.zipCode"
            :label="t('organizationForm.form.zipCode')"
            required
            :rules="organizationZipCodeRules"
            class="w-33 mr-6"
          />
          <DefaultTextField
            v-model="organization.address.city"
            :label="t('organizationForm.form.city')"
            required
            :rules="organizationCityStreetRules"
            class="w-66"
          />
        </div>
        <DefaultTextField
          v-model="organization.registrationNumber"
          :label="t('organizationForm.form.registrationNumber')"
        />
        <p class="highlight">{{ t('organizationForm.admin') }}</p>
        <DefaultTextField
          v-model="organization.email"
          :label="t('organizationForm.form.email')"
          disabled
          :hint="t('organizationForm.info')"
          persistent-hint
        />
      </template>
      <template #buttons>
        <DefaultButton
          :type="ButtonTypes.SUBMIT"
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          :disabled="!organizationForm"
        >
          {{ t('buttons.create') }}
        </DefaultButton>

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayout>
  </v-form>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { ButtonTypes } from '@/models/enums/components/Buttons'
import { computed, ref } from 'vue'
import { isMaxLength, isMinLength, isNotEmpty } from '@/validators'
import { useActiveUserStore } from '@/store/activeUser'
import { storeToRefs } from 'pinia'
import DefaultTextField from '@/components/baseComponents/inputs/DefaultTextField.vue'

const { t } = useI18n()

const emit = defineEmits(['submit', 'cancel'])

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
})

const activeUserStore = useActiveUserStore()

const { activeUser } = storeToRefs(activeUserStore)

const organizationForm = ref(false)
const organization = ref({
  name: '',
  email: activeUser?.value?.email,
  address: {
    street: '',
    number: '',
    city: '',
    zipCode: '',
  },
  registrationNumber: undefined,
})

const organizationNameRules = computed(() => [
  (v: string): boolean | string => isNotEmpty(v),
  (v: string): boolean | string => isMinLength(v, 3),
  (v: string): boolean | string => isMaxLength(v, 50),
])

const organizationCityStreetRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 90)])
const organizationNumberRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 10)])
const organizationZipCodeRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 15)])
</script>
<i18n lang="yaml">
de:
  organizationForm:
    headline:
      create: Anbieter erstellen
    form:
      name: Organisationsname*
      email: E-Mail
      street: Straße*
      number: Hausnummer*
      city: Stadt*
      zipCode: Postleitzahl*
      registrationNumber: Handelsregisternummer
    admin: Admin-Nutzer
    info: Admins können über die Nutzerverwaltung der neu erstellten Organisation geändert werden.
</i18n>
