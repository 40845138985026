<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('tenantDashboard.headline') }}
    </template>
    <template #append>
      <DefaultButton
        :color="PlattformColors.PRIMARY"
        :prepend-icon="Icons.CIRCLE_ADD"
        @click="createProviderDialogRef?.open()"
      >
        {{ t('tenantDashboard.headBtn') }}
      </DefaultButton>
    </template>
  </GeneralPageTitle>
  <v-row>
    <v-col>
      <LinkTeaser :link="Routes.TENANT_SETTINGS">
        <template #title>
          {{ t('tenantDashboard.navigationList.title') }}
        </template>
        <template #subtitle>
          {{ t('tenantDashboard.navigationList.subtitle') }}
        </template>
        <template #text>
          <DefaultList>
            <!-- <ListElementSelection :to="{ name: Routes.TENANT_SETTINGS_TENANT_DATA }">
              <template #prepend>
                <DefaultAvatar color="grey-lighten-4">
                  <v-icon :icon="Icons.ORGANIZATIONS" />
                </DefaultAvatar>
              </template>
              <template #title>{{ t('tenantDashboard.navigationList.links.tenantData') }}</template>
            </ListElementSelection>
            <ListElementSelection :to="{ name: Routes.TENANT_SETTINGS_CONTACT_PERSONS }">
              <template #prepend>
                <DefaultAvatar color="grey-lighten-4">
                  <v-icon :icon="Icons.USER" />
                </DefaultAvatar>
              </template>
              <template #title>{{ t('tenantDashboard.navigationList.links.contact') }}</template>
            </ListElementSelection>
            <ListElementSelection :to="{ name: Routes.TENANT_SETTINGS_USER_MANAGEMENT }">
              <template #prepend>
                <DefaultAvatar color="grey-lighten-4">
                  <v-icon :icon="Icons.USERS" />
                </DefaultAvatar>
              </template>
              <template #title>{{ t('tenantDashboard.navigationList.links.userManagement') }}</template>
            </ListElementSelection> -->
            <ListElementSelection :to="{ name: Routes.TENANT_SETTINGS_PLATFORM_INFORMATION }">
              <template #prepend>
                <DefaultAvatar color="grey-lighten-4">
                  <v-icon
                    :size="20"
                    :icon="Icons.API_INFOS"
                  />
                </DefaultAvatar>
              </template>
              <template #title>{{ t('tenantDashboard.navigationList.links.platformInformation') }}</template>
            </ListElementSelection>
            <ListElementSelection :to="{ name: Routes.TENANT_SETTINGS_LEGAL_CONTENT }">
              <template #prepend>
                <DefaultAvatar color="grey-lighten-4">
                  <v-icon
                    :size="20"
                    :icon="Icons.USER_SETTINGS"
                  />
                </DefaultAvatar>
              </template>
              <template #title>{{ t('tenantDashboard.navigationList.links.legalContent') }}</template>
            </ListElementSelection>
          </DefaultList>
        </template>
      </LinkTeaser>
    </v-col>
    <v-col>
      <LinkTeaser
        v-if="organizations.length"
        :link="Routes.TENANT_PROVIDER"
      >
        <template #title>
          {{ t('tenantDashboard.providerList.title') }}
        </template>
        <template #subtitle>
          {{ t('tenantDashboard.providerList.subtitle') }}
        </template>
        <template #text>
          <ListElementSelection
            v-for="organization in organizations.slice(0, 5)"
            :key="organization.id"
            :to="{ name: Routes.TENANT_PROVIDER_DETAIL, params: { providerId: organization.id } }"
          >
            <template #prepend>
              <OrganizationLogo
                :size="40"
                :organization-logo="organization.logo"
              />
            </template>
            <template #title>{{ organization.name }}</template>
          </ListElementSelection>
        </template>
      </LinkTeaser>
      <CenteredTeaser v-else>
        <template #title>{{ t('tenantDashboard.noProvider.title') }}</template>
        <template #subtitle>{{ t('tenantDashboard.noProvider.subtitle') }}</template>
        <template #text>
          <DefaultAvatar
            size="120"
            class="mx-auto"
            color="grey-lighten-2"
          >
            <v-icon
              color="black"
              size="60"
              >{{ Icons.ORGANIZATIONS }}</v-icon
            >
          </DefaultAvatar>
        </template>
        <template #actions>
          <DefaultButton
            :variant="ButtonVariants.PLAIN"
            :prepend-icon="Icons.CIRCLE_ADD"
            @click="createProviderDialogRef?.open()"
            >{{ t('tenantDashboard.noProvider.button') }}</DefaultButton
          >
        </template>
      </CenteredTeaser>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <DocsTeaser />
    </v-col>
  </v-row>

  <Dialog
    ref="createProviderDialogRef"
    @on-close="createProviderDialogRef?.close()"
  >
    <DialogTenantProviderCreate
      :loading="loading"
      @cancel="createProviderDialogRef?.close()"
      @submit="(organizationForm: OrganizationForm) => createOrganization(organizationForm)"
    />
  </Dialog>
  <Dialog
    ref="createdProviderDialogRef"
    :show-close-btn="false"
  >
    <DialogTenantProviderCreated
      @provider="switchToProvider()"
      @tenant="createdProviderDialogRef?.close()"
    />
  </Dialog>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { Icons } from '@/models/enums/IconTypes'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Routes } from '@/models/enums/Routes'
import { ButtonVariants } from '@/models/enums/components/Buttons'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import DocsTeaser from '@/components/dashboards/general/DocsTeaser.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import ListElementSelection from '@/components/baseComponents/lists/ListElementSelection.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import LinkTeaser from '@/components/baseComponents/teasers/LinkTeaser.vue'
import CenteredTeaser from '@/components/baseComponents/teasers/CenteredTeaser.vue'
import { useOrganizationStore } from '@/store/organizations'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import Dialog from '@/components/layout/Dialog.vue'
import DialogTenantProviderCreate from '@/components/dialogs/DialogTenantProviderCreate.vue'
import DialogTenantProviderCreated from '@/components/dialogs/DialogTenantProviderCreated.vue'
import { ref } from 'vue'
import { OrganizationForm } from '@/models/Organization'
import OrganizationLogo from '@/components/organizations/OrganizationLogo.vue'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()

const organizationStore = useOrganizationStore()
const myOrganizationStore = useMyOrganizationStore()

const { organizations } = storeToRefs(organizationStore)

const createProviderDialogRef = ref<InstanceType<typeof Dialog>>()
const createdProviderDialogRef = ref<InstanceType<typeof Dialog>>()
const loading = ref(false)
const createdOrganizationId = ref<string>()

/**
 * createOrganization
 * @param {OrganizationForm} organizationForm
 */
async function createOrganization(organizationForm: OrganizationForm): Promise<void> {
  try {
    loading.value = true
    createdOrganizationId.value = (await myOrganizationStore.CREATE({ ...organizationForm, provider: true })).id
    createProviderDialogRef.value?.close()
    createdProviderDialogRef.value?.open()
  } catch {
    createProviderDialogRef.value?.close()
    Promise.resolve()
  } finally {
    loading.value = false
  }
}

/**
 * switchToProvider
 */
async function switchToProvider(): Promise<void> {
  if (createdOrganizationId.value) {
    try {
      await myOrganizationStore.setActiveOrganization(createdOrganizationId.value)
      await router.push({ name: Routes.INDEX })
    } catch {
      createdProviderDialogRef.value?.close()
      Promise.resolve()
    }
  }
}

await organizationStore.GET_ALL_PROVIDERS()
</script>
<i18n lang="yaml">
de:
  tenantDashboard:
    headline: Verwaltungskonsole
    headBtn: Anbieter hinzufügen
    navigationList:
      title: Einstellungen
      subtitle: Verwaltung der Daten deines Developer Portals
      links:
        tenantData: Mandantendaten
        contact: Kontaktpersonen
        userManagement: Nutzerverwaltung
        legalContent: Rechtliche Inhalte
        platformInformation: Plattforminformationen
    providerList:
      title: Anbieter
      subtitle: Organisationen, die in deinem Developer Portal sichtbar sind
    noProvider:
      title: Füge deinen ersten Anbieter hinzu
      subtitle: Anbieter stellen API-Produkte bereit
      button: Anbieter hinzufügen
</i18n>
