<template>
  <Searchbar
    :searchbar-variant="SearchbarVariants.NONE"
    :result-label="t('filesAndFolders.results', { count: files.length })"
    :sort-options="getDefaultSortOptions()"
    @updated-filter="(searchBarValues: SearchbarValues) => (selectedSortOptionValue = searchBarValues.selectedSortOptionValue)"
  />
  <v-list
    v-for="rootFolder in filesAndFoldersSorted"
    :key="rootFolder.name"
    class="root-list"
  >
    <!--ROOT FOLDER-->
    <v-list-group
      expand-icon=""
      collapse-icon=""
    >
      <template #activator="{ isOpen, props }">
        <v-list-item
          v-bind="props"
          :title="t(`filesAndFolders.${rootFolder.nodeType}`)"
          class="rounded-lg bg-white border-grey"
          @click="checkState({ isOpen, props }, rootFolder)"
        >
          <template #prepend>
            <v-icon :class="{ 'rotate-90': !isOpen }">{{ Icons.CHEVRON_DOWN }}</v-icon>
            <DefaultAvatar
              size="40"
              :color="getFolderColor(rootFolder.nodeType)"
            >
              <v-icon>{{ getFolderSetIcon(rootFolder.nodeType) }}</v-icon>
            </DefaultAvatar>
          </template>

          <template #append>
            <p
              v-if="view === FilesAndFoldersView.PRODUCTS && rootFolder.nodeType && rootFolder.permissions.includes(Permissions.CREATE_FILE)"
              class="xs mb-0 mr-8"
            >
              <span>{{ t('filesAndFolders.acceptedFormats') }}</span
              ><span class="font-weight-bold">{{ getAllowedFileTypes(rootFolder.nodeType).join(', ').toLocaleUpperCase() }}</span>
            </p>
            <ContextMenu
              v-if="rootFolder.permissions && rootFolder.permissions.length > 1"
              :type="FilesAndFolderTypes.ROOT_FOLDER"
              :permissions="rootFolder.permissions"
              :accepted-file-formats="view === FilesAndFoldersView.PRODUCTS ? getAllowedFileTypes(rootFolder.nodeType) : undefined"
              @handle-menu-click="(permission, _files) => menuFolderClick(permission, rootFolder, { isOpen, props }, undefined, _files)"
            />
          </template>
          <DropZone
            v-if="rootFolder.permissions.includes(Permissions.CREATE_FILE)"
            @upload-file="(_files) => emit('handleCreateFile', _files, rootFolder)"
          />
        </v-list-item>
      </template>
      <v-list-item
        v-if="!rootFolder.subfolders?.length && !rootFolder.files?.length"
        class="empty-folder rounded-lg bg-transparent"
      >
        <v-list-item-title>
          <span class="bold mr-2">{{ t('filesAndFolders.empty.bold') }} </span>
          <template v-if="rootFolder.permissions && rootFolder.permissions.length > 1">{{ t('filesAndFolders.empty.text') }}</template>
        </v-list-item-title>
      </v-list-item>

      <!--LEVEL 1 FOLDER-->
      <v-list-group
        v-for="subFolder in rootFolder.subfolders"
        :key="subFolder.name"
        expand-icon=""
        collapse-icon=""
      >
        <template #activator="{ isOpen, props }">
          <v-list-item
            v-bind="props"
            :title="subFolder.name"
            class="rounded-lg bg-white border-grey"
            @click="checkState({ isOpen, props }, subFolder)"
          >
            <template #prepend>
              <v-icon :class="{ 'rotate-90': !isOpen }">{{ Icons.CHEVRON_DOWN }}</v-icon>
              <v-icon
                color="primary"
                :icon="Icons.FOLDER"
              ></v-icon>
            </template>

            <template
              v-if="subFolder.permissions && subFolder.permissions.length > 1"
              #append
            >
              <ContextMenu
                :type="FilesAndFolderTypes.SUB_FOLDER"
                :permissions="subFolder.permissions"
                @handle-menu-click="(permission, _files) => menuFolderClick(permission, subFolder, { isOpen, props }, rootFolder, _files)"
              />
            </template>
            <DropZone
              v-if="subFolder.permissions.includes(Permissions.CREATE_FILE)"
              @upload-file="(_files) => emit('handleCreateFile', _files, subFolder)"
            />
          </v-list-item>
        </template>
        <v-list-item
          v-if="!subFolder.subfolders?.length && !subFolder.files?.length"
          class="empty-folder rounded-lg bg-transparent"
        >
          <v-list-item-title>
            <span class="bold mr-2">{{ t('filesAndFolders.empty.bold') }} </span>
            <template v-if="subFolder.permissions && subFolder.permissions.length > 1">{{ t('filesAndFolders.empty.text') }}</template>
          </v-list-item-title>
        </v-list-item>

        <!--LEVEL 2 FOLDER-->
        <v-list-group
          v-for="folder in subFolder.subfolders"
          :key="folder.name"
          expand-icon=""
          collapse-icon=""
        >
          <template #activator="{ isOpen, props }">
            <v-list-item
              v-bind="props"
              :title="folder.name"
              class="rounded-lg bg-white border-grey"
              @click="checkState({ isOpen, props }, folder)"
            >
              <template #prepend>
                <v-icon :class="{ 'rotate-90': !isOpen }">{{ Icons.CHEVRON_DOWN }}</v-icon>
                <v-icon
                  color="primary"
                  :icon="Icons.FOLDER"
                ></v-icon>
              </template>

              <template
                v-if="folder.permissions && folder.permissions.length > 1"
                #append
              >
                <ContextMenu
                  :type="FilesAndFolderTypes.LAST_FOLDER"
                  :permissions="folder.permissions"
                  @handle-menu-click="(permission, _files) => menuFolderClick(permission, folder, { isOpen, props }, subFolder, _files)"
                />
              </template>
              <DropZone
                v-if="folder.permissions.includes(Permissions.CREATE_FILE)"
                @upload-file="(_files) => emit('handleCreateFile', _files, folder)"
              />
            </v-list-item>
          </template>
          <v-list-item
            v-if="!folder.subfolders?.length && !folder.files?.length"
            class="empty-folder rounded-lg bg-transparent"
          >
            <v-list-item-title>
              <span class="bold mr-2">{{ t('filesAndFolders.empty.bold') }} </span>
              <template v-if="folder.permissions && folder.permissions.length > 1">{{ t('filesAndFolders.empty.text') }}</template>
            </v-list-item-title>
          </v-list-item>

          <!--LEVEL 2 FILES-->
          <NewFile
            v-for="uploadFile in folder.uploadFiles"
            :key="uploadFile.name"
            :new-file="uploadFile"
          />
          <v-list-item
            v-for="file in folder.files"
            :key="file.name"
            :title="file.name"
            class="rounded-lg bg-white border-grey"
          >
            <template #prepend>
              <v-icon
                color="primary"
                :icon="Icons.DOCUMENT"
              ></v-icon>
            </template>

            <template
              v-if="file.permissions?.length"
              #append
            >
              <DefaultTag
                v-if="isConsumerLabel(file)"
                :variant="TagVariants.READ_ONLY"
                :prepend-icon="Icons.DOCUMENT_CHECK"
                class="mr-8"
              >
                {{ t(`filesAndFolders.customerUpload.${organizationType}`) }}
              </DefaultTag>
              <ContextMenu
                :type="FilesAndFolderTypes.FILE"
                :permissions="file.permissions"
                @handle-menu-click="(permission) => menuFileClick(permission, folder, file)"
              />
            </template>
          </v-list-item>
        </v-list-group>
        <NewFolder
          v-if="subFolder.ghostFolder"
          :parent-folder="rootFolder"
          @handle-save-new-folder="(folderName) => emit('handleCreateFolder', folderName, subFolder)"
          @handle-cancel-new-folder="cancelNewFolder(subFolder)"
        />

        <!--LEVEL 1 FILES-->
        <NewFile
          v-for="uploadFile in subFolder.uploadFiles"
          :key="uploadFile.name"
          :new-file="uploadFile"
        />
        <v-list-item
          v-for="subFile in subFolder.files"
          :key="subFile.name"
          :title="subFile.name"
          class="rounded-lg bg-white border-grey"
        >
          <template #prepend>
            <v-icon
              color="primary"
              :icon="Icons.DOCUMENT"
            ></v-icon>
          </template>

          <template
            v-if="subFile.permissions?.length"
            #append
          >
            <DefaultTag
              v-if="isConsumerLabel(subFile)"
              :variant="TagVariants.READ_ONLY"
              :prepend-icon="Icons.DOCUMENT_CHECK"
              class="mr-8"
            >
              {{ t(`filesAndFolders.customerUpload.${organizationType}`) }}
            </DefaultTag>
            <ContextMenu
              :type="FilesAndFolderTypes.FILE"
              :permissions="subFile.permissions"
              @handle-menu-click="(permission) => menuFileClick(permission, subFolder, subFile)"
            />
          </template>
        </v-list-item>
      </v-list-group>

      <NewFolder
        v-if="rootFolder.ghostFolder"
        :parent-folder="rootFolder"
        @handle-save-new-folder="(folderName) => emit('handleCreateFolder', folderName, rootFolder)"
        @handle-cancel-new-folder="cancelNewFolder(rootFolder)"
      />

      <!--Root FILES-->
      <NewFile
        v-for="uploadFile in rootFolder.uploadFiles"
        :key="uploadFile.name"
        :new-file="uploadFile"
      />
      <v-list-item
        v-for="subFile in rootFolder.files"
        :key="subFile.id"
        :title="subFile.name"
        class="rounded-lg bg-white border-grey"
      >
        <template #prepend>
          <v-icon
            color="primary"
            :icon="Icons.DOCUMENT"
          ></v-icon>
        </template>

        <template
          v-if="subFile.permissions?.length"
          #append
        >
          <DefaultTag
            v-if="isConsumerLabel(subFile)"
            :variant="TagVariants.READ_ONLY"
            :prepend-icon="Icons.DOCUMENT_CHECK"
            class="mr-8"
          >
            {{ t(`filesAndFolders.customerUpload.${organizationType}`) }}
          </DefaultTag>

          <DefaultCheckbox
            v-if="
              subFile.permissions.includes(Permissions.UPDATE) &&
              (rootFolder.nodeType === FilesAndFolderNodeTypes.README ||
                rootFolder.nodeType === FilesAndFolderNodeTypes.API ||
                rootFolder.nodeType === FilesAndFolderNodeTypes.FAQ)
            "
            v-model="(subFile as ProductDocumentationFileResponse).active"
            class="mb-0"
            :label="t('filesAndFolders.active')"
            :hide-details="true"
            @update:model-value="(value: unknown) => emit('handleFileActivation', value, subFile.id, rootFolder)"
          />

          <ContextMenu
            :type="FilesAndFolderTypes.FILE"
            :permissions="subFile.permissions"
            @handle-menu-click="(permission) => menuFileClick(permission, rootFolder, subFile)"
          />
        </template>
      </v-list-item>
    </v-list-group>
  </v-list>
  <Dialog
    ref="deleteFolderDialogRef"
    @on-close="deleteFolderDialogRef?.close()"
  >
    <DialogFolderDelete
      :folder="folderToDelete"
      @cancel="deleteFolderDialogRef?.close()"
      @submit="emit('handleDeleteFolder', folderToDelete?.id, parentFolderOfToDelete)"
  /></Dialog>
  <Dialog
    ref="deleteFileDialogRef"
    @on-close="deleteFileDialogRef?.close()"
  >
    <DialogFileDelete
      :file="fileToDelete"
      @cancel="deleteFileDialogRef?.close()"
      @submit="emit('handleDeleteFile', fileToDelete?.id, parentFolderOfToDelete)"
  /></Dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import ContextMenu from '@/components/filesAndFolders/ContextMenu.vue'
import NewFolder from '@/components/filesAndFolders/NewFolder.vue'
import DropZone from './DropZone.vue'
import { Folder } from '@/models/Folder'
import NewFile from './NewFile.vue'
import { useFilesStore } from '@/store/files'
import { FileResponse, ProductDocumentationFileResponse } from '@/models/File'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import { computed, PropType, ref } from 'vue'
import { Icons } from '@/models/enums/IconTypes'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { Permissions } from '@/models/enums/Permissions'
import { FilesAndFolderNodeTypes, FilesAndFoldersView, FilesAndFolderTypes } from '@/models/enums/FilesAndFolderTypes'
import Dialog from '@/components/layout/Dialog.vue'
import DialogFolderDelete from '@/components/dialogs/DialogFolderDelete.vue'
import DialogFileDelete from '@/components/dialogs/DialogFileDelete.vue'
import { useFileHelper } from '@/composables/useFileHelper'
import { useSearchHelper } from '@/composables/useSearchHelper'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import Searchbar from '../Searchbar.vue'
import { SearchbarVariants } from '@/models/enums/SearchbarVariants'
import { SearchbarValues } from '@/models/SearchbarValues'
import DefaultCheckbox from '../baseComponents/checkboxes/DefaultCheckbox.vue'
import DefaultTag from '@/components/baseComponents/tags/DefaultTag.vue'
import { TagVariants } from '@/models/enums/components/Tags'

interface Activator {
  isOpen: boolean
  props: Record<string, unknown>
}

const { t } = useI18n()
const filesStore = useFilesStore()
const myOrganizationStore = useMyOrganizationStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)

const { getFolderColor, getFolderSetIcon, getFilesInArray, getAllowedFileTypes } = useFileHelper()
const { getDefaultSortOptions, getSortFunction } = useSearchHelper()

const emit = defineEmits(['handleCreateFolder', 'handleDeleteFolder', 'handleCreateFile', 'handleDeleteFile', 'handleFileDownload', 'handleFileActivation'])

const componentProps = defineProps({
  filesAndFolders: { type: Array<Folder>, required: true },
  organizationType: { type: String as PropType<OrganizationTypes>, default: undefined },
  view: { type: String as PropType<FilesAndFoldersView>, default: undefined },
})

const deleteFolderDialogRef = ref<InstanceType<typeof Dialog>>()
const deleteFileDialogRef = ref<InstanceType<typeof Dialog>>()
const fileToDelete = ref<FileResponse>()
const folderToDelete = ref<Folder>()
const parentFolderOfToDelete = ref<Folder>()
const selectedSortOptionValue = ref<string>()

const filesAndFoldersSorted = computed(() => {
  /**
   * sortFilesAndSubfolders
   * @param {folder} folder
   * @param {Function} sortFunction
   */
  function sortFilesAndSubfolders(folder: Folder, sortFunction: (a: object, b: object) => number): void {
    folder.subfolders?.sort(sortFunction)
    folder.files?.sort(sortFunction)

    if (folder.subfolders?.length) {
      folder.subfolders?.forEach((subfolder: Folder) => sortFilesAndSubfolders(subfolder, sortFunction))
    }
  }

  const sortedResult = componentProps.filesAndFolders

  if (selectedSortOptionValue.value) {
    const sortFunction = getSortFunction(selectedSortOptionValue.value)

    sortedResult.sort(sortFunction) // sort Rootfolders separately
    sortedResult.forEach((folder) => sortFilesAndSubfolders(folder, sortFunction))
  }

  return sortedResult
})

const files = computed(() => getFilesInArray(componentProps.filesAndFolders))

/**
 * isConsumerLabel
 * @param {FileResponse} file
 * @return {boolean}
 */
function isConsumerLabel(file: FileResponse): boolean {
  return (
    (componentProps.organizationType === OrganizationTypes.CONSUMER && file.createdByOrganizationId === activeOrganization?.value?.id) ||
    (componentProps.organizationType === OrganizationTypes.PROVIDER && file.createdByOrganizationId !== activeOrganization?.value?.id)
  )
}

/**
 * checkState
 * @param {activator} activator
 * @param {currentFolder} currentFolder
 */
function checkState(activator: Activator, currentFolder: Folder): void {
  if (activator.isOpen && !!currentFolder.ghostFolder) {
    filesStore.removeLastFolder(currentFolder)
  }
}

/**
 * cancelNewFolder
 * @param {parentFolder} parentFolder
 */
function cancelNewFolder(parentFolder: Folder): void {
  filesStore.removeLastFolder(parentFolder)
}

/**
 * menuFolderClick
 * @param {string} permission - The permission level
 * @param {Folder} currentFolder - The current folder
 * @param {Activator} activator - The triggering element
 * @param {Folder} [parentFolder] - (Optional) The parent folder
 * @param {Array<File>} [files] - (Optional) A list of files
 */
function menuFolderClick(permission: string, currentFolder: Folder, activator: Activator, parentFolder?: Folder, files?: Array<File>): void {
  // handle auto open list group
  if (!activator.isOpen) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore:next-line
    activator.props.onClick(new Event('click'))
  }

  if (permission === Permissions.CREATE_FOLDER) {
    filesStore.createNewFolder(currentFolder)
  }

  if (permission === Permissions.DELETE_FOLDER && parentFolder) {
    folderToDelete.value = currentFolder
    parentFolderOfToDelete.value = parentFolder
    deleteFolderDialogRef.value?.open()
  }

  if (permission === Permissions.CREATE_FILE && files) {
    emit('handleCreateFile', files, currentFolder)
  }
}

/**
 * menuFileClick
 * @param {permission} permission
 * @param {parentFolder} parentFolder
 * @param {file} file
 */
function menuFileClick(permission: string, parentFolder: Folder, file: FileResponse): void {
  if (permission === Permissions.FILE_READ) {
    emit('handleFileDownload', file)
  }

  if (permission === Permissions.DELETE_FILE && parentFolder) {
    fileToDelete.value = file
    parentFolderOfToDelete.value = parentFolder
    deleteFileDialogRef.value?.open()
  }
}

/**
 * closeDialogs
 */
function closeDialogs(): void {
  deleteFolderDialogRef.value?.close()
  deleteFileDialogRef.value?.close()
}

defineExpose({
  closeDialogs,
})
</script>

<style lang="scss" scoped>
.root-list {
  background-color: transparent;
  padding: 0;
}

:deep(.v-list-item-title) {
  font-size: $font-size-s;
}

// root folder
.v-list-group {
  .v-list-item {
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
    min-height: 4rem;

    &__prepend {
      > .v-icon,
      > .v-avatar {
        margin-right: 0.75rem;
      }
    }

    &.empty-folder {
      border: 2px dashed rgb(var(--v-theme-black));

      /* stylelint-disable */
      margin-left: calc(var(--indent-padding) - 1.5rem) !important;
      /* stylelint-enable */
      .bold {
        font-weight: bold;
      }
    }
  }
}

// sub-folder
.v-list-group__items {
  .v-list-item {
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
    min-height: 4rem;

    /* stylelint-disable */
    padding-inline-start: 1.5rem !important;
    margin-left: calc(1rem + var(--indent-padding)) !important;
    /* stylelint-enable */
  }
}

:deep(.v-list-item__prepend > .v-icon ~ .v-list-item__spacer) {
  display: none;
}

.sortbox__select {
  width: 13rem;
}
</style>

<i18n lang="yaml">
de:
  filesAndFolders:
    sortfield: Sortierung
    CONTRACT: Verträge
    INVOICE: Rechnungen
    CREDENTIAL: Anmeldeinformationen
    REPORT: Reports
    MISC: Sonstiges
    README: Readme
    API: API Explorer
    FAQ: FAQ
    active: Aktiv
    empty:
      bold: Dieser Ordner ist leer.
      text: Nutze das Kontextmenü, um Dateien hochzuladen oder neue Unterordner zu erstellen.
    customerUpload:
      provider: Kunden-Upload
      consumer: Meine Datei
    results: '{count} Datei | {count} Dateien'
    acceptedFormats: 'Unterstützte Formate: '
</i18n>
