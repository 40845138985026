<template>
  <Transition name="fade">
    <div
      v-if="appLoading"
      class="d-flex justify-center align-center app-loading bg-white"
    ></div>

    <v-app
      v-else
      class="bg-secondary"
    >
      <NavDrawer />
      <NavBar />
      <v-main>
        <Dialog
          ref="deletedUserOverlay"
          @on-close="deletedUserOverlay?.close()"
        >
          <DialogUserDeleted @close="deletedUserOverlay?.close()" />
        </Dialog>
        <Dialog
          v-model="selectOrganizationOverlay"
          :show-close-btn="false"
        >
          <DialogSelectOrganization
            :my-organizations="myOrganizations"
            @submit="(selectedOrganization: UserOrganization) => selectOrganization(selectedOrganization)"
          />
        </Dialog>
        <v-container class="main-container">
          <AlertContainer />
          <Snackbar />
          <RouterView v-slot="{ Component }">
            <template v-if="Component">
              <Suspense timeout="0">
                <!-- main content -->
                <component :is="Component"></component>
                <!-- loading state -->
                <template #fallback>
                  <div class="content-loading d-flex justify-center align-center bg-secondary">
                    <v-progress-circular
                      indeterminate
                      size="100"
                      color="primary"
                    />
                  </div>
                </template>
              </Suspense>
            </template>
          </RouterView>
        </v-container>
      </v-main>
    </v-app>
  </Transition>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useTenantStore } from '@/store/tenants'
import { useLoadingStore } from '@/store/loading'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { isLoggedIn } from '@/plugins/keycloak'
import { useLocalStorage } from '@/composables/useLocalStorage'
import AlertContainer from '@/components/layout/AlertContainer.vue'
import NavBar from '@/components/layout/NavBar.vue'
import NavDrawer from '@/components/layout/NavDrawer.vue'
import Dialog from '@/components/layout/Dialog.vue'
import DialogSelectOrganization from '@/components/dialogs/DialogSelectOrganization.vue'
import DialogUserDeleted from '@/components/dialogs/DialogUserDeleted.vue'
import Snackbar from '@/components/layout/Snackbar.vue'
import { UserOrganization } from '@/models/User'
import { Routes } from '@/models/enums/Routes'
import { AuthorizationsContexts } from '@/models/enums/AuthorizationsContextTypes'

const router = useRouter()

const myOrganizationStore = useMyOrganizationStore()
const tenantStore = useTenantStore()
const loadingStore = useLoadingStore()

const { getItem, removeItem } = useLocalStorage()
const { appLoading } = storeToRefs(loadingStore)
const { activeOrganization, myOrganizations } = storeToRefs(myOrganizationStore)
const { activeTenant } = storeToRefs(tenantStore)
const deletedUserOverlay = ref<InstanceType<typeof Dialog>>()

const selectOrganizationOverlay = computed(() => isLoggedIn() && myOrganizations.value.length && !activeOrganization?.value && !activeTenant?.value)

/**
 * selectOrganization
 * @param {UserOrganization} userOrganization
 */
async function selectOrganization(userOrganization?: UserOrganization): Promise<void> {
  try {
    if (userOrganization) {
      loadingStore.setAppLoading(true)

      if (userOrganization.authorizations[0].contexts.some((context) => context.context === AuthorizationsContexts.TENANT)) {
        await tenantStore.setActiveTenant(userOrganization.organizationId)
      } else {
        await myOrganizationStore.setActiveOrganization(userOrganization.organizationId)
      }
      await router.push({ name: Routes.INDEX })
    }
  } catch {
    await Promise.resolve()
  } finally {
    loadingStore.setAppLoading(false)
  }
}

onMounted(() => {
  if (getItem('deletedUser')) {
    removeItem('deletedUser')
    deletedUserOverlay.value?.open()
  }
})
</script>
<style lang="scss" scoped>
.app-loading {
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  left: 0;
  top: 0;
}

.fade-leave-active {
  transition: opacity 0.375s ease;
}

.fade-leave-to {
  opacity: 0;
}

.content-loading {
  position: absolute;
  height: 100vh;
  width: calc(100% - 16rem);
  z-index: 100;
  left: 16rem;
  top: 0;
}

.inactive-provider {
  position: relative;
  height: calc(100vh - 11rem);

  :deep(.v-overlay__content) {
    height: 100%;
    width: 100%;
  }

  :deep(.v-card) {
    margin: 10rem auto 0;
    max-width: 44rem;
    padding: 3rem 5rem 5rem;
    text-align: center;
  }

  .v-image {
    margin: 0 auto 1rem;
  }
}
</style>
<i18n lang="yaml">
de:
  inactiveOverlay:
    headline: Deine Organisation wurde noch nicht freigeschaltet.
    copy: Wir melden uns bei dir, sobald der Prozess erfolgreich abgeschlossen wurde. Bei Fragen kommen wir auf dich zu.
</i18n>
