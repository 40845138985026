<template>
  <div class="create-plan">
    <v-form
      ref="createPlanFormRef"
      v-model="createPlanForm"
      @submit.prevent="submitPlan()"
    >
      <DialogDefaultLayout>
        <template #content>
          <h1>{{ headline }}</h1>
          <p v-if="!planToUpdate">{{ t('createPlan.copy') }}</p>

          <p class="highlight">{{ t('createPlan.sublines.general') }}</p>
          <DefaultTextField
            v-model="plan.name"
            autofocus
            :label="t('createPlan.form.name')"
            required
            :rules="planNameRules"
          />

          <DefaultTextarea
            v-model="plan.description"
            :rules="planDescriptionRules"
            :label="t('createPlan.form.description')"
            counter="255"
          />
          <v-divider class="mb-6" />
          <p class="highlight">{{ t('createPlan.sublines.visibility') }}</p>
          <DefaultTextField
            v-model="validity.validFrom"
            :rules="planValidFromRules"
            type="date"
            :min="format(new Date(), 'yyyy-MM-dd')"
            :label="t('createPlan.form.validFrom')"
            required
            @change="createPlanFormRef?.validate()"
          />
          <DefaultButton
            v-if="!validUntilVisible"
            class="mb-4"
            :color="PlattformColors.PRIMARY"
            :prepend-icon="Icons.CIRCLE_ADD"
            @click="toggleValidUntil()"
            >{{ t('createPlan.addValidUntil') }}</DefaultButton
          >

          <DefaultTextField
            v-else
            v-model="validity.validUntil"
            :rules="planValidUntilRules"
            type="date"
            :min="format(new Date(), 'yyyy-MM-dd')"
            :label="t('createPlan.form.validUntil')"
            @change="createPlanFormRef?.validate()"
          >
            <template #append>
              <IconButton
                :icon="Icons.DELETE"
                @click="toggleValidUntil()"
              />
            </template>
          </DefaultTextField>
          <v-divider class="mb-6" />
          <p class="highlight">{{ t('createPlan.sublines.contract') }}</p>
          <DefaultTextField
            :model-value="t('createPlan.form.unlimited')"
            :label="t('createPlan.form.duration')"
            required
            disabled
          />
        </template>

        <template #buttons>
          <DefaultButton
            type="submit"
            :color="PlattformColors.PRIMARY"
            :disabled="!createPlanForm"
            :loading="loading"
          >
            {{ t('buttons.save') }}
          </DefaultButton>

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="emit('cancel')"
            >{{ t('buttons.cancel') }}</DefaultButton
          >
        </template>
      </DialogDefaultLayout>
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import { Icons } from '@/models/enums/IconTypes'
import { isNotEmpty, isMinLength, isMaxLength, isDateLaterOrNull } from '@/validators'
import { PropType, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { PlanForm, PlanResponse, PlanValidity } from '@/models/Plan'
import { PlanTypes } from '@/models/enums/PlanTypes'
import { format } from 'date-fns'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import IconButton from '../baseComponents/buttons/IconButton.vue'
import DefaultTextField from '@/components/baseComponents/inputs/DefaultTextField.vue'
import DefaultTextarea from '@/components/baseComponents/inputs/DefaultTextarea.vue'

const { t } = useI18n()

const emit = defineEmits(['submit', 'cancel'])

const props = defineProps({
  planToUpdate: { type: Object as PropType<PlanResponse>, default: undefined },
})

const createPlanFormRef = ref<HTMLFormElement>()
const createPlanForm = ref(false)
const loading = ref(false)
const plan = ref<PlanForm>({
  name: props.planToUpdate?.name || '',
  description: props.planToUpdate?.description || undefined,
  planType: props.planToUpdate?.planType || PlanTypes.DYNAMIC,
})
const validity = ref<PlanValidity>({
  validFrom: props.planToUpdate?.validity?.validFrom ? format(new Date(props.planToUpdate.validity.validFrom), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
  validUntil: props.planToUpdate?.validity?.validUntil ? format(new Date(props.planToUpdate.validity.validUntil), 'yyyy-MM-dd') : undefined,
})

const validUntilVisible = ref(props.planToUpdate?.validity?.validUntil ? true : false)

const headline = computed(() => (props.planToUpdate ? t('editPlan.headline') : t('createPlan.headline')))

const planNameRules = computed(() => [
  (v: string): boolean | string => isNotEmpty(v),
  (v: string): boolean | string => isMinLength(v, 3),
  (v: string): boolean | string => isMaxLength(v, 50),
])
const planDescriptionRules = computed(() => [(v: string): boolean | string => isMaxLength(v, 255)])
const planValidFromRules = computed(() => [(v: string): boolean | string => isNotEmpty(v)])
const planValidUntilRules = computed(() => [(v: string): boolean | string => isDateLaterOrNull(v, validity.value.validFrom)])

/**
 * toggleValidUntil
 */
function toggleValidUntil(): void {
  validUntilVisible.value = !validUntilVisible.value
  if (!validUntilVisible.value) {
    validity.value.validUntil = null
  }
}

/**
 * submitPlan
 */
function submitPlan(): void {
  loading.value = true
  const planToSubmit = {
    ...plan.value,
    validity: {
      validFrom: new Date(validity.value.validFrom).toISOString(),
      validUntil: validity.value.validUntil ? new Date(validity.value.validUntil).toISOString() : null,
    },
  }
  emit('submit', planToSubmit)
}
</script>

<i18n lang="yaml">
de:
  createPlan:
    headline: Neuen Plan erstellen
    copy: Lege Pläne für deine API an, um deinem Kunden unterschiedliche Services und Leistungen anzubieten.
    form:
      name: Name des Plans*
      description: Beschreibung*
      validFrom: Startdatum*
      validUntil: Enddatum
      duration: Laufzeit*
      unlimited: Unbegrenzt
    sublines:
      general: Allgemeine Informationen
      visibility: Sichtbarkeit auf dem Marktplatz
      contract: Vertragslaufzeit
    addValidUntil: Enddatum hinzufügen
  editPlan:
    headline: Plan bearbeiten
</i18n>
