import { SearchbarValues } from './../models/SearchbarValues'
import { defineStore } from 'pinia'

interface searchbarStateProperties {
  searchbarProperties: SearchbarValues[]
}

export const useSearchbarStore = defineStore('searchbar', {
  state: (): searchbarStateProperties => ({
    searchbarProperties: [],
  }),
  actions: {
    setSearchbarValues(updatedSearchBarValues: SearchbarValues) {
      const matchedSearchbarValueObject = this.searchbarProperties.find(
        (searchbarValues) => searchbarValues.searchbarRoute === updatedSearchBarValues.searchbarRoute
      )

      if (matchedSearchbarValueObject) {
        matchedSearchbarValueObject.searchQuery = updatedSearchBarValues.searchQuery
        matchedSearchbarValueObject.selectedSortOptionValue = updatedSearchBarValues.selectedSortOptionValue
        matchedSearchbarValueObject.selectedFilterOptionValues = updatedSearchBarValues.selectedFilterOptionValues
      } else {
        this.searchbarProperties.push(updatedSearchBarValues)
      }
    },
    clearSearchbarValues(searchbarRoute: SearchbarValues['searchbarRoute']) {
      const searchbarPropertyMatchedIndex = this.searchbarProperties.findIndex((searchbarValues) => searchbarValues.searchbarRoute === searchbarRoute)
      if (typeof searchbarPropertyMatchedIndex === 'number') {
        this.searchbarProperties.splice(searchbarPropertyMatchedIndex, 1)
      }
    },
  },
  getters: {
    getMatchedSearchbarValues(state) {
      return (searchbarRoute: SearchbarValues['searchbarRoute']): SearchbarValues | undefined =>
        state.searchbarProperties.find((searchbarValues) => searchbarRoute === searchbarValues.searchbarRoute)
    },
    getSearchbarSortOption(state) {
      return (searchbarRoute: SearchbarValues['searchbarRoute']): SearchbarValues['selectedSortOptionValue'] =>
        state.searchbarProperties.find((searchbarValues) => searchbarRoute === searchbarValues.searchbarRoute)?.selectedSortOptionValue
    },
    getSearchbarFilterOptions(state) {
      return (searchbarRoute: SearchbarValues['searchbarRoute']): SearchbarValues['selectedFilterOptionValues'] =>
        state.searchbarProperties.find((searchbarValues) => searchbarRoute === searchbarValues.searchbarRoute)?.selectedFilterOptionValues
    },
    getSearchbarSearchQuery(state) {
      return (searchbarRoute: SearchbarValues['searchbarRoute']): SearchbarValues['searchQuery'] =>
        state.searchbarProperties.find((searchbarValues) => searchbarRoute === searchbarValues.searchbarRoute)?.searchQuery
    },
  },
})
