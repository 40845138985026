<template>
  <div v-if="bundle">
    <GeneralPageTitle>
      <template #prepend>
        <BundleAvatar :size="80" />
      </template>
      <template #title>
        {{ bundle.name }}
      </template>
      <template #subtitle>
        <b>{{ t('consumerBundleDetail.appId') }}</b> {{ bundle.id }}
      </template>
      <template #append>
        <FlagStatus
          class="mr-2"
          :color="getStatusSetFromStatusKey(bundle.contractStatus)?.color"
          :status-key="getStatusSetFromStatusKey(bundle.contractStatus)?.key"
          :outlined="getStatusSetFromStatusKey(bundle.contractStatus)?.outlined"
        >
          {{ t(`bundleStatus.${bundle.contractStatus}`) }}
        </FlagStatus>
        <PlainTooltip>
          <template #activator="{ props }">
            <DefaultAvatar
              v-if="(bundle.contractStatus === StatusTypes.APPROVED || bundle.contractStatus === StatusTypes.IN_TERMINATION) && !bundle.accessStatus.access"
              v-bind="props"
              size="32"
              :color="PlattformColors.ERROR"
            >
              <v-icon size="16">{{ Icons.LOCK }}</v-icon>
            </DefaultAvatar>
          </template>
          <template #default>{{ t('consumerBundleDetail.toggleBtn.tooltips.inactive') }}</template>
        </PlainTooltip>
        <ProgressButton
          v-if="bundle.contractStatus === StatusTypes.DRAFT"
          :progress-items="progressItems"
          :button-text="t('consumerBundleDetail.btn.goLive')"
          @submit="goLiveDialogRef?.open()"
        />
        <v-menu
          location="bottom"
          transition="slide-y-transition"
        >
          <template #activator="{ props }">
            <IconButton
              v-if="
                bundle.contractStatus === StatusTypes.DRAFT ||
                bundle.contractStatus === StatusTypes.APPROVED ||
                bundle.contractStatus === StatusTypes.IN_TERMINATION ||
                bundle.contractStatus === StatusTypes.TERMINATED
              "
              v-bind="props"
              :icon="Icons.OPTIONS_VERTICAL"
            />
          </template>
          <v-list density="compact">
            <v-list-item
              v-for="(item, i) in bundleDetailMenuList"
              :key="i"
              class="context-menu-list-item"
              flat
              :disabled="!!item.disabled"
              @click="item.action"
            >
              <template #prepend>
                <v-icon>{{ item.icon }}</v-icon>
              </template>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </GeneralPageTitle>
    <p class="mb-6">{{ bundle.description }}</p>
    <InfoCardContainer
      :bundle="bundle"
      :bundle-change="bundleChange"
      @submit-changes="submitChanges()"
      @discard-changes="removeChangesQuestionDialogRef?.open()"
    />
    <v-tabs>
      <v-tab
        replace
        exact
        :to="{ name: Routes.CONSUMER_BUNDLES_PRODUCTS, params: { bundleId: bundle.id } }"
      >
        {{ t('consumerBundleDetail.productsTab') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.CONSUMER_BUNDLES_BUSINESSCASE, params: { bundleId: bundle?.id } }"
      >
        {{ t('consumerBundleDetail.businessCaseTab') }}
      </v-tab>
      <v-tab
        v-if="bundle.contractStatus !== StatusTypes.DRAFT"
        replace
        exact
        :to="{ name: Routes.CONSUMER_BUNDLES_CREDENTIALS, params: { bundleId: bundle.id } }"
      >
        {{ t('consumerBundleDetail.credentialsTab') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.CONSUMER_BUNDLES_CONTACT, params: { bundleId: bundle?.id } }"
      >
        {{ t('consumerBundleDetail.bundleContactTab') }}
      </v-tab>
    </v-tabs>
    <TabContainer />
    <Dialog
      ref="editBundleDialogRef"
      @on-close="editBundleDialogRef?.close()"
    >
      <DialogBundleForm
        :available-organizations="[bundle?.providerOrganization]"
        :bundle-object="bundle"
        :loading="editBundleLoading"
        :focus-element-ref="editBundleFocusElement"
        @cancel="editBundleDialogRef?.close()"
        @submit="(_bundle: BundleCreateRequest) => editBundle(_bundle)"
      />
    </Dialog>
    <Dialog
      ref="deleteBundleQuestionDialogRef"
      @on-close="deleteBundleQuestionDialogRef?.close()"
    >
      <DialogBundleDelete
        :bundle="bundle"
        :loading="deleteBundleLoading"
        @cancel="deleteBundleQuestionDialogRef?.close()"
        @submit="deleteBundle()"
      />
    </Dialog>
    <Dialog
      ref="terminateBundleDialogRef"
      @on-close="terminateBundleDialogRef?.close()"
    >
      <DialogBundleTerminate
        :bundle="bundle"
        :loading="terminateBundleLoading"
        @cancel="terminateBundleDialogRef?.close()"
        @submit="(note: string | undefined) => terminateBundle(note)"
      />
    </Dialog>
    <Dialog
      ref="removeChangesQuestionDialogRef"
      @on-close="removeChangesQuestionDialogRef?.close()"
    >
      <DialogBundleChangeDelete
        @cancel="removeChangesQuestionDialogRef?.close()"
        @submit="discardChanges()"
    /></Dialog>
    <Dialog
      ref="goLiveDialogRef"
      @on-close="goLiveDialogRef?.close()"
    >
      <DialogBundleGoLive
        :loading="loadingDialogBundleGoLive"
        @cancel="goLiveDialogRef?.close()"
        @submit="startGoLiveRequest()"
      />
    </Dialog>

    <Dialog
      ref="createCredentialDialogRef"
      :size="DialogSizes.EXTRA_LARGE"
      :show-close-btn="false"
      @on-close="createCredentialDialogRef?.close()"
    >
      <DialogBundleCredentialCreate
        ref="createCredentialsRef"
        :access-definitions="accessDefinitionsToRequest"
        :loading="createCredentialsLoading"
        @create-new-access-key="(authenticators: AuthenticatorRequest[]) => handleCreateCredential(authenticators)"
        @cancel="closeCreateCredentialsDialog()"
      />
    </Dialog>
  </div>
</template>

<script lang="ts" setup>
import TabContainer from '@/components/TabContainer.vue'
import { useBundleStore } from '@/store/bundles'
import { useI18n } from 'vue-i18n'
import { RouteRecordName, useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import Dialog from '@/components/layout/Dialog.vue'
import DialogBundleDelete from '@/components/dialogs/DialogBundleDelete.vue'
import DialogBundleTerminate from '@/components/dialogs/DialogBundleTerminate.vue'
import DialogBundleChangeDelete from '@/components/dialogs/DialogBundleChangeDelete.vue'
import DialogBundleGoLive from '@/components/dialogs/DialogBundleGoLive.vue'
import DialogBundleForm from '@/components/dialogs/DialogBundleForm.vue'
import DialogBundleCredentialCreate from '@/components/dialogs/DialogBundleCredentialCreate.vue'
import InfoCardContainer from '@/components/bundles/InfoCardContainer.vue'
import ProgressButton from '@/components/ProgressButton.vue'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { computed, ref } from 'vue'
import { BundleChanges, BundleChangesProduct, BundleCreateRequest, BundleResponse } from '@/models/Bundle'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { PlanResponse } from '@/models/Plan'
import { AccessDefinitionResponse } from '@/models/AccessDefinition'
import { AuthenticatorRequest } from '@/models/Authenticator'
import { useAccessesStore } from '@/store/accesses'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { DialogSizes } from '@/models/enums/DialogSizes'
import { useAccessDefinitionsStore } from '@/store/accessDefinitions'
import { useStatusHelper } from '@/composables/useStatusHelper'
import FlagStatus from '@/components/baseComponents/flags/FlagStatus.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import BundleAvatar from '@/components/bundles/BundleAvatar.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import PlainTooltip from '@/components/baseComponents/tooltips/PlainTooltip.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const myOrganizationStore = useMyOrganizationStore()
const bundleStore = useBundleStore()
const accessStore = useAccessesStore()
const accessDefinitionsStore = useAccessDefinitionsStore()
const alertStore = useAlertStore()
const { bundle, bundleChanges } = storeToRefs(bundleStore)
const { activeOrganization } = storeToRefs(myOrganizationStore)
const { getStatusSetFromStatusKey } = useStatusHelper()
const editBundleDialogRef = ref<InstanceType<typeof Dialog>>()
const deleteBundleQuestionDialogRef = ref<InstanceType<typeof Dialog>>()
const terminateBundleDialogRef = ref<InstanceType<typeof Dialog>>()
const removeChangesQuestionDialogRef = ref<InstanceType<typeof Dialog>>()
const goLiveDialogRef = ref<InstanceType<typeof Dialog>>()
const createCredentialDialogRef = ref<InstanceType<typeof Dialog>>()
const accessDefinitionsToRequest = ref<AccessDefinitionResponse[]>()
const editBundleLoading = ref(false)
const editBundleFocusElement = ref<string>()
const deleteBundleLoading = ref(false)
const terminateBundleLoading = ref(false)
const loadingDialogBundleGoLive = ref(false)
const createCredentialsLoading = ref(false)

const progressItems = [
  {
    name: t(`consumerBundleDetail.btn.menu.description`),
    link: (): void => handleDescriptionClick(),
    status: computed(() => !!bundle?.value?.description),
    required: true,
  },
  {
    name: t(`consumerBundleDetail.btn.menu.products`),
    link: (): void => handleProgressItemClick(Routes.CONSUMER_BUNDLES_PRODUCTS),
    status: computed(() => !!bundle?.value?.bundleProducts?.length),
    required: true,
  },
  {
    name: t(`consumerBundleDetail.btn.menu.businesscase`),
    link: (): void => handleProgressItemClick(Routes.CONSUMER_BUNDLES_BUSINESSCASE),
    status: computed(() => !!bundle?.value?.businessCases?.length),
    required: true,
  },
  {
    name: t(`consumerBundleDetail.btn.menu.contact`),
    link: (): void => handleProgressItemClick(Routes.CONSUMER_BUNDLES_CONTACT),
    status: computed(() => !!bundle?.value?.contact),
    required: true,
  },
  {
    name: t(`consumerBundleDetail.btn.menu.organization`),
    link: (): void => handleProgressItemClick(Routes.DETAILS),
    status: computed(() => !!activeOrganization?.value?.address),
    required: true,
  },
]

const bundleChange = computed(() => bundleChanges?.value?.find((change: BundleChanges) => change.bundleId === bundle?.value?.id))

interface MenuItem {
  title: string
  icon: Icons
  action: () => void
  disabled?: boolean
}

const menuItems: { [key: string]: MenuItem } = {
  edit: {
    title: t('consumerBundleDetail.menu.edit'),
    icon: Icons.EDIT,
    action: (): void => {
      editBundleFocusElement.value = ''
      editBundleDialogRef.value?.open()
    },
  },
  delete: {
    title: t('consumerBundleDetail.menu.delete'),
    icon: Icons.DELETE,
    action: (): void => deleteBundleQuestionDialogRef.value?.open(),
  },
  terminate: {
    title: t('consumerBundleDetail.menu.cancel'),
    icon: Icons.CLOSE,
    action: (): void => terminateBundleDialogRef.value?.open(),
  },
  contact: {
    title: t('consumerBundleDetail.menu.contact'),
    icon: Icons.EMAIL,
    action: (): string => (window.location.href = `mailto:${bundle?.value?.providerOrganization?.email}`),
  },
}

const bundleDetailMenuList = computed(() => {
  switch (bundle?.value?.contractStatus) {
    case StatusTypes.DRAFT:
      return [menuItems.edit, menuItems.delete]
    case StatusTypes.APPROVED:
      return [menuItems.terminate, menuItems.contact]
    case StatusTypes.IN_TERMINATION:
      return [{ ...menuItems.terminate, disabled: true }, menuItems.contact]
    case StatusTypes.TERMINATED:
      return [menuItems.contact, menuItems.delete]
    default:
      return []
  }
})

/**
 * handleDescriptionClick
 */
function handleDescriptionClick(): void {
  editBundleFocusElement.value = 'bundleFormDesc'
  editBundleDialogRef.value?.open()
}

/**
 * handleProgressItemClick
 * @param {link} link
 */
function handleProgressItemClick(link: RouteRecordName | undefined): void {
  router.push({ name: link, params: { bundleId: bundle?.value?.id } })
}

/**
 * submitChanges
 */
async function submitChanges(): Promise<void> {
  if (bundle?.value) {
    let planIds: Array<PlanResponse['id']> = []
    const newAccessDefinitions: AccessDefinitionResponse[] = []
    bundleChange.value?.changedProducts.forEach((changedProduct: BundleChangesProduct) => (planIds = [...planIds, ...changedProduct.selectedPlans]))
    bundleChange.value?.newProducts.forEach((newProduct: BundleChangesProduct) => {
      planIds = [...planIds, ...newProduct.selectedPlans]
      if (newProduct.product.accessDefinition) {
        newAccessDefinitions.push(newProduct.product.accessDefinition)
      }
    })
    try {
      await Promise.all([
        planIds.length ? bundleStore.ADD_SUBSCRIPTION(bundle.value.id, planIds) : Promise.resolve(),
        bundleChange.value?.changedBusinessCases?.length
          ? bundleStore.UPDATE(bundle?.value?.id, { businessCases: bundleChange.value?.changedBusinessCases })
          : Promise.resolve(),
      ])

      if (newAccessDefinitions.length) {
        openCreateCredentialsDialog(newAccessDefinitions)
      } else {
        alertStore.add({
          text: t('consumerBundleDetail.success.edit'),
          type: AlertTypes.SUCCESS,
        })
      }

      bundleStore.removeBundleChangesById(bundle.value.id)
    } catch {
      Promise.resolve()
    }
  }
}

/**
 * discardChanges
 */
function discardChanges(): void {
  if (bundle?.value) {
    bundleStore.removeBundleChangesById(bundle.value.id)
    removeChangesQuestionDialogRef.value?.close()
  }
}

/**
 * openCreateCredentialsDialog
 * @param {selectedAccessDefinitions} selectedAccessDefinitions
 */
function openCreateCredentialsDialog(selectedAccessDefinitions: AccessDefinitionResponse[]): void {
  accessDefinitionsToRequest.value = selectedAccessDefinitions
  createCredentialDialogRef.value?.open()
}

/**
 * closeCreateCredentialsDialog
 */
function closeCreateCredentialsDialog(): void {
  accessDefinitionsToRequest.value = []
  createCredentialDialogRef.value?.close()
}

/**
 * editBundle
 * @param {Partial<BundleResponse>} updateBundle
 */
async function editBundle(updateBundle: Partial<BundleResponse>): Promise<void> {
  if (bundle?.value) {
    try {
      editBundleLoading.value = true
      await bundleStore.UPDATE(bundle.value.id, updateBundle)
      alertStore.add({
        text: t('consumerBundleDetail.success.edit'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      Promise.resolve()
    } finally {
      editBundleLoading.value = false
      editBundleDialogRef.value?.close()
    }
  }
}

/**
 * deleteBundle
 */
async function deleteBundle(): Promise<void> {
  if (bundle?.value) {
    try {
      deleteBundleLoading.value = true
      await bundleStore.DELETE(bundle.value.id)
      router.push({ name: Routes.CONSUMER_BUNDLES })
      alertStore.add({
        text: t('consumerBundleDetail.success.delete'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      Promise.resolve()
    } finally {
      deleteBundleLoading.value = false
    }
  }
}

/**
 * terminateBundle
 * @param {string | undefined} note
 */
async function terminateBundle(note?: string): Promise<void> {
  if (bundle?.value) {
    try {
      terminateBundleLoading.value = true
      await bundleStore.TERMINATE(bundle.value.id, note)
    } catch {
      Promise.resolve()
    } finally {
      terminateBundleLoading.value = false
      terminateBundleDialogRef.value?.close()
    }
  }
}

/**
 * startGoLiveRequest
 */
async function startGoLiveRequest(): Promise<void> {
  try {
    loadingDialogBundleGoLive.value = true
    const accessDefinitionIds = [...new Set(bundle?.value?.bundleProducts?.map((bundleProduct) => bundleProduct.accessDefinitionId))]
    const accessDefinitions: AccessDefinitionResponse[] = []

    await Promise.all(
      accessDefinitionIds.map(async (accessDefinitionId) => {
        accessDefinitions.push(await accessDefinitionsStore.GET_BY_ID(accessDefinitionId))
      })
    )

    accessDefinitionsToRequest.value = accessDefinitions
    createCredentialDialogRef.value?.open()
  } catch {
    Promise.resolve()
  } finally {
    goLiveDialogRef.value?.close()
    loadingDialogBundleGoLive.value = false
  }
}

/**
 * handleCreateCredential
 * @param {AuthenticatorRequest[]} authenticators
 */
function handleCreateCredential(authenticators: AuthenticatorRequest[]): void {
  if (bundle?.value?.contractStatus === StatusTypes.DRAFT) {
    createGoLiveRequest(authenticators)
  } else {
    requestNewCredential(authenticators)
  }
}

/**
 * createGoLiveRequest
 * @param {AuthenticatorRequest[]} authenticators
 */
async function createGoLiveRequest(authenticators: AuthenticatorRequest[]): Promise<void> {
  if (bundle?.value) {
    try {
      createCredentialsLoading.value = true
      await bundleStore.GO_LIVE_REQUEST(bundle.value.id, { authenticators })

      alertStore.add({
        text: t('consumerBundleDetail.success.goLiveRequested'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      Promise.resolve()
    } finally {
      createCredentialsLoading.value = false
      closeCreateCredentialsDialog()
    }
  }
}

/**
 * requestNewCredential
 * @param {AuthenticatorRequest[]} authenticators
 */
async function requestNewCredential(authenticators: AuthenticatorRequest[]): Promise<void> {
  if (bundle?.value) {
    try {
      createCredentialsLoading.value = true
      const newAccess = await accessStore.CREATE(authenticators[0], bundle.value.id)
      bundleStore.addBundleAccess(newAccess)

      alertStore.add({
        text: t('consumerBundleDetail.success.credentialRequested'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      Promise.resolve()
    } finally {
      createCredentialsLoading.value = false
      closeCreateCredentialsDialog()
    }
  }
}

await Promise.allSettled([bundleStore.GET_BY_ID(route.params.bundleId as string)])
</script>
<i18n lang="yaml">
de:
  consumerBundleDetail:
    appId: App-ID
    productsTab: API-Produkte
    businessCaseTab: Business Case
    credentialsTab: Authentifizierungsmittel
    bundleContactTab: Kontakt
    btn:
      goLive: Aktivierungsanfrage
      menu:
        description: Kurzbeschreibung
        products: API-Produkte
        businesscase: Business Case
        contact: Kontaktdaten
        organization: Organisationsdaten
    toggleBtn:
      tooltips:
        inactive: App-Zugriff ist gesperrt
    menu:
      edit: App bearbeiten
      delete: App löschen
      cancel: App kündigen
      contact: Provider kontaktieren
    success:
      edit: Die App wurde erfolgreich aktualisiert!
      credentialRequested: Der neue Access wurde erfolgreich beantragt.
      goLiveRequested: Die Aktivierungsanfrage wurde erfolgreich abgeschickt.
      delete: Die App wurde erfolgreich gelöscht!
</i18n>
