<template>
  <div class="pa-3">
    <v-row class="dialog-split-layout">
      <v-col
        cols="5"
        :class="`left-side-wrapper pa-16 bg-grey-lighten-4`"
      >
        <slot name="content-left"></slot>
      </v-col>

      <v-col
        cols="7"
        class="bg-white px-16 pt-16 pb-8"
      >
        <div class="right-side-wrapper d-flex flex-column justify-space-between">
          <slot name="content-right"></slot>
          <div class="buttons-wrapper">
            <div class="dialog-divider py-8">
              <v-divider
                color="primary"
                class="dialog-divider__line mb-8"
              />
            </div>
            <div class="d-flex justify-space-between flex-row-reverse">
              <slot name="buttons"></slot>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.left-side-wrapper {
  z-index: 1; // necessary to hide divider line
}

.right-side-wrapper {
  height: 100%;
}

.dialog-divider {
  &__line {
    position: absolute;
    width: 101%;
    left: 0;
  }
}
</style>
