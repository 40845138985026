<template>
  <div v-if="bundle?.bundleProducts?.length">
    <Searchbar
      :result-label="t('providerBundleDetailProducts.results', { count: combinedBundleProducts.length })"
      :sort-options="getDefaultSortOptions()"
      :searchbar-variant="SearchbarVariants.NONE"
      @updated-filter="(searchbarValues: SearchbarValues) => (selectedSortOptionValue = searchbarValues.selectedSortOptionValue)"
    />
    <ProductListWithPlans
      :bundle-status="bundle.contractStatus"
      :products="combinedBundleProducts"
      @handle-product-click="handleProductClick"
      @handle-terminate-subscription="(subscription: SubscriptionResponse) => handleTerminateSubscription(subscription)"
    />
  </div>
  <div v-else>
    <p>{{ t('providerBundleDetailProducts.empty') }}</p>
  </div>
  <Dialog
    ref="bundleTerminateSubscriptionQuestionDialogRef"
    @on-close="bundleTerminateSubscriptionQuestionDialogRef?.close()"
  >
    <DialogBundleSubscriptionTerminate
      :subscription="subscriptionForTerminate"
      :loading="subscriptionTerminateLoading"
      @cancel="bundleTerminateSubscriptionQuestionDialogRef?.close()"
      @submit="(subscription: SubscriptionResponse) => terminateSubscription(subscription)"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { ProductResponse } from '@/models/Product'
import { useBundleStore } from '@/store/bundles'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { Routes } from '@/models/enums/Routes'
import Dialog from '@/components/layout/Dialog.vue'
import ProductListWithPlans from '@/components/products/ProductListWithPlans.vue'
import DialogBundleSubscriptionTerminate from '@/components/dialogs/DialogBundleSubscriptionTerminate.vue'
import { useBundleProductsHelper } from '@/composables/useBundleProductsHelper'
import { SubscriptionResponse } from '@/models/Subscription'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { computed, ref } from 'vue'
import { useSubscriptionStore } from '@/store/subscriptions'
import { useAlertStore } from '@/store/alerts'
import { useSearchHelper } from '@/composables/useSearchHelper'
import { SearchbarVariants } from '@/models/enums/SearchbarVariants'
import { SearchbarValues } from '@/models/SearchbarValues'
import Searchbar from '@/components/Searchbar.vue'
import { useProductStore } from '@/store/products'

const { t } = useI18n()
const router = useRouter()
const bundleStore = useBundleStore()
const productStore = useProductStore()
const subscriptionStore = useSubscriptionStore()
const alertStore = useAlertStore()

const { bundle } = storeToRefs(bundleStore)
const { bundleProducts } = storeToRefs(productStore)

const { getDefaultSortOptions } = useSearchHelper()
const { getAvailableBundleProductsExtended } = useBundleProductsHelper()

const selectedSortOptionValue = ref<string>()

const bundleTerminateSubscriptionQuestionDialogRef = ref<InstanceType<typeof Dialog>>()
const subscriptionForTerminate = ref<SubscriptionResponse>()
const subscriptionTerminateLoading = ref(false)

const combinedBundleProducts = computed(() => {
  return bundle?.value ? getAvailableBundleProductsExtended(bundle.value, bundleProducts.value, selectedSortOptionValue.value) : []
})

/**
 * handleProductClick
 * @param {productId} productId
 */
function handleProductClick(productId: ProductResponse['id']): void {
  router.push({ name: Routes.PROVIDER_PRODUCTS_DETAILS, params: { productId } })
}

/**
 * handleTerminateSubscription
 * @param {subscription} subscription
 */
function handleTerminateSubscription(subscription: SubscriptionResponse): void {
  subscriptionForTerminate.value = subscription
  bundleTerminateSubscriptionQuestionDialogRef.value?.open()
}

/**
 * terminateSubscription
 * @param {subscription} subscription
 */
async function terminateSubscription(subscription: SubscriptionResponse): Promise<void> {
  try {
    subscriptionTerminateLoading.value = true
    const bundleResponse = await subscriptionStore.TERMINATE(subscription.id, 'note')
    if (bundle?.value) bundle.value = bundleResponse
    alertStore.add({
      text: t('providerBundleDetailProducts.terminatePlan'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    subscriptionTerminateLoading.value = false
    bundleTerminateSubscriptionQuestionDialogRef.value?.close()
  }
}

if (bundle?.value) await productStore.GET_BUNDLE_PRODUCTS(bundle.value.bundleProducts)
</script>
<i18n lang="yaml">
de:
  providerBundleDetailProducts:
    results: '{count} API | {count} API-Produkte'
    empty: Keine API-Produkte gefunden.
    terminatePlan: Der Plan wurde erfolgreich gekündigt.
</i18n>
