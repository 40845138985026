<template>
  <v-list-item class="bg-white pa-8 rounded-lg border justify-space-between">
    <template #prepend>
      <DefaultAvatar
        size="48"
        class="mr-4"
        color="grey-lighten-4"
      >
        <v-icon size="24">{{ icon }}</v-icon>
      </DefaultAvatar>
    </template>
    <template #title>
      <p class="highlight mb-0">{{ headline }}</p>
    </template>
    <template
      v-if="copy"
      #subtitle
    >
      <p class="sm mb-0">{{ copy }}</p>
    </template>
    <template #append>
      <DefaultButton
        :color="PlattformColors.PRIMARY"
        :loading="loading"
        @click="emit('submit')"
      >
        {{ btnText }}
      </DefaultButton>
    </template>
  </v-list-item>
</template>

<script lang="ts" setup>
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'

const emit = defineEmits(['submit'])

defineProps({
  icon: {
    type: String,
    required: true,
  },
  headline: {
    type: String,
    required: true,
  },
  copy: {
    type: String,
    default: '',
  },
  btnText: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})
</script>
