<template>
  <v-select
    v-model="model"
    :color="PlattformColors.CHANGE"
    :density="density"
    :disabled="disabled"
    :hide-details="hideDetails"
    :items="items"
    :item-title="itemTitle"
    :item-value="itemValue"
    :label="label"
    :required="required"
    variant="outlined"
    :width="width"
  >
    <template
      v-if="$slots['prepend-inner']"
      #prepend-inner
    >
      <slot name="prepend-inner"></slot>
    </template>
  </v-select>
</template>
<script lang="ts" setup>
import { PlattformColors } from '@/models/enums/ColorSets'
import { InputDensity } from '@/models/enums/components/Inputs'
import { PropType } from 'vue'

defineProps({
  density: { type: String as PropType<InputDensity>, default: InputDensity.DEFAULT },
  disabled: { type: Boolean, default: false },
  hideDetails: { type: Boolean, default: false },
  items: { type: Array, required: true },
  itemTitle: { type: String, default: 'title' },
  itemValue: { type: String, default: 'value' },
  label: { type: String, default: undefined },
  required: { type: Boolean, default: false },
  width: { type: String, default: undefined },
})

const model = defineModel<unknown>()
</script>
