<template>
  <div>
    <v-form
      v-model="createContactForm"
      @submit.prevent="(emit('submit', contact), (loading = true))"
    >
      <DialogDefaultLayout>
        <template #content>
          <h1>{{ headline }}</h1>
          <DefaultTextField
            v-model="contact.name"
            autofocus
            :label="t('contactPersonsForm.labels.name')"
            :rules="contactNameRules"
            required
          />
          <DefaultTextField
            v-model="contact.email"
            :label="t('contactPersonsForm.labels.email')"
            :rules="contactEmailRules"
            required
          />
          <DefaultTextField
            v-model="contact.phone"
            :label="t('contactPersonsForm.labels.phone')"
            :rules="contactPhoneRules"
            required
          />

          <DefaultCheckbox
            v-if="!contactToUpdate"
            v-model="contact.defaultContact"
            class="mr-6"
            :label="t('contactPersonsForm.labels.default')"
          />
        </template>
        <template #buttons>
          <DefaultButton
            type="submit"
            :color="PlattformColors.PRIMARY"
            :loading="loading"
            :disabled="!createContactForm"
          >
            {{ t('buttons.save') }}</DefaultButton
          >

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="emit('cancel')"
            >{{ t('buttons.cancel') }}</DefaultButton
          >
        </template>
      </DialogDefaultLayout>
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { isNotEmpty, isMaxLength, isEmail } from '@/validators'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { ContactResponse, ContactRequest } from '@/models/Contact'
import { PropType } from 'vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultCheckbox from '../baseComponents/checkboxes/DefaultCheckbox.vue'
import DefaultTextField from '@/components/baseComponents/inputs/DefaultTextField.vue'

const { t } = useI18n()

const props = defineProps({
  contactToUpdate: { type: Object as PropType<ContactResponse>, default: undefined },
})

const emit = defineEmits(['submit', 'cancel'])

const headline = computed(() => (props.contactToUpdate ? t('contactPersonsForm.headline.edit') : t('contactPersonsForm.headline.create')))
const createContactForm = ref(false)
const loading = ref(false)
const contact = ref<ContactRequest | ContactResponse>({
  name: '',
  email: '',
  phone: '',
  defaultContact: false,
})

if (props.contactToUpdate) contact.value = props.contactToUpdate

const contactNameRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 255)])
const contactEmailRules = computed(() => [
  (v: string): boolean | string => isNotEmpty(v),
  (v: string): boolean | string => isEmail(v),
  (v: string): boolean | string => isMaxLength(v, 255),
])
const contactPhoneRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 255)])
</script>

<i18n lang="yaml">
de:
  contactPersonsForm:
    headline:
      create: Neue Kontaktperson erstellen
      edit: Kontaktperson bearbeiten
    labels:
      name: Name*
      email: E-Mail*
      phone: Telefon*
      default: Als Standard festlegen
</i18n>
