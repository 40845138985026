<template>
  <FilesAndFolders
    :files-and-folders="filesAndFolders"
    :view="FilesAndFoldersView.PRODUCTS"
    @handle-file-download="downloadFile"
  />
</template>

<script lang="ts" setup>
import FilesAndFolders from '@/components/filesAndFolders/FilesAndFolders.vue'
import { FilesAndFoldersView } from '@/models/enums/FilesAndFolderTypes'
import { ProductDocumentationFileResponse } from '@/models/File'
import { useFilesStore } from '@/store/files'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'

const route = useRoute()
const filesStore = useFilesStore()
const productId = route.params.productId as string

const { filesAndFolders } = storeToRefs(filesStore)

/**
 * downloadFile
 * @param {fileObject} fileObject
 */
async function downloadFile(fileObject: ProductDocumentationFileResponse): Promise<void> {
  try {
    const response = await filesStore.GET_PRODUCT_DOCUMENTATION_FILE_BY_ID(fileObject.id)

    const blob = new Blob([response], { type: response.type })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileObject.name || ''
    link.click()
    URL.revokeObjectURL(link.href)
  } catch {
    Promise.resolve()
  }
}

await filesStore.GET_PRODUCT_DOCUMENTATION_STRUCTURE(productId)
</script>
