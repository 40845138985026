<template>
  <v-row class="content-container product-details">
    <v-col :cols="product?.productLinks?.length ? 9 : 12">
      <MdPreview
        v-if="product?.detailedDescription"
        v-model="product.detailedDescription"
        language="en-US"
        no-iconfont
        no-mermaid
        no-katex
        no-highlight
      />
      <p
        v-else
        class="mb-0"
      >
        {{ t('productDetail.noDescription') }}
      </p>
    </v-col>
    <v-col
      v-if="product?.productLinks?.length"
      cols="3"
      class="px-0"
    >
      <p class="highlight xs mt-2">{{ t('productDetail.links.subline') }}</p>
      <DefaultButton
        v-for="productLink in product.productLinks"
        :key="productLink.id"
        :color="PlattformColors.SECONDARY"
        :append-icon="Icons.EXTERNAL_LINK"
        :href="productLink.url"
        :size="ButtonSizes.DEFAULT"
        block
        target="_blank"
        class="px-4 mb-2 justify-space-between"
      >
        <div class="text-truncate">
          {{ productLink.name }}
        </div>
      </DefaultButton>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { MdPreview } from 'md-editor-v3'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import 'md-editor-v3/lib/style.css'

import { useProductStore } from '@/store/products'
import { ButtonSizes } from '@/models/enums/components/Buttons'

const { t } = useI18n()
const productStore = useProductStore()

const { product } = storeToRefs(productStore)
</script>

<style lang="scss" scoped>
.product-details {
  :deep(.v-btn__content) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

<i18n lang="yaml">
de:
  productDetail:
    noDescription: Keine Beschreibung verfügbar.
    links:
      subline: Externe Links
</i18n>
