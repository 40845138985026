<template>
  <v-select
    v-model="model"
    :color="PlattformColors.CHANGE"
    :items="filterOptions"
    item-title="key"
    item-value="value"
    :label="t('filter.label')"
    multiple
    hide-details
    variant="outlined"
    density="comfortable"
    min-width="140"
    :data-cy="DefaultFilterTest.DEFAULT_FILTER"
  >
    <template #selection="{ item, index }">
      <span v-if="index === 0">{{ item.title }}</span>
      <span
        v-if="index === 1"
        class="align-self-center"
      >
        + {{ (model?.length || 0) - 1 }}
      </span>
    </template>
    <template #prepend-inner>
      <v-icon class="mx-1">{{ Icons.FILTER }}</v-icon>
    </template>
  </v-select>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { DefaultFilterTest } from '@/models/enums/test/DefaultFilterTest'
import { Icons } from '@/models/enums/IconTypes'
import { FilterOption } from '@/models/FilterOption'
import { PlattformColors } from '@/models/enums/ColorSets'

defineProps({
  filterOptions: { type: Array<FilterOption>, required: true },
  presetFilter: { type: Array<string>, default: undefined },
})

const model = defineModel<unknown[]>()

const { t } = useI18n()
</script>

<i18n lang="yaml">
de:
  filter:
    label: Filter
</i18n>
