<template>
  <DialogCenteredLayout>
    <template #image>
      <DefaultAvatar
        size="96"
        :color="PlattformColors.SECONDARY"
      >
        <v-icon
          size="60"
          :color="PlattformColors.SUCCESS"
          >{{ Icons.CIRCLE_CHECK }}</v-icon
        >
      </DefaultAvatar>
    </template>
    <template #content>
      <h1>{{ t('createdTenant.headline') }}</h1>
      <p>{{ t('createdTenant.copy') }}</p>
    </template>
    <template #buttons>
      <DefaultButton
        :color="PlattformColors.PRIMARY"
        class="mb-4"
        @click="emit('provider')"
      >
        {{ t('createdTenant.buttons.provider') }}
      </DefaultButton>
      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click="emit('tenant')"
      >
        {{ t('createdTenant.buttons.tenant') }}
      </DefaultButton>
    </template>
  </DialogCenteredLayout>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import DialogCenteredLayout from './layout/DialogCenteredLayout.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'

const { t } = useI18n()

const emit = defineEmits(['provider', 'tenant'])
</script>
<i18n lang="yaml">
de:
  createdTenant:
    headline: Anbieter erfolgreich erstellt!
    copy: Du kannst die Organisation nun verwalten.
    buttons:
      provider: Zum Anbieter wechseln
      tenant: Zurück zur Verwaltungskonsole
</i18n>
