<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('tenantProvider.headline') }}
    </template>
    <template #append>
      <DefaultButton
        :color="PlattformColors.PRIMARY"
        :prepend-icon="Icons.CIRCLE_ADD"
        @click="createProviderDialogRef?.open()"
      >
        {{ t('tenantProvider.headBtn') }}
      </DefaultButton>
    </template>
  </GeneralPageTitle>
  <DefaultList>
    <ListElement
      v-for="organization in organizations"
      :key="organization.id"
      class="bg-white"
      :to="{ name: Routes.TENANT_PROVIDER_DETAIL, params: { providerId: organization.id } }"
    >
      <template #title>{{ organization.name }}</template>
      <template #prepend>
        <OrganizationLogo :organization-logo="organization.logo" />
      </template>
    </ListElement>
  </DefaultList>
  <Dialog
    ref="createProviderDialogRef"
    @on-close="createProviderDialogRef?.close()"
  >
    <DialogTenantProviderCreate
      :loading="loading"
      @cancel="createProviderDialogRef?.close()"
      @submit="(organizationForm: OrganizationForm) => createOrganization(organizationForm)"
    />
  </Dialog>
  <Dialog
    ref="createdProviderDialogRef"
    :show-close-btn="false"
  >
    <DialogTenantProviderCreated
      @provider="switchToProvider()"
      @tenant="createdProviderDialogRef?.close()"
    />
  </Dialog>
</template>
<script lang="ts" setup>
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import ListElement from '@/components/baseComponents/lists/ListElement.vue'
import OrganizationLogo from '@/components/organizations/OrganizationLogo.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { useI18n } from 'vue-i18n'
import { useOrganizationStore } from '@/store/organizations'
import { storeToRefs } from 'pinia'
import { Icons } from '@/models/enums/IconTypes'
import { Routes } from '@/models/enums/Routes'
import { PlattformColors } from '@/models/enums/ColorSets'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import Dialog from '@/components/layout/Dialog.vue'
import DialogTenantProviderCreate from '@/components/dialogs/DialogTenantProviderCreate.vue'
import DialogTenantProviderCreated from '@/components/dialogs/DialogTenantProviderCreated.vue'
import { ref } from 'vue'
import { OrganizationForm } from '@/models/Organization'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()

const organizationStore = useOrganizationStore()
const myOrganizationStore = useMyOrganizationStore()

const { organizations } = storeToRefs(organizationStore)

const createProviderDialogRef = ref<InstanceType<typeof Dialog>>()
const createdProviderDialogRef = ref<InstanceType<typeof Dialog>>()
const loading = ref(false)
const createdOrganizationId = ref<string>()

/**
 * createOrganization
 * @param {OrganizationForm} organizationForm
 */
async function createOrganization(organizationForm: OrganizationForm): Promise<void> {
  try {
    loading.value = true
    createdOrganizationId.value = (await myOrganizationStore.CREATE({ ...organizationForm, provider: true })).id
    createProviderDialogRef.value?.close()
    createdProviderDialogRef.value?.open()
  } catch {
    createProviderDialogRef.value?.close()
    Promise.resolve()
  } finally {
    loading.value = false
  }
}

/**
 * switchToProvider
 */
async function switchToProvider(): Promise<void> {
  if (createdOrganizationId.value) {
    try {
      await myOrganizationStore.setActiveOrganization(createdOrganizationId.value)
      await router.push({ name: Routes.INDEX })
    } catch {
      createdProviderDialogRef.value?.close()
      Promise.resolve()
    }
  }
}

await organizationStore.GET_ALL_PROVIDERS()
</script>
<i18n lang="yaml">
de:
  tenantProvider:
    headline: Anbieter
    headBtn: Anbieter hinzufügen
</i18n>
