<template>
  <v-form
    v-model="createProductForm"
    @submit.prevent="emit('submit', product)"
  >
    <DialogDefaultLayout>
      <template #content>
        <h1 class="mb-4">{{ productObject ? t('product.edit.headline') : t('product.create.headline') }}</h1>
        <p
          v-if="!product"
          class="mb-4"
        >
          {{ t('product.create.copy') }}
        </p>
        <v-item-group
          v-model="product.publiclyAvailable"
          mandatory
          class="d-flex space-between-content mb-8 availability-selector"
        >
          <v-item>
            <v-card
              class="text-center w-100 mr-4"
              variant="flat"
              color="secondary"
              @click="setPubliclyAvailable(true)"
            >
              <template #prepend>
                <RichTooltip>
                  <template #activator="{ props }">
                    <v-icon
                      class="mt-2 ml-2"
                      v-bind="props"
                      :icon="Icons.CIRCLE_INFORMATION"
                    ></v-icon>
                  </template>
                  <template #copy>{{ t('product.public.tooltip') }}</template>
                </RichTooltip>
              </template>
              <template #title>
                <DefaultAvatar
                  size="48"
                  class="mt-2 mb-4"
                  :color="PlattformColors.INFO"
                >
                  <v-icon size="24">{{ Icons.GLOBE }}</v-icon>
                </DefaultAvatar>
                <p class="highlight mb-0">{{ t('product.public.headline') }}</p>
              </template>
              <template #append>
                <DefaultRadioButton :model-value="product.publiclyAvailable" />
              </template>
            </v-card>
          </v-item>
          <v-item>
            <v-card
              class="text-center w-100"
              variant="flat"
              color="secondary"
              @click="setPubliclyAvailable(false)"
            >
              <template #prepend>
                <RichTooltip>
                  <template #activator="{ props }">
                    <v-icon
                      class="mt-2 ml-2"
                      v-bind="props"
                      :icon="Icons.CIRCLE_INFORMATION"
                    ></v-icon>
                  </template>
                  <template #copy>{{ t('product.private.tooltip') }}</template>
                </RichTooltip>
              </template>
              <template #title>
                <DefaultAvatar
                  size="48"
                  class="mt-2 mb-4"
                  :color="PlattformColors.INFO"
                >
                  <v-icon size="24">{{ Icons.LOCK }}</v-icon>
                </DefaultAvatar>

                <p class="highlight mb-0">{{ t('product.private.headline') }}</p>
              </template>
              <template #append>
                <DefaultRadioButton :model-value="!product.publiclyAvailable" />
              </template>
            </v-card>
          </v-item>
        </v-item-group>
        <InfoBox
          v-if="product?.productStatus !== StatusTypes.DRAFT && productObject?.publiclyAvailable && !product.publiclyAvailable"
          :headline="t('product.info.headline')"
          :copy="t('product.info.copy')"
          class="mb-8"
        />
        <DefaultTextField
          v-model="product.name"
          :autofocus="!focusDesc"
          :label="t('product.form.productNameLabel')"
          :rules="productNameRules"
          required
        />
        <DefaultTextarea
          v-model="product.description"
          :label="t('product.form.productDescriptionLabel')"
          :rules="productDescriptionRules"
          required
          counter="240"
          :autofocus="focusDesc"
        />
      </template>
      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          :disabled="!createProductForm"
        >
          {{ t('buttons.save') }}
        </DefaultButton>

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayout>
  </v-form>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, ref, PropType } from 'vue'
import { ProductForm, ProductResponse } from '@/models/Product'
import { isNotEmpty, isMinLength, isMaxLength } from '@/validators'
import { Icons } from '@/models/enums/IconTypes'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import InfoBox from '../InfoBox.vue'
import { StatusTypes } from '@/models/enums/StatusTypes'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import DefaultTextField from '@/components/baseComponents/inputs/DefaultTextField.vue'
import DefaultTextarea from '@/components/baseComponents/inputs/DefaultTextarea.vue'
import RichTooltip from '../baseComponents/tooltips/RichTooltip.vue'
import DefaultRadioButton from '../baseComponents/radio/DefaultRadioButton.vue'

const { t } = useI18n()

const componentProps = defineProps({
  productObject: {
    type: Object as PropType<ProductResponse>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  focusDesc: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])

const createProductForm = ref(false)
const productNameRules = computed(() => [
  (v: string): boolean | string => isNotEmpty(v),
  (v: string): boolean | string => isMinLength(v, 3),
  (v: string): boolean | string => isMaxLength(v, 50),
])
const productDescriptionRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 240)])

const product = ref({ ...componentProps.productObject })

/**
 * setPubliclyAvailable
 * @param {publiclyAvailable} publiclyAvailable
 */
function setPubliclyAvailable(publiclyAvailable: ProductForm['publiclyAvailable']): void {
  product.value.publiclyAvailable = publiclyAvailable
}
</script>
<style lang="scss" scoped>
.availability-selector {
  :deep(.v-card-item) {
    width: 100%;
    padding: 0.825rem 0.825rem 1.5rem;
  }

  :deep(.v-card-item__append),
  :deep(.v-card-item__prepend) {
    align-self: start;
  }
}
</style>

<i18n lang="yaml">
de:
  product:
    create:
      headline: Neue API erstellen
      copy: Deine API wird zunächst als Entwurf gespeichert. Im nächsten Schritt kannst du weitere Informationen hinzufügen und die API veröffentlichen.
    edit:
      headline: API bearbeiten
    public:
      headline: Öffentlich
      tooltip: Öffentliche API-Produkte sind für alle Benutzer auf dem Marktplatz sichtbar.
    private:
      headline: Privat
      tooltip: Private API-Produkte sind nur von eingeladenen Kunden auf dem Marktplatz sichtbar.
    form:
      productNameLabel: Name*
      productDescriptionLabel: Kurzbeschreibung*
    info:
      headline: Aktive Nutzer werden automatisch freigegeben.
      copy: Private API-Produkte müssen für Organisationen freigegeben werden, um auf dem Marktplatz sichtbar zu sein.
</i18n>
