import { Alert, NewAlert } from '@/models/Alert'
import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { StoreName } from '@/models/enums/StoreName'

interface AlertStateProperties {
  alerts: Alert[]
}

export const useAlertStore = defineStore(StoreName.ALERTS, {
  state: (): AlertStateProperties => ({
    alerts: [],
  }),
  actions: {
    add(alert: NewAlert) {
      const id = uuidv4()

      this.alerts.push({
        id,
        text: alert.text,
        type: alert.type,
      })
      setTimeout(() => this.del(id), 15000)
    },
    del(id: Alert['id']) {
      this.alerts = this.alerts.filter((alert) => alert.id !== id)
    },
  },
})
