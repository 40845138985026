<template>
  <div>
    <h2>{{ t(`wizardStep1.headline.${switchContext}`) }}</h2>
    <template v-if="!noPropertiesAvailable">
      <p>{{ t(`wizardStep1.copy.${switchContext}`) }}</p>
      <v-select
        v-model="wizardContent.organizations"
        :disabled="noPropertiesAvailable || wizardContent.allSelected"
        multiple
        :items="selectProperties"
        :label="selectLabel"
        item-title="name"
        item-value="id"
        variant="outlined"
        return-object
      >
        <template #selection="{ item, index }">
          <v-chip v-if="index < 3">
            <span>{{ item.title }}</span>
          </v-chip>
          <span
            v-if="index === 3"
            class="text-grey text-caption align-self-center"
          >
            (+{{ t('additional', { count: selectedLength - 3 }) }})
          </span>
        </template>
      </v-select>

      <DefaultCheckbox
        :model-value="wizardContent.allSelected"
        :label="t(`selectAll`)"
        @click="toggleAll"
      />
    </template>
    <div
      v-else
      class="d-flex justify-center align-center flex-column bg-secondary rounded-lg pa-8"
    >
      <v-icon class="mb-4">{{ Icons.CIRCLE_INFORMATION }}</v-icon>
      <p class="mb-0">{{ t(`wizardStep1.noOrganizations.${switchContext}`) }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import DefaultCheckbox from '@/components/baseComponents/checkboxes/DefaultCheckbox.vue'
import { OrganizationPublicResponse } from '@/models/Organization'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { useOrganizationStore } from '@/store/organizations'
import { useSideWizardStore } from '@/store/sideWizard'
import { storeToRefs } from 'pinia'
import { ComputedRef, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { Icons } from '@/models/enums/IconTypes'

const { t } = useI18n()

const organizationStore = useOrganizationStore()
const sideWizardStore = useSideWizardStore()

const { organizationRelations } = storeToRefs(organizationStore)
const { wizardContent, wizardValidated } = storeToRefs(sideWizardStore)

wizardValidated.value[0] = computed(() => !!wizardContent.value.organizations?.length)

const switchContext = computed(() => (wizardContent.value.type === OrganizationTypes.PROVIDER ? OrganizationTypes.CONSUMER : OrganizationTypes.PROVIDER))

const selectProperties: ComputedRef<OrganizationPublicResponse[]> = computed(() =>
  organizationRelations.value.map((organizationRelation) => organizationRelation[switchContext.value])
)
const noPropertiesAvailable = computed(() => !selectProperties.value.length)
const selectLabel = computed(() => t('wizardStep1.label', { type: t(`wizardStep1.${switchContext.value}`) }))
const selectedLength = computed(() => wizardContent.value.organizations?.length || 0)

/**
 * toggleAll
 */
function toggleAll(): void {
  wizardContent.value.allSelected = !wizardContent.value.allSelected

  if (wizardContent.value.allSelected) {
    wizardContent.value.organizations = selectProperties.value.slice()
  } else {
    wizardContent.value.organizations = []
  }
}
</script>

<style lang="scss" scoped>
.v-selection-control {
  margin-bottom: 2rem;
}
</style>

<i18n lang="yaml">
de:
  wizardStep1:
    label: '{type} auswählen'
    headline:
      consumer: Kunde auswählen
      provider: Anbieter auswählen
    copy:
      provider: Wähle die Anbieter aus, die durch das Team verwaltet werden sollen.
      consumer: Wähle die Kunden aus, die durch das Team verwaltet werden sollen.
    consumer: Kunden
    provider: Anbieter
    noOrganizations:
      consumer: Keine Kunden gefunden
      provider: Keine Anbieter gefunden
</i18n>
