<template>
  <DialogDefaultLayoutEnter :on-submit="() => emit('submit')">
    <template #content>
      <h1>{{ t('user.delete.headline') }}</h1>
      <p>{{ t('user.delete.copy') }}</p>
      <ul class="ml-4">
        <li v-if="hasOrganizations">{{ t('user.delete.bulletPoints.first') }}</li>
        <li>{{ t('user.delete.bulletPoints.second') }}</li>
        <li>{{ t('user.delete.bulletPoints.third') }}</li>
      </ul>
    </template>
    <template #buttons>
      <DefaultButton
        type="submit"
        :color="PlattformColors.PRIMARY"
        :loading="loading"
        @click="emit('submit')"
      >
        {{ t('buttons.confirm') }}
      </DefaultButton>

      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click="emit('cancel')"
      >
        {{ t('buttons.cancel') }}
      </DefaultButton>
    </template>
  </DialogDefaultLayoutEnter>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

defineProps({
  hasOrganizations: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])
</script>
<i18n lang="yaml">
de:
  user:
    delete:
      headline: Profil löschen
      copy: Bist du dir sicher, dass du dein Profil löschen möchtest?
      bulletPoints:
        first: Deine Zugehörigkeit zu Organisationen wird automatisch aufgelöst
        second: Du wirst automatisch ausgeloggt und verlierst den Zugang zum Developer Portal
        third: Alle Daten deines Profils werden unwiderruflich gelöscht
</i18n>
