<template>
  <div class="organization-users">
    <GeneralPageTitle>
      <template #title>
        {{ t('organizationUsers.headline') }}
      </template>
    </GeneralPageTitle>
    <div class="organization-users__container content-container">
      <v-tabs color="primary">
        <v-tab
          replace
          exact
          :to="{ name: Routes.USERS }"
        >
          {{ t('organizationUsers.userTabName') }}
        </v-tab>
        <v-tab
          replace
          exact
          :to="{ name: Routes.TEAMS }"
        >
          {{ t('organizationUsers.teamsTabName') }}
        </v-tab>
      </v-tabs>
      <TabContainer />
    </div>
  </div>
</template>
<script lang="ts" setup>
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import TabContainer from '@/components/TabContainer.vue'
import { Routes } from '@/models/enums/Routes'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<i18n lang="yaml">
de:
  organizationUsers:
    headline: Nutzer & Teams
    userTabName: Nutzer
    teamsTabName: Teams
</i18n>
