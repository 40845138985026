<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('tenantSettings.headline') }}
    </template>
  </GeneralPageTitle>
  <!-- <v-row>
    <v-col>
      <NavigationTeaser :to="{ name: Routes.TENANT_SETTINGS_TENANT_DATA }">
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.ORGANIZATIONS }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('tenantSettings.tenantDataTeaser.title') }}
        </template>
        <template #subtitle>
          {{ t('tenantSettings.tenantDataTeaser.subtitle') }}
        </template>
      </NavigationTeaser>
    </v-col>
    <v-col>
      <NavigationTeaser :to="{ name: Routes.TENANT_SETTINGS_CONTACT_PERSONS }">
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.USER }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('tenantSettings.contactPersonTeaser.title') }}
        </template>
        <template #subtitle>
          {{ t('tenantSettings.contactPersonTeaser.subtitle') }}
        </template>
      </NavigationTeaser>
    </v-col>
  </v-row> -->
  <v-row>
    <v-col>
      <!-- <NavigationTeaser :to="{ name: Routes.TENANT_SETTINGS_USER_MANAGEMENT }">
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.USERS }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('tenantSettings.userManagementTeaser.title') }}
        </template>
        <template #subtitle>
          {{ t('tenantSettings.userManagementTeaser.subtitle') }}
        </template>
      </NavigationTeaser> -->
      <NavigationTeaser :to="{ name: Routes.TENANT_SETTINGS_PLATFORM_INFORMATION }">
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.API_INFOS }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('tenantSettings.platformInfoTeaser.title') }}
        </template>
        <template #subtitle>
          {{ t('tenantSettings.platformInfoTeaser.subtitle') }}
        </template>
      </NavigationTeaser>
    </v-col>
    <v-col>
      <NavigationTeaser :to="{ name: Routes.TENANT_SETTINGS_LEGAL_CONTENT }">
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.USER_SETTINGS }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('tenantSettings.legalContentTeaser.title') }}
        </template>
        <template #subtitle>
          {{ t('tenantSettings.legalContentTeaser.subtitle') }}
        </template>
      </NavigationTeaser>
    </v-col>
  </v-row>
  <!-- <v-row>
    <v-col>
      <NavigationTeaser :to="{ name: Routes.TENANT_SETTINGS_PLATFORM_INFORMATION }">
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.API_INFOS }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('tenantSettings.platformInfoTeaser.title') }}
        </template>
        <template #subtitle>
          {{ t('tenantSettings.platformInfoTeaser.subtitle') }}
        </template>
      </NavigationTeaser>
    </v-col>
    <v-col> </v-col>
  </v-row> -->
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import NavigationTeaser from '@/components/baseComponents/teasers/NavigationTeaser.vue'

const { t } = useI18n()
</script>
<i18n lang="yaml">
de:
  tenantSettings:
    headline: Einstellungen
    tenantDataTeaser:
      title: Mandantendaten
      subtitle: Verwaltung von Organisationsnname, Anschrift, etc.
    contactPersonTeaser:
      title: Kontaktpersonen
      subtitle: Anlegen und verwalten von Kontaktpersonen
    userManagementTeaser:
      title: Nutzerverwaltung
      subtitle: Verwaltung von Rollen und Nutzerzugängen
    legalContentTeaser:
      title: Rechtliche Inhalte
      subtitle: Impressum, Nutzungsbedingungen, Datenschutz
    platformInfoTeaser:
      title: Plattforminformationen
      subtitle: Subdomain, Design
</i18n>
