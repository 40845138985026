<template>
  <GeneralPageTitle>
    <template #prepend>
      <OrganizationLogo
        :size="80"
        :organization-logo="organization?.logo"
      />
    </template>
    <template #title>{{ organization?.name }}</template>
  </GeneralPageTitle>
  <v-row>
    <v-col>
      <DefaultList>
        <ListElement class="bg-white">
          <template #prepend>
            <DefaultAvatar
              color="grey-lighten-4"
              size="48"
            >
              <v-icon>{{ Icons.ORGANIZATIONS }}</v-icon>
            </DefaultAvatar>
          </template>
          <template #title>{{ t('tenantProviderDetail.organization') }}</template>
          <template #additional>
            <div class="d-flex flex-column align-start pl-12 ml-5">
              <DefaultTag
                v-if="organization?.email"
                class="mb-4"
                :prepend-icon="Icons.EMAIL"
                :href="`mailto:${organization?.email}`"
              >
                {{ organization.email }}
              </DefaultTag>
              <DefaultTag
                v-if="fullAddress"
                class="mb-4"
                :prepend-icon="Icons.LOCATION"
                :variant="TagVariants.READ_ONLY"
              >
                {{ fullAddress }}
              </DefaultTag>
              <DefaultTag
                v-if="(organization as OrganizationFullResponse)?.registrationNumber"
                class="mb-4"
                :variant="TagVariants.READ_ONLY"
              >
                {{ (organization as OrganizationFullResponse)?.registrationNumber }}
              </DefaultTag>
            </div>
          </template>
        </ListElement>
      </DefaultList>
    </v-col>
    <v-col>
      <DefaultList v-if="defaultContact">
        <ListElement class="bg-white">
          <template #prepend>
            <DefaultAvatar
              color="grey-lighten-4"
              size="48"
            >
              <v-icon>{{ Icons.USER }}</v-icon>
            </DefaultAvatar>
          </template>
          <template #title>{{ t('tenantProviderDetail.contact') }}</template>
          <template #additional>
            <div class="d-flex flex-column align-start pl-12 ml-5">
              <DefaultTag
                class="mb-4"
                :prepend-icon="Icons.USER"
                :variant="TagVariants.READ_ONLY"
              >
                {{ defaultContact.name }}
              </DefaultTag>
              <DefaultTag
                class="mb-4"
                :prepend-icon="Icons.EMAIL"
                :href="`mailto:${defaultContact.email}`"
              >
                {{ defaultContact.email }}
              </DefaultTag>
              <DefaultTag
                class="mb-4"
                :prepend-icon="Icons.CALL"
                :href="`tel:${defaultContact.phone}`"
              >
                {{ defaultContact.phone }}
              </DefaultTag>
            </div>
          </template>
        </ListElement>
      </DefaultList>
    </v-col>
  </v-row>
</template>
<script lang="ts" setup>
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import OrganizationLogo from '@/components/organizations/OrganizationLogo.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import ListElement from '@/components/baseComponents/lists/ListElement.vue'
import { useI18n } from 'vue-i18n'
import { useOrganizationStore } from '@/store/organizations'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { Icons } from '@/models/enums/IconTypes'
import { OrganizationFullResponse } from '@/models/Organization'
import { computed } from 'vue'
import DefaultTag from '@/components/baseComponents/tags/DefaultTag.vue'
import { TagVariants } from '@/models/enums/components/Tags'

const { t } = useI18n()
const route = useRoute()
const organizationStore = useOrganizationStore()

const { organization } = storeToRefs(organizationStore)

const fullAddress = computed(() => {
  const o = organization?.value as OrganizationFullResponse
  return o.address ? `${o.address?.street} ${o.address?.number}, ${o.address?.zipCode} ${o.address?.city}` : undefined
})

// TODO Default Contact
const defaultContact = computed(() => {
  const o = organization?.value as OrganizationFullResponse
  return o.contacts?.length ? o.contacts[0] : undefined
})

await organizationStore.GET_ORGANIZATION(route.params.providerId as string)
</script>
<i18n lang="yaml">
de:
  tenantProviderDetail:
    organization: Unternehmen
    contact: Kontaktperson
</i18n>
