<template>
  <ListElement class="bg-white">
    <template #prepend>
      <DefaultAvatar
        size="48"
        :color="PlattformColors.INFO"
      >
        <v-icon :icon="Icons.USERS" />
      </DefaultAvatar>
    </template>
    <template #title>{{ contact.name }}</template>
    <template #subtitle>
      {{ activeOrganization?.provider ? t('contactElement.contactPerson') : t('createdAt', { d: format(new Date(contact.createdAt), 'dd.MM.yyyy') }) }}
    </template>
    <template #additional>
      <div class="pl-12 ml-6">
        <DefaultTag
          class="mr-4"
          :href="`mailto:${contact.email}`"
          :prepend-icon="Icons.EMAIL"
        >
          {{ contact.email }}
        </DefaultTag>
        <DefaultTag
          class="mr-4"
          :href="`tel:${contact.phone}`"
          :prepend-icon="Icons.CALL"
        >
          {{ contact.phone }}
        </DefaultTag>
      </div>
    </template>
    <template #append>
      <slot name="append"></slot>
    </template>
  </ListElement>
</template>
<script lang="ts" setup>
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { ContactResponse } from '@/models/Contact'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { format } from 'date-fns'
import { storeToRefs } from 'pinia'
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import DefaultTag from '@/components/baseComponents/tags/DefaultTag.vue'
import ListElement from '@/components/baseComponents/lists/ListElement.vue'

const { t } = useI18n()
const myOrganizationStore = useMyOrganizationStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)

defineProps({
  contact: { type: Object as PropType<ContactResponse>, required: true },
})
</script>
<i18n lang="yaml">
de:
  contactElement:
    contactPerson: Kontaktperson
</i18n>
