<template>
  <v-divider inset />
  <v-list-item
    class="my-4 pa-2"
    :to="to"
    :data-test="dataTest"
    v-on="clickable ? { click: () => emit('click') } : {}"
  >
    <template
      v-if="$slots.prepend"
      #prepend
    >
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.title"
      #title
    >
      <p class="highlight mb-0">
        <slot name="title"></slot>
      </p>
    </template>
    <template
      v-if="$slots.subtitle"
      #subtitle
    >
      <p class="xs mb-0">
        <slot name="subtitle"></slot>
      </p>
    </template>
    <template
      v-if="$slots.append"
      #append
    >
      <slot name="append"></slot>
    </template>
  </v-list-item>
</template>
<script setup lang="ts">
import { PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

defineProps({
  to: { type: Object as PropType<RouteLocationRaw>, default: undefined },
  dataTest: { type: String, default: undefined },
  clickable: { type: Boolean, default: false },
})

const emit = defineEmits(['click'])
</script>
