<template>
  <div class="create-bundle">
    <v-form
      ref="bundleForm"
      v-model="createBundleForm"
      @submit.prevent="emit('submit', bundle)"
    >
      <DialogDefaultLayout>
        <template #content>
          <h1>{{ isEditForm ? t('editBundle.headline') : t('createBundle.headline') }}</h1>
          <p class="mb-8">{{ t('createBundle.copy') }}</p>

          <v-autocomplete
            v-model="bundle.providerId"
            :items="availableOrganizations"
            :label="t('createBundle.form.select.label')"
            variant="outlined"
            required
            :rules="bundleProviderRules"
            item-title="name"
            item-value="id"
            :disabled="!!bundleObject"
          />

          <DefaultTextField
            v-model="bundle.name"
            :label="t('createBundle.form.name.label')"
            counter
            maxlength="25"
            required
            :rules="bundleNameRules"
          />

          <DefaultTextarea
            ref="bundleFormDesc"
            v-model="bundle.description"
            :autofocus="focusElementRef === 'bundleFormDesc'"
            :label="t('createBundle.form.description.label')"
          />
        </template>

        <template #buttons>
          <DefaultButton
            :type="ButtonTypes.SUBMIT"
            :color="PlattformColors.PRIMARY"
            :disabled="!createBundleForm && !bundle.providerId"
            :loading="loading"
          >
            {{ t('buttons.save') }}</DefaultButton
          >

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="emit('cancel')"
          >
            {{ t('buttons.cancel') }}
          </DefaultButton>
        </template>
      </DialogDefaultLayout>
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import { OrganizationPublicResponse } from '@/models/Organization'
import { isNotEmpty, isMaxLength, isProviderOrganization } from '@/validators'
import { PropType, Ref, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { BundleResponse, BundleForm } from '@/models/Bundle'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { ButtonTypes } from '@/models/enums/components/Buttons'
import DefaultTextField from '@/components/baseComponents/inputs/DefaultTextField.vue'
import DefaultTextarea from '@/components/baseComponents/inputs/DefaultTextarea.vue'

const { t } = useI18n()

const emit = defineEmits(['submit', 'cancel'])
const bundleFormDesc = ref<HTMLFormElement>()

const componentProps = defineProps({
  availableOrganizations: {
    type: Array<OrganizationPublicResponse>,
    default: undefined,
  },
  bundleObject: {
    type: Object as PropType<BundleResponse>,
    default: undefined,
  },
  focusElementRef: {
    type: String,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const bundleNameRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 25)])
const bundleProviderRules = computed(() => [(v: string): boolean | string => isProviderOrganization(v, componentProps.availableOrganizations || [])])

const createBundleForm = ref(false)
const bundleForm = ref<HTMLFormElement>()
const bundle: Ref<BundleForm> = ref({ ...componentProps.bundleObject })

const isEditForm = computed(() => !!componentProps.bundleObject)
</script>

<i18n lang="yaml">
de:
  createBundle:
    headline: Neue App erstellen
    copy: Gib deiner App einen Namen und beschreibe kurz die Funktionalität. Beachte dabei, dass der Name eindeutig sein muss.
    form:
      select:
        label: Anbieter auswählen*
      name:
        label: Name*
      description:
        label: Kurzbeschreibung
  editBundle:
    headline: App bearbeiten
</i18n>
