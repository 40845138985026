<template>
  <DefaultAvatar :size="`${size}`">
    <v-img
      v-if="productLogo && !activeOrganization?.provider"
      :src="productLogo"
      :max-width="size / 2"
      :max-height="size / 2"
      contain
    />
    <v-icon
      v-else
      color="primary"
      :size="size / 2"
      >{{ Icons.PRODUCT }}</v-icon
    >
  </DefaultAvatar>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'
import { OrganizationPublicResponse } from '@/models/Organization'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { Icons } from '@/models/enums/IconTypes'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'

const myOrganizationStore = useMyOrganizationStore()
const { activeOrganization } = storeToRefs(myOrganizationStore)

defineProps({
  productLogo: { type: String as PropType<OrganizationPublicResponse['logo']>, default: undefined },
  size: { type: Number, default: 48 },
})
</script>
